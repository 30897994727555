import { connect } from "react-redux";
import "./FundingOption.scss"
import Loading from "components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { getMemberType } from "store/action/memberTypeAction";
import { getByMembership } from "store/action/memberTypeAction";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { showSuccess } from "utils/NQToaster";
import IconCopy from "Icons/iconCopy";
import { ByFundingOption } from "constant/APICalls";



const FundingOption = (props) => {

    const [funding,SetFunding]= useState([]);
    const [loading , SetLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        if(props.clientKey !== null){
            FundingData(props.clientKey);
          } 
    }

 
    const FundingData = async (NuclesKey) => {
        SetLoading(true);
        const workspaceKey = localStorage.getItem("workspaceKey");
        const url = `${ByFundingOption.controller}/${NuclesKey}/${ByFundingOption.action}/${workspaceKey}`;
        const env = JSON.parse(localStorage.getItem("env"));
        axios.get(`${env.apiUrl}${url}`).then(o => {
         SetFunding(o.data.data);
         SetLoading(false);
        }).catch(err => {
          SetLoading(false);
        })
      }

    const copyText = async (text) =>{
        navigator.clipboard.writeText(text);
        showSuccess("Copied successfully.");
    }


    return (
        <GridContainer>
            <GridItem lg={12} sm={12} xs={12} md={12}>
                <div className="css-1ulh703">
                    <div className="css-18akwle">
                        <p className="css-p">Funding Option</p>
                    </div>
                </div>
            </GridItem>

            {loading ? 
            <GridItem lg={12} sm={12} xs={12} md={12}>
            <div className="flex-container">
            <div className="flex-items loader">Retrieving your data... <span>🔍</span></div>
            </div>
            </GridItem> : null}

            {!loading && funding.filter(x=> x.name == "CRN" || x.name == "BPAYReferenceNumber").length == 0 ?
            <GridItem lg={12} sm={12} xs={12} md={12}>
                <div className="css-1ulh703">
                    <div className="css-18akwle">
                        <p>Your portfolio is still being established. Please check back soon.</p>
                    </div>
                </div>
            </GridItem> : null}
            
            { funding!=null && funding.filter(x=> x.name == "CRN").length > 0 ? 
            <GridItem lg={12} sm={12} xs={12} md={12}>
          <div className="css-1ulh703">
            <div className="css-18akwle">
              <p className="css-p">EFT Details</p>
            </div>
            <div className="css-vy2pbh">
              <div className="css-xqrdoy">
                <div>

                  <div className="css-0">
                    <p className="css-fqk8rb">Bank</p>
                    <span className="css-1crpfoq">
                    Australia and New Zealand Banking Group Limited (ANZ)
                    </span>
                  </div>
                  <div className="css-0">
                    <p className="css-fqk8rb">BSB Number</p>
                    <span className="css-1crpfoq text-align-middle">
                    014-953 
                    </span> 
                    <span className="text-align-middle ml-3 pointer" onClick={() => {copyText("014-953")}}>
                    <IconCopy/>
                    </span>
                    
                  </div>
                  <div className="css-0">
                    <p className="css-fqk8rb">Account No</p>
                    <span className="css-1crpfoq text-align-middle">
                    { funding.filter(a => a.name === 'CRN')[0]?.value || '--' } 
                    </span> 
                    { funding.filter(a => a.name === 'CRN')[0]?.value ?
                    <span className="text-align-middle ml-3 pointer" onClick={() => {copyText(funding.filter(a => a.name === 'CRN')[0]?.value)}}>
                    <IconCopy/>
                    </span>
                    : null
                    }
                  </div>

                </div>
              </div>
            </div>
          </div>
            </GridItem> : null }

            { funding!=null && funding.filter(x=> x.name == "BPAYReferenceNumber").length > 0 ? 
            <GridItem lg={12} sm={12} xs={12} md={12}>
          <div className="css-1ulh703">
            <div className="css-18akwle">
              <p className="css-p">BPay Details</p>
              {/* <a className="css-phtnxg">Edit</a> */}
            </div>
            <div className="css-vy2pbh">
              <div className="css-xqrdoy">
                <div>
                  <div className="css-1qm1lh">
                    <p className="css-fqk8rb">BPay Biller Code</p>
                    <span className="css-1crpfoq">
                       745976
                    </span>
                    <span className="text-align-middle ml-3 pointer" onClick={() => {copyText("745976")}}>
                    <IconCopy/>
                    </span>
                  </div>
                  <div className="css-1qm1lh">
                    <p className="css-fqk8rb">BPay Ref</p>
                    <span className="css-1crpfoq">
                      { funding.filter(a => a.name === 'BPAYReferenceNumber')[0]?.value || '--' }
                    </span>
                    { funding.filter(a => a.name === 'BPAYReferenceNumber')[0]?.value ?
                    <span className="text-align-middle ml-3 pointer" onClick={() => {copyText(funding.filter(a => a.name === 'BPAYReferenceNumber')[0]?.value)}}>
                    <IconCopy/>
                    </span>
                    : null
                    }
                  </div>

                </div>
              </div>
            </div>
          </div>
            </GridItem> : null }
       
        </GridContainer>
    )

}


const mapStateToProps = (state) => {
    return {
        memberTypeDataObj: state.memberTypeReducer.memberTypeDataObj,
        byMembershipTypeDataObj: state.memberTypeReducer.byMembershipTypeDataObj,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        memberType: () => dispatch(getMemberType()),
        ByMembershipType: () => dispatch(getByMembership()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withAuthenticationRequired(FundingOption, {
        onRedirecting: () => <Loading />,
    })
);
