import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { RequestType } from "../../../constant/RequestType";
import { useHistory } from "react-router-dom";
import NQInputText from "../../../components/TextField/NQ-input-text";
import "./AddRequest.scss";
import NQDropDown from "components/DropDown/NQDropDown";
import NQInputTextArea from "components/TextField/NQ-input-textarea";
import { showError } from "utils/NQToaster";
import SwitchSuperPension from "../../../assets/icon/SwitchSuperPension.svg";
import Updateyourdetails from "../../../assets/icon/Updateyourdetails.svg";
import AmendPension from "../../../assets/icon/AmendPension.svg";
import ClientFees from "../../../assets/icon/ClientFees.svg";
import ContactTechSupport from "../../../assets/icon/ContactTechSupport.svg";
import Transferassets from "../../../assets/icon/Transferassets.svg";
import Requestcashwithdrawal from "../../../assets/icon/Requestcashwithdrawal.svg";
import Managedepositscontributions from "../../../assets/icon/Managedepositscontributions.svg";
import Tooltip from "@material-ui/core/Tooltip";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { connect } from "react-redux";
import { getEnumbyType } from "store/action/readOnlyAction";
import { AddNewRequest } from "store/action/requestAction";
import { getClients } from "store/action/factFindAction";
import { GetByKey } from "store/action/httpAction";
import { showSuccess } from './../../../utils/NQToaster';
import CloseIcon from '@material-ui/icons/Close';

import axios from "axios";
import FileSaver from "file-saver";
import { CircularProgress } from "@material-ui/core";
import { getClientPortfolioList } from "store/action/portfoliosAction";
import { NIL as NIL_UUID } from 'uuid';
const AddRequest = (props) => {

  const [dropdown, setDropdown] = useState(false);
  const [EnumTypesList, setEnumTypesList] = useState([])
  const [requestStatus, setrequestStatus] = useState([])
  const [loading, setloading] = useState(false)

  let env = JSON.parse(localStorage.getItem("env"));

  const useStyles = makeStyles((theme) => ({
    root: { width: "100%" },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [RequestObj, setRequestObj] = useState(RequestType);
  const [files, setfiles] = useState({});
  const history = useHistory();
  const classes = useStyles();

  const DropDownPortFolios = () => {
    return props.portfoliosDataObj.map((x) => ({
      ...x,
      label: x.portfolioName,
      value: x.portfolioKey,
    }));
  };

  const handleChange = (e, x) => {
    setRequestObj({
      ...RequestObj,
      [typeof e.target !== "undefined"

        ? [e.target.name]
        : "plannedDate"]: e.target ? e.target.value : x,
    });

    RequestObj[e.target.name] = e.target.value ? e.target.value : e.target;
    RequestObj.amount = Number(e.target.value ? e.target.value : e.target);
  };

  const handleNext = (e, step) => {
    if (e) {
      e.preventDefault();
    }

    if (props.apiresponse[0] && props.apiresponse[0].key) {
      setPortfolioType(props.apiresponse[0] && props.apiresponse[0].clientName ? props.apiresponse[0].clientName : '--');
      setSelectedRequestType(props.apiresponse[0] && props.apiresponse[0].requestType ? props.apiresponse[0].requestType : '--');
    }
    if (isValidate()) {
      if (step === "firtstep") {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  const navigateTo = () => {
    history.push("/admin/Request");
  };

  const handleSave = async () => {
    let col = [];
    if (isValidate()) {
      RequestObj.typeOfRequiredChanges.forEach((element) => {
        col.push(element.value);
      });
      if (RequestObj.status && requestStatus.filter(o => o.text === RequestObj.status)[0]) {
        RequestObj.status = requestStatus.filter(o => o.text === RequestObj.status)[0].value
      }
      const contactKey = localStorage.getItem("contactKey");
      RequestObj.contactKey = contactKey;
      RequestObj.typeOfRequiredChanges = col;
      if (col) props.AddNewRequest(RequestObj, history).then(o => {
        showSuccess();
        initilizeRequestObject();
        history.push("/admin/Request");
      });
    }
  };

  const initilizeRequestObject = () => {
    setRequestObj({
      adviserFirmKey: "",
      adviserFirmName: "",
      adviserKey: "",
      adviserName: "",
      amount: 0,
      clientKey: NIL_UUID,
      clientName: "",
      comment: "",
      key: "",
      name: "",
      portfolioKey: "",
      requestType: "",
      status: "",
      typeOfRequiredChanges: [],
      contactKey: ""
    })
    setCategory(prev => (''));
  }
  function getSteps() {
    return ["", "", ""];
  }

  useEffect(() => {
    if (props.location.key) {
      let workspaceKey = localStorage.getItem("workspaceKey");
      let url = `Requests/${props.location.key}/${workspaceKey}`;
      props.getByKey(url);

      let env = JSON.parse(localStorage.getItem("env"));
      let workSpaceKey = localStorage.getItem("workspaceKey");
      axios.get(`${env.apiUrl}Requests/${props.location.key}/RequestDocuments/${workSpaceKey}`).then(o => {
        let file = o.data.data[0];
        file.name = file.fileName
        setfiles(file)
      }).catch(err => {
      })
    }
    else {
      initilizeRequestObject();
    }

    setDropdown(false);
    props.getClients();
    props.portfoliosList(props.nqSuperSelected);

    props.requestStatus().then(o => {
      setrequestStatus(o.data);
    });

    props.getEnumTypes().then(o => {
      let array = o.data;
      array.forEach(element => {
        element.label = element.text;
        element.id = element.value;
      });

      setEnumTypesList(prev => ({ ...prev, Data: array }))

    });
    

    if (props.location.memberKey === 'UpdateClientAccountDetail') {

      setPortfolioType("");
      setSelectedRequestType("");
      let object = {
        field: "UpdateClientAccountDetail",
        image: "/static/media/othercategory.22b223ed.svg",
        value: "Update client and account detail"
      }
      setMemeberScreen(object)

    }
    return () => {
      //unsubscribe api
    };



  }, []);

  const setMemeberScreen = async (object) => {

    await setSelectedCategory(object);


    // RequestObj.requestType = "UpdateClientAccountDetail"; 

    setRequestObj({
      ...RequestObj,
      requestType: "UpdateClientAccountDetail"
    })
    if (props.portfoliosDataObj && props.portfoliosDataObj[0] && props.portfoliosDataObj[0].key) {
      RequestObj.portfolioKey = props.portfoliosDataObj[0].portfolioKey;
    }


    setSelectedRequestType("Update Client Account Detail");
    if (props.portfoliosDataObj && props.portfoliosDataObj[0] && props.portfoliosDataObj[0].name) {
      setPortfolioType(props.portfoliosDataObj[0].name);
    }

    handleNext(null, 'firtstep');
  }

  React.useEffect(() => {
    mapResponse();
  }, [props]);

  const mapResponse = useCallback(() => {
    if (props.apiresponse && props.apiresponse[0]) {
      Object.keys(props.apiresponse[0]).forEach((key) => {
        RequestObj[key] = props.apiresponse[0][key];
      });
      let obj = requestTypeArray.filter(
        (o) => o.value === RequestObj.requestType
      )[0];
      setCategory(RequestObj.requestType);
      let mapArray = [];
      for (var i = 0; i < props.apiresponse.length; i++) {
        if (props.apiresponse[0].typeOfRequiredChanges[i]) {
          let a = EnumTypesList?.Data?.filter(
            (a) => a.text === props.apiresponse[0].typeOfRequiredChanges[i]
          )[0];
          if (a) {
            a["label"] = a.text;
            mapArray.push(a);
          }
        }
      }
      RequestObj.requestType = obj && obj.field.length > 0 && obj.field;
      if (mapArray && mapArray.length > 0) {
        RequestObj.typeOfRequiredChanges = mapArray;
      }
    }
  }, [props.apiresponse]);

  const [CashWithdrawal, setCashWithdrawal] = useState(false);
  const [UpdateClientAccountDetail, setUpdateClientAccountDetail] = useState(
    false
  );
  const isValidate = () => {
    let isValid = true;

    if (activeStep === 0) {
      if (!RequestObj.requestType) {
        isValid = false;
        showError("Select Request Type ");
      }
      if (!RequestObj.portfolioKey) {
        isValid = false;
        showError(`Portfolio can't be empty`);
      }
      if (RequestObj.requestType === "CashWithdrawal") {
        setCashWithdrawal(true);
      } else {
        setCashWithdrawal(false);
      }
      if (RequestObj.requestType === "UpdateClientAccountDetail") {
        setUpdateClientAccountDetail(true);
      } else {
        setUpdateClientAccountDetail(false);
      }
    }
    if (activeStep === 1) {
      if (!RequestObj.comment || RequestObj.comment.toString().trim().length <= 0) {
        isValid = false;
        showError("Comment can't be empty");
      }
      if (RequestObj.requestType === "CashWithdrawal") {
        if (!RequestObj.amount) {
          isValid = false;
          showError("Amount can't be empty");
        }
      }
      if (RequestObj.requestType === "UpdateClientAccountDetail") {
        if (
          RequestObj.typeOfRequiredChanges &&
          RequestObj.typeOfRequiredChanges.length === 0
        ) {
          isValid = false;
          showError("Select Required Changes");
        }
      }
    }
    if (activeStep === 2) {
    }

    return isValid;
  };

  const requestTypeArray = [  
    {
      image: Managedepositscontributions,
      value: "Manage deposits & contributions",
      field: "ManageDepositContributions",
    },
    {
      image: Requestcashwithdrawal,
      value: "Request cash withdrawal",
      field: "CashWithdrawal",
    },
    {
      image: SwitchSuperPension,
      value: "Switch between Super & Pension",
      field: "SwitchSuperPension",
    },
    {
      image: AmendPension,
      value: "Amend pension",
      field: "AmendPension",
    },
    {
      image: Transferassets,
      value: "Transfer assets",
      field: "TransferAssets",
    },
    {
      image: Updateyourdetails,
      value: "Update your details",
      field: "UpdateClientAccountDetail",
    },
    {
      image: ClientFees,
      value: "Enquire about Fees",
      field: "MaintainClientFees",
    },
    {
      image: ContactTechSupport,
      value: "Contact Tech Support",
      field: "TechRequest",
    }
  ];
  const [category, setCategory] = useState();
  const [portfolioType, setPortfolioType] = useState("");
  const [selectedrequestType, setSelectedRequestType] = useState("");

  const setSelectedCategory = async (item) => {
    await setCategory(item.value);

    RequestObj.requestType = item.field;
    setSelectedRequestType("Update Client Account Detail");
  };

  const TypeChange = (selectedOption) => {

    setRequestObj({
      ...RequestObj,
      portfolioKey: selectedOption?.value,
      clientKey: selectedOption?.clientKey,
    });

    RequestObj.portfolioKey = selectedOption?.value;
    RequestObj.clientKey = selectedOption?.clientKey;
    setPortfolioType(selectedOption?.label);
    setSelectedRequestType(RequestObj.requestType);

  };

  const downloadFile = () => {
    setloading(true);
    let env = JSON.parse(localStorage.getItem("env"));
    let workSpaceKey = localStorage.getItem("workspaceKey");


    axios.get(`${env.apiUrl}Files/${files.key}/${workSpaceKey}`, { responseType: 'blob' }).then(o => {

      FileSaver.saveAs(o.data, files.name)
      setloading(false);
    }).catch(err => {
      setloading(false);
    })
  }

  const TypeChangeReqDetail = (selectedOption) => {
    setRequestObj((req) => {
      return {
        ...req,
        typeOfRequiredChanges: [...selectedOption],
      };
    });
  };

  const getUploadParams = ({ file, meta }) => {

    setloading(true)
    let env = JSON.parse(localStorage.getItem("env"));
    let token = localStorage.getItem("token");
    let workSpaceKey = localStorage.getItem("workspaceKey");
    const body = new FormData()
    body.append('File', file);
    body.append('documentType', 'Request');
    return {
      url: `${env.apiUrl}Files/${workSpaceKey}`, body, headers: { Authorization: `bearer ${token}` },
    };
  };

  const handleChangeStatus = ({ meta, file, xhr }, status) => {

    if (status === "done") {
      let response = JSON.parse(xhr.response);
      setloading(false);
      let objfile = { key: response }
      setfiles(objfile)
      setRequestObj((req) => {
        return {
          ...req,
          documentKey: response,
        };
      });
    }
  };

  const onDelete = () => {

    let env = JSON.parse(localStorage.getItem("env"));
    let workSpaceKey = localStorage.getItem("workspaceKey");
    axios.delete(`${env.apiUrl}Requests/${props.location.key}/Files/${files.key}/${workSpaceKey}`).then(o => {
      setfiles(null)
    }).catch(err => { })
  }
if(!props.nqSuperSelected){
  const arry=requestTypeArray.findIndex(obj=>{
    return obj.field==='AmendPension'
  })
  requestTypeArray.splice(arry,1)
}
  return (
    <div className="addrequest">
      {dropdown == false ? (
        <React.Fragment>
          {activeStep == steps.length ? (
            <div className="mainDiv">
              <Typography component={"span"} className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div className={classes.instructions}>
              {activeStep == 0 ? (
                <div className="">
                  <GridContainer>
                    <GridItem lg={12} sm={12} xs={12} md={12}>
                      <div className="css-q4good">
                        <h1
                          className="css-1tu684v"
                          style={{ marginBottom: "10px" }}
                        >
                          Create Request
                        </h1>

                        <div>


                          <div className="css-1yjvs5a">
                            <div className="sc-oefIU cAmEDQ">
                              <div className="sc-hhIhEF fsTaMx">
                                <div className="sc-oefIU ecNPTU">
                                  <div className="sc-hhIhEF enPTrg">
                                    <GridItem lg={12} sm={12} xs={12} md={12}>
                                      <label className="cat-title">
                                        Request Type
                                      </label>



                                      <div className="category">
                      
                                        {requestTypeArray.map((item, index) => (
                                          <Tooltip
                                            key={index}
                                            title={item.value}
                                            aria-label="add"
                                          >
                                            <div
                                              key={index}
                                              className="NQ-web"
                                              onClick={() =>
                                                setSelectedCategory(item)
                                              }
                                            >
                                              <div className="NQ-card goal-card">
                                                <div
                                                  className={`${item.value === category &&
                                                    "cartory-border "
                                                    } NQ-card-body`}
                                                >
                                                  <div className="NQ-left">
                                                    <div className="NQ-col-1">
                                                      <img
                                                        height="40"
                                                        src={item.image}
                                                        width="60"
                                                        alt={item.value}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html: item.span,
                                                }}
                                              ></div>
                                            </div>
                                          </Tooltip>
                                        ))
                                  }
                                      </div>
                                    </GridItem>
                                    <GridItem lg={12} sm={12} xs={12} md={12}>
                                      <NQDropDown
                                        caption="Portfolio"
                                        id="portfolioKey"
                                        variant="outlined"
                                        value={RequestObj.portfolioKey}
                                        label="name"
                                        required={true}
                                        name="portfolioKey"
                                        onChange={(e) => TypeChange(e)}
                                        data={DropDownPortFolios()}
                                        placeholder="Portfolio"
                                      />
                                    </GridItem>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="css-dvxtzn">
                            <button
                              onClick={(e) => handleNext(e, "firtstep")}
                              type="submit"
                              className="css-jhd49u"
                            >
                              Next
                            </button>
                            <button
                              onClick={() => navigateTo()}
                              type="button"
                              className="css-1gld5x9"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              ) : activeStep == 1 ? (
                <div className="nextstep">
                  <GridContainer>
                    <GridItem lg={12} sm={12} xs={12} md={12}>
                      <div className="css-q4good">
                        <h1 className="css-1tu684v">Create Request</h1>
                        <div>

                          {/*  */}
                          <div className="css-1ulh703">

                            <div className="css-vy2pbh">
                              <div className="css-xqrdoy">
                                <div>
                                  <div className="css-1qm1lh">
                                    <p className="css-fqk8rb">Request Type</p>
                                    <span className="css-1crpfoq">
                                      {selectedrequestType}
                                    </span>
                                  </div>
                                  <div className="css-1qm1lh" style={{ margin: '0px' }}>
                                    <p className="css-fqk8rb">Portfolio</p>
                                    <span className="css-1crpfoq">
                                      {portfolioType}
                                    </span>
                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                          {/*  */}
                          <div className="css-1yjvs5a">
                            <div className="sc-oefIU cAmEDQ">
                              <div className="sc-hhIhEF fsTaMx">
                                <div className="sc-oefIU ecNPTU">
                                  <div className="sc-hhIhEF enPTrg">
                                    <GridItem lg={12} sm={12} xs={12} md={12}>
                                      <NQInputTextArea
                                        caption="Comments"
                                        id="comment"
                                        value={RequestObj.comment}
                                        variant="outlined"
                                        label="Comments"
                                        name="comment"
                                        onChange={handleChange}
                                        type="text"
                                        size="medium"
                                      />
                                    </GridItem>
                                    {CashWithdrawal ? (
                                      <GridItem lg={12} sm={12} xs={12} md={12}>
                                        <NQInputText
                                          caption="Amount"
                                          id="amount"
                                          value={RequestObj.amount}
                                          variant="outlined"
                                          label="Amount"
                                          name="amount"
                                          onChange={handleChange}
                                          type="number"
                                          size="medium"
                                        />
                                      </GridItem>
                                    ) : (
                                      ""
                                    )}
                                    {UpdateClientAccountDetail ? (
                                      <GridItem
                                        lg={12}
                                        sm={12}
                                        xs={12}
                                        md={12}
                                        style={{ marginTop: "15px" }}
                                      >
                                        <NQDropDown
                                          caption="Required changes"
                                          id="typeOfRequiredChanges"
                                          variant="outlined"
                                          label="name"
                                          required={true}
                                          name="typeOfRequiredChanges"
                                          onChange={(e) =>
                                            TypeChangeReqDetail(e)
                                          }
                                          value={
                                            RequestObj.typeOfRequiredChanges
                                          }

                                          data={EnumTypesList?.Data}
                                          multiple={true}
                                          placeholder="Required changes detail"
                                        />
                                      </GridItem>
                                    ) : (
                                      ""
                                    )}
                                    <GridItem
                                      lg={12}
                                      sm={12}
                                      xs={12}
                                      md={12}
                                      style={{ marginTop: "15px" }}
                                    >
                                      {!files?.name
                                        ? <Dropzone
                                          SubmitButtonComponent={null}
                                          getUploadParams={getUploadParams}
                                          onChangeStatus={handleChangeStatus}
                                          accept="*"
                                          maxFiles={1}
                                          multiple={false}
                                          inputContent="Drag File 1 or Click to Browse"
                                        /> :
                                        <div style={{
                                          border: '1px solid', minHeight: '40px', minWidth: '40px', borderColor: '#600ce8'
                                        }}>
                                          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px', fontSize: '13px' }}>
                                            <div>
                                              <span onClick={downloadFile} style={{ borderBottom: '1px solid', color: 'blue', fontWeight: '900' }}> {files?.name} </span>
                                            </div>
                                            <div className="ABCsvgIcon" style={{ paddingTop: '5px' }}>
                                              <span className="SVGCloseIcon"> <CloseIcon onClick={onDelete} /> </span>
                                            </div>
                                          </div>
                                        </div>}
                                    </GridItem>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="css-dvxtzn">
                            {loading ? <CircularProgress /> :
                              <button
                                onClick={(e) => handleNext(e, "secstep")}
                                type="submit"
                                className="css-jhd49u"
                              >
                                Next
                              </button>
                            }
                            <button
                              disabled={activeStep == 0}
                              type="button"
                              onClick={handleBack}
                              className="css-1gld5x9"
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              ) : (
                <div className="nextstep">
                  <GridContainer>
                    <GridItem lg={12} sm={12} xs={12} md={12}>
                      <div className="css-q4good">
                        <h1 className="css-1tu684v">Create Request</h1>
                        <div>
                          <div className="sc-ljslrt bOCWrd"><div className="sc-fujyUd foxWyQ"><div className="sc-fmdMXc gkRBAV"><div className="sc-ljslrt cdDuvV"><div className="css-0"><p className="css-olq2dz">Comment</p><span className="css-1crpfoq">{RequestObj.comment}</span></div></div>
                          <div className="sc-ljslrt cdDuvV"><div className="css-0"><p className="css-olq2dz">Portfolio</p><span className="css-1crpfoq">
                            {/* {
                            props.ClientRequestList.filter(
                              (a) => a.key === RequestObj.clientKey
                            )[0] && props.ClientRequestList.filter(
                              (a) => a.key === RequestObj.clientKey
                            )[0].name
                          } */}
                            {portfolioType}
                          </span></div></div><div className="sc-ljslrt cdDuvV"><div className="css-0"><p className="css-olq2dz">Requried Type</p><span className="css-1crpfoq">
                            {/* {
                            requestTypeArray.filter(
                              (a) =>
                                a.field ===
                                RequestObj.requestType ||
                                a.value === RequestObj.requestType
                            )[0] && requestTypeArray.filter(
                              (a) =>
                                a.field ===
                                RequestObj.requestType ||
                                a.value === RequestObj.requestType
                            )[0].value
                          } */}
                            {selectedrequestType}
                          </span></div></div></div></div></div>
                          <div className="css-dvxtzn">
                            <button
                              onClick={handleSave}
                              type="button"
                              className="css-jhd49u"
                            >
                              Save
                            </button>
                            <button
                              disabled={activeStep == 0}
                              type="button"
                              onClick={handleBack}
                              className="css-1gld5x9"
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      ) : null
      }
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    ClientRequestList: state.factFindReducer.ClientRequestList,
    apiresponse: state.httpActionReducer.response,
    nqSuperSelected: state.sideBarReducer.nqSuperSelected,
    portfoliosDataObj:state.portfoliosReducer.portfoliosDataObj
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEnumTypes: () => dispatch(getEnumbyType("TypeOfRequiredChanges")),
    requestStatus: () => dispatch(getEnumbyType("RequestStatuses")),
    AddNewRequest: (NewRequest, history) =>
      dispatch(AddNewRequest(NewRequest, history)),
    getClients: () => dispatch(getClients()),
    getByKey: (url) => dispatch(GetByKey(url)),
    portfoliosList: (isSuperSelected) => dispatch(getClientPortfolioList(isSuperSelected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRequest);
