import { API_ERROR, Portfolios, SHOW_LOADER, isSuperOnboardingCompleted, isSuperPortfolioExist, portfolioLoader } from "../types";

const initialState = {
  portfoliosDataObj: [],
  loading: false,
  portfolioLoader: true,
  isSuperOnboardingCompleted: false,
  isSuperPortfolioExist: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Portfolios:
      return {
        ...state,
        portfoliosDataObj: action.payload,
      };
    case SHOW_LOADER:
      return { ...state, loading: action.payload };
    case portfolioLoader:
      return { ...state, portfolioLoader: action.payload };
    case isSuperOnboardingCompleted:
      return { ...state, isSuperOnboardingCompleted: action.payload };
    case isSuperPortfolioExist:
      return { ...state, isSuperPortfolioExist: action.payload };
    default:
      return state;
  }
}
