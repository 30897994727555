import { connect } from "react-redux";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import "./MemberDetail.scss"
import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import axios from "axios";
import VisibilityOffIcon from '@material-ui/icons//VisibilityOff';
import { ByFundingOption } from "constant/APICalls";
import { showError, showSuccess } from "utils/NQToaster";
import Modal from "components/Modal/modal";
import { Adviser } from "constant/APICalls";
import NQTextField from "components/TextField/NqTextField";
import { getMemberType } from "store/action/memberTypeAction";
import { getByMembership } from "store/action/memberTypeAction";
import Loading from "components/Loading";


const MemberDetail = (props) => {


    const [contactMemberDetails, setcontactMemberDetails] = React.useState([]);
    const [tfnNumber, setTfnNumber] = React.useState(null);
    const [tfnAttributeKey, setTfnAttributeKey] = React.useState(null);
    const [timeLeft, setTimeLeft] = React.useState(10);
    const [intervalId, setintervalId] = React.useState(0);
    const [timeLeftCode, setTimeLeftCode] = React.useState(0);
    const [intervalIdCode, setintervalIdCode] = React.useState(0);
    const [showModal, setShowModal] = React.useState(false);
    const [showModalVerify, setShowModalVerify] = React.useState(false);
    const [isdisabled, setisdisabled] = React.useState(false);
    const [clientBeneficiaries, setclientBeneficiaries] = React.useState([]);
    const [insurancePolicy, setinsurancePolicy] = React.useState([]);
    const [adviserDetails, setAdviserDetails] = React.useState([]);
    const [nqsuperInfo, setnqsuperInfo] = React.useState({});
    const [stateNumber, setNumber] = React.useState({
        NumberOne: "",
        NumberTwo: "",
        NumberThree: "",
        NumberFour: "",
        NumberFive: "",
        NumberSix: ""
      })


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        props.memberType();
        props.ByMembershipType();
        getContactMemberDetails();
        getContactDetails();
        getTFN(props.clientKey[0]);
        getContactInsurancePolicy();
        GetClientBeneficiaries();
        getAdviserDetails(getUniqueClientKeys(props.clientKey));

    }


    const getContactMemberDetails = () => {
        let env = JSON.parse(localStorage.getItem("env"));
        let workSpaceKey = localStorage.getItem("workspaceKey");
        let contactKey = localStorage.getItem("contactKey");
        axios.get(`${env.apiUrl}Contacts/${contactKey}/ContactMemberDetails/${workSpaceKey}`).then(res => {
            setcontactMemberDetails(res.data.data);
        }).catch(err => {
        })

    }

    const getContactDetails = () => {
        let env = JSON.parse(localStorage.getItem("env"));
        let workSpaceKey = localStorage.getItem("workspaceKey");
        let contactKey = localStorage.getItem("contactKey");
        axios.get(`${env.apiUrl}Contacts/${contactKey}/ClientInsurancePolicy/${workSpaceKey}`).then(o => {
          setnqsuperInfo(o.data.data.filter(o => o.clientType == 11)[0]);
    
        }).catch(err => {
        })
    
      }

    
  const getContactInsurancePolicy = () => {

    let env = JSON.parse(localStorage.getItem("env"));
    let workSpaceKey = localStorage.getItem("workspaceKey");
    let contactKey = localStorage.getItem("contactKey");
    axios.get(`${env.apiUrl}Contacts/${contactKey}/ClientInsurancePolicy/${workSpaceKey}`).then(o => {
      if (o.data) {
        let policies = o.data.data.map(o => ({ ...o, extensions: Array.isArray(o.insurancePolicyExtensions) ? o.insurancePolicyExtensions.map(o => o.insurancePolicyTypeName + ', ') : '' }))
        setinsurancePolicy(policies);
      }

    }).catch(err => {
    })
  }

  const GetClientBeneficiaries = () => {

    let env = JSON.parse(localStorage.getItem("env"));
    let workSpaceKey = localStorage.getItem("workspaceKey");
    let contactKey = localStorage.getItem("contactKey");
    axios.get(`${env.apiUrl}Contacts/${contactKey}/ClientBeneficiaries/${workSpaceKey}`).then(o => {

        if(props.clientType == "VMA"){
            setclientBeneficiaries(o && o.data && o.data.data.filter(a => a.clientType != "Super" && a.clientType != "Pension"));
        }else{
            setclientBeneficiaries(o && o.data && o.data.data.filter(a => a.clientType == props.clientType));
        }

        
    }).catch(err => {
    })
  }

  const getAdviserDetails = (clientKey) => {
    let env = JSON.parse(localStorage.getItem("env"));
    let workspaceKey = localStorage.getItem("workspaceKey");
    const url = `${Adviser.controller}/${Adviser.action}/${workspaceKey}`;
    axios.post(`${env.apiUrl}${url}`,clientKey).then(o => {

        setAdviserDetails(o.data.data)

    }).catch(err => {
    })

  }

  const getUniqueClientKeys = (data) => {

    let arr = [];
    arr = data.reduce((a,b) => {
        if(a.indexOf(b) < 0) a.push(b);
        return a
    },[]);

    return arr;
  }

    const getTFN = async (ClientKey) => {
        const workspaceKey = localStorage.getItem("workspaceKey");
        const url = `${ByFundingOption.controller}/${ClientKey}/${ByFundingOption.action}/${workspaceKey}`;
        const env = JSON.parse(localStorage.getItem("env"));
        axios.get(`${env.apiUrl}${url}`).then(o => {
          if(o.data?.data){
            
            let data = o.data.data?.find(x=> x.name == "TFN");
            let tfnNumber = data?.value;
            let attributeKey = data?.nucleusAttributeKey;
            if(tfnNumber){

                setTfnNumber("been Supplied"); 
                setTfnAttributeKey(attributeKey);
              
            }else{
               setTfnNumber('Not been Supplied')
            }
            
          }
         
        }).catch(err => {
        })
      }

    const handleToggleModal = () => {
        setShowModal(!showModal);
    }
    const handleVrifyCode = (emailAddress) => {
        submitCode(emailAddress);
    }
    const verifyHandle = (e) => {

        let workSpaceKey = localStorage.getItem("workspaceKey");
        const value = stateNumber.NumberOne + stateNumber.NumberTwo + stateNumber.NumberThree + stateNumber.NumberFour + stateNumber.NumberFive + stateNumber.NumberSix;
        if (value != null & value != "") {
            let requestContent =
            {
                code: value,
                nucleusAttributeKey: tfnAttributeKey,
                workSpaceKey: workSpaceKey
            }
            let env = JSON.parse(localStorage.getItem("env"));
            axios.put(`${env.apiUrl}IdentifierVerification/VerifyCode`, requestContent).then(o => {
                if (o.data.value != null) {
                    setTfnNumber(o.data.value);
                    setShowModalVerify(false);
                }
                else {
                    showError("Invalid Verification Code");
                }
            }).catch(err => {
            })
        }
        else {
            showError("Please Enter the Code...")
        }
    }
    const resendCode = (emailAddress) => {
        submitCode(emailAddress, 'resend')
    }
    const submitCode = (emailAddress, resend = null) => {
        setisdisabled(true);
        const email = 'waqas@newquantum.com';
        if (email != null && email != "") {
            let workSpaceKey = localStorage.getItem("workspaceKey");
            let requestContent =
            {
                emailAddress: email,
                mobileNumber: props.byMembershipTypeDataObj[0]?.phones.filter(
                    (p) =>
                        p.isPrimary === true && p.type === "Mobile"
                )[0]?.phone,
                nucleusAttributeKey: tfnAttributeKey,
                workSpaceKey: workSpaceKey
            }
            let env = JSON.parse(localStorage.getItem("env"));
            axios.put(`${env.apiUrl}IdentifierVerification/SendVerificationCode`, requestContent).then(o => {
                if (o.data.isCompleted) {
                    if (resend == null) {
                        setShowModal(false);
                        setShowModalVerify(!showModalVerify);
                    }
                    SetTimeOutCode();
                    showSuccess("Verification code sent to email address or mobile number.")
                }
                else {
                    showError(o.data.result)
                }
            }).catch(err => {
                showError(err)
            })
        }
        else {
            if (email == null) {
                setShowModalVerify(false);
            }
            else {
                showError("Email Address Not Found...")
            }
        }
    }
    const authentiaction = () => {
        handleToggleModal();
        setTimeLeft(300)
        SetTimeOut();
    }
    const SetTimeOut = () => {
        const chnageUi = document.getElementById("view");
        chnageUi.style = 'display:none'
        const interval = setInterval(() => {
            setTimeLeft((t) => t - 1);
        }, 1000);
        setintervalId(interval)
        return () => clearInterval(intervalId);
    }
    const SetTimeOutCode = () => {
        setTimeLeftCode(300)
        const interval = setInterval(() => {
            setTimeLeftCode((t) => t - 1);
        }, 1000);
        setintervalIdCode(interval)
        return () => clearInterval(intervalIdCode);
    }
    const format = (seconds) => {
        if (seconds <= '00') {
            clearInterval(intervalId);
            timeOut();
            return "00:00"
        }
        else {
            var minutes = ("00" + Math.floor((seconds % 3600) / 60)).slice(-2);
            var seconds = ("00" + (seconds % 3600) % 60).slice(-2);
            return minutes + ":" + seconds;
        }
    }
    const formatCodeTime = (seconds) => {
        if (seconds <= '00') {
            clearInterval(intervalIdCode);
            return "00:00"
        }
        else {
            var minutes = ("00" + Math.floor((seconds % 3600) / 60)).slice(-2);
            var seconds = ("00" + (seconds % 3600) % 60).slice(-2);
            return minutes + ":" + seconds;
        }
    }
    const timeOut = () => {
        var timeout = document.getElementById("view");
        timeout.style = 'display:initial'
        timeout.style = 'cursor:pointer';
    }

    const setNumberHandle = (e, target) => {
        const value = e.target.value;
        if (value.length <= 1) {
          if (value.length == 0) {
            setNumber({
              ...stateNumber,
              [e.target.name]: value
            })
          }
          else {
            document.getElementById("currentValue" + target).focus();
            setNumber({
              ...stateNumber,
              [e.target.name]: value
            })
          }
        }
        else {
          document.getElementById("currentValue" + target).focus();
        }
    
    
      }


    return (
        <div>
            <GridContainer>
                {props.clientType != "VMA" ? 
                <GridItem lg={12} sm={12} xs={12} md={12} >
                    <div className="css-1ulh703">
                        <div className="css-18akwle">
                            <p className="css-p">Member details</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#6F708C"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <rect
                                    x="3"
                                    y="11"
                                    width="18"
                                    height="11"
                                    rx="2"
                                    ry="2"
                                ></rect>
                                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                            </svg>

                        </div>
                        <div className="css-vy2pbh">
                            <div className="css-12u19mu">
                                <div className="css-3or2ho">
                                    <span>verified</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </div>
                            </div>
                            <div className="css-xqrdoy">
                                <div className="css-1qm1lh">
                                    <p className="css-fqk8rb">Member Name</p>
                                    <span className="css-1crpfoq">
                                        {contactMemberDetails && contactMemberDetails[0] ? contactMemberDetails[0].name : "--"}
                                    </span>
                                </div>
                                <div className="css-1qm1lh">
                                    <p className="css-fqk8rb">Member Number</p>
                                    <span className="css-1crpfoq">
                                        {contactMemberDetails && contactMemberDetails[0] ? contactMemberDetails[0].nqspMemberNumber : "--"}
                                    </span>
                                </div>
                                <div className="css-1qm1lh">
                                    <p className="css-fqk8rb">Unique Super Identifier(USI)</p>
                                    <span className="css-1crpfoq"> 30099320583003 </span>
                                </div>
                                <div className="css-1qm1lh">
                                    <p className="css-fqk8rb">Australian Business Number</p>
                                    <span className="css-1crpfoq"> 30099320583 </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </GridItem> : null}
                <GridItem lg={12} sm={12} xs={12} md={12}>
                    <div className="css-1ulh703">
                        <div className="css-18akwle">
                            <p className="css-p">Tax file number</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#6F708C"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <rect
                                    x="3"
                                    y="11"
                                    width="18"
                                    height="11"
                                    rx="2"
                                    ry="2"
                                ></rect>
                                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                            </svg>
                        </div>
                        <div className="css-vy2pbh">

                            <div className="css-xqrdoy">
                                <p className="sc-cbeQSR eFrFNd">Your Tax File Number has <span className={
                                    tfnNumber === 'been Supplied'
                                        ? "tfnSupplied"
                                        : tfnNumber === 'Not been Supplied'
                                            ? "notTfnSupplied"
                                            : ""
                                }>
                                    {tfnNumber == "been Supplied" || tfnNumber == "Not been Supplied" && timeLeft >= 0 ? tfnNumber
                                        : format(timeLeft) <= "00:00" ? "been Supplied" : tfnNumber
                                    }
                                </span>. 
                                {
                                    tfnNumber == "been Supplied" ? <span className="view-Icon" id="view" style={{ cursor: 'pointer' }}><VisibilityOffIcon onClick={() => authentiaction()}/></span> : null
                                }
                                
                                </p>
                                <input value={format(timeLeft)} hidden={true} readOnly={true} />
                            </div>
                        </div>
                    </div>
                </GridItem>
                <GridItem lg={12} sm={12} xs={12} md={12}>
                    <div className="css-1ulh703 ">
                        <div className="css-18akwle">
                            <p className="css-p">Beneficiaries</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#6F708C"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <rect
                                    x="3"
                                    y="11"
                                    width="18"
                                    height="11"
                                    rx="2"
                                    ry="2"
                                ></rect>
                                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                            </svg>
                        </div>
                        <div className="css-vy2pbh foxWyQ">
                            <div className="sc-fmdMXc gkRBAV">
                                <div className="sc-ljslrt cJAFac">
                                    <div className="sc-fmdMXc gkRBAV">
                                        <div className="sc-ljslrt cdDuvV">
                                            <p className="sc-cbeQSR eFrFNd">A beneficiary is the person you have nominated to receive your super in the event of your death. To ensure your benefits go to the right person(s), please make sure you keep this information up to date.</p>
                                        </div><div className="sc-ljslrt cdDuvV">
                                            <p className="sc-cbeQSR eFrFNd">
                                                It may take up to five days for updates to non-binding beneficiary information to be reflected in your account.</p>
                                        </div>
                                    </div></div>
                                <div className="sc-ljslrt cJAFac">
                                    <div className="sc-fmdMXc gkRBAV">
                                        <div className="sc-ljslrt bOCWrd">
                                            <p className="sc-dSnYFs jPDzqR">Benefit proportions</p>
                                        </div>
                                        <div id="no-more-tables">
                                            <table id="myTable" className=" table-bordered table-striped table-condensed cf w-100">
                                                <thead className="cf">
                                                    <tr className="header">
                                                        <th className="w-20">Name</th>
                                                        <th className="w-20">Beneficiary Type</th>
                                                        <th className="w-20">Ownership</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { clientBeneficiaries && clientBeneficiaries.length > 0 ?
                                                    clientBeneficiaries.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td data-title="Name" className="mobile-text">{item.name}</td>
                                                                <td data-title="Beneficiary Type" className="mobile-text">{item.beneficiaryType}</td>
                                                                <td data-title="ownership" className="mobile-text">{Math.round(item.ownership * 100)}%</td>
                                                            </tr>
                                                        )
                                                    }) : null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div></div></div>
                        </div>
                    </div>
                </GridItem>
                {props.clientType != "VMA" ? 
                <GridItem lg={12} sm={12} xs={12} md={12}>
                    <div className="css-1ulh703 ">
                        <div className="css-18akwle">
                            <p className="css-p">Insurance Summary</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#6F708C"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <rect
                                    x="3"
                                    y="11"
                                    width="18"
                                    height="11"
                                    rx="2"
                                    ry="2"
                                ></rect>
                                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                            </svg>
                        </div>
                        <div className="css-vy2pbh foxWyQ">
                            <div className="sc-fmdMXc gkRBAV">
                                <div className="sc-ljslrt cJAFac">
                                    <div className="sc-fmdMXc gkRBAV">
                                    </div><div className="sc-ljslrt cJAFac">
                                    </div>

                                    <div id="no-more-tables">
                                        <table id="myTable" className=" table-bordered table-striped table-condensed cf w-100">
                                            <thead className="cf">
                                                <tr className="header">
                                                    <th className="w-40">Policy Cover</th>
                                                    <th className="w-20">Policy No.</th>
                                                    <th className="w-20">Insurer</th>
                                                    <th className="w-20">Cover</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {insurancePolicy.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td data-title="Policy Cover" className="mobile-text">{item.insurancePolicyTypeName}</td>
                                                            <td data-title="Policy No." className="mobile-text">{item.policyNo}</td>
                                                            <td data-title="Insurer" className="mobile-text">{item.productName}</td>
                                                            <td data-title="Cover" className="mobile-text">{item.sumInsured}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </GridItem>: null}
                <GridItem lg={12} sm={12} xs={12} md={12}>
                    <div className="css-1ulh703" style={{ display: "none" }}>
                        <div className="css-vy2pbh ">
                            <div className="css-xqrdoy">
                                <div>
                                    <div className="css-1qm1lh">
                                        <p className="css-fqk8rb">Regular Investment Amount</p>
                                        <span className="css-1crpfoq">
                                            {/* {memberInfo?.emailAddress} */}
                                            {props &&
                                                props.memberTypeDataObj[0] &&
                                                props.memberTypeDataObj[0].emailAddress
                                                ? props.memberTypeDataObj[0].emailAddress
                                                : "--"}
                                        </span>
                                    </div>
                                    <div className="css-1qm1lh">
                                        <p className="css-fqk8rb">Payment Method</p>
                                        <span className="css-1crpfoq">
                                            {props.byMembershipTypeDataObj[0]?.phones
                                                ? props.byMembershipTypeDataObj[0]?.phones.filter(
                                                    (p) =>
                                                        p.isPrimary === true && p.type === "Mobile"
                                                )[0]?.phone
                                                : "--"}
                                        </span>
                                    </div>
                                    <div className="css-1qm1lh">
                                        <p className="css-fqk8rb">Frequency</p>
                                        <span className="css-1crpfoq">
                                            {props.byMembershipTypeDataObj[0]?.phones
                                                ? props.byMembershipTypeDataObj[0]?.phones.filter(
                                                    (p) =>
                                                        p.isPrimary === true && p.type === "Mobile"
                                                )[0]?.phone
                                                : "--"}
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </GridItem>
                <GridItem lg={12} sm={12} xs={12} md={12}>
                    <div className="css-1ulh703">
                        <div className="css-18akwle">
                            <p className="css-p">Adviser Details</p>
                            {/* <a className="css-phtnxg">Edit</a> */}
                        </div>
                        <div className="css-vy2pbh">
                            <div className="css-xqrdoy">
                                {
                                    adviserDetails && adviserDetails.length > 0 ?

                                        <div id="no-more-tables">

                                            <table id="myTable" className=" table-bordered table-striped table-condensed cf w-100 fixed-table">
                                                <thead className="cf">
                                                    <tr className="header">
                                                        <th className="w-20">Financial Group</th>
                                                        <th className="w-20">Adviser Firm</th>
                                                        <th className="w-20">Name</th>
                                                        <th className="w-20">Phone Number</th>
                                                        <th className="w-20">Email</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {adviserDetails.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td data-title="Financial Group" className="mobile-text wrap-text">{item.financialGroupName}</td>
                                                                <td data-title="Adviser Firm" className="mobile-text wrap-text">{item.adviserFirmName}</td>
                                                                <td data-title="Name" className="mobile-text wrap-text">{item.adviserName}</td>
                                                                <td data-title="Phone Number" className="mobile-text wrap-text">{item.adviserPhone}</td>
                                                                <td data-title="Email" className="mobile-text wrap-text" >{item.adviserEmail}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        : <div> No Adviser assigned. </div>
                                }
                            </div>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
            <Modal show={showModal} handleClose={handleToggleModal} heading="Get Verification Code">
                <div>
                    <div className="Modal-flex">
                        <div className="divided50">
                            <p>Mobile Number</p>
                            <p className="field">{props.byMembershipTypeDataObj[0]?.phones
                                ? props.byMembershipTypeDataObj[0]?.phones.filter(
                                    (p) =>
                                        p.isPrimary === true && p.type === "Mobile"
                                )[0]?.phone
                                : "--"}</p>
                        </div>
                        <div className="divided50">
                            <p>Email</p>
                            <p className="field">{props &&
                                props.memberTypeDataObj[0] &&
                                props.memberTypeDataObj[0].emailAddress
                                ? props.memberTypeDataObj[0].emailAddress
                                : "--"}</p>
                        </div>
                    </div>
                    <div className="mdactions">
                        <button className="btnui btnuiok" disabled={isdisabled} type="button" onClick={() => handleVrifyCode(props.memberTypeDataObj[0].emailAddress)} >Send Code</button>
                    </div>
                </div>
            </Modal>

            <Modal show={showModalVerify} handleClose={() => handleVrifyCode(null)} heading="Enter Code For Verification">
                <div>
                    <p>We emailed you on your email. Please enter the code</p>
                    <div className="Modal-flex flexcc">
                        <div>
                            <NQTextField className="numberValue" id="currentValue1" type="number" name='NumberOne' value={stateNumber.NumberOne} onChange={(e) => setNumberHandle(e, 2)} />
                        </div>
                        <div>
                            <NQTextField className="numberValue" id="currentValue2" type="number" name='NumberTwo' value={stateNumber.NumberTwo} onChange={(e) => setNumberHandle(e, 3)} />
                        </div>
                        <div>
                            <NQTextField className="numberValue" id="currentValue3" type="number" name='NumberThree' value={stateNumber.NumberThree} onChange={(e) => setNumberHandle(e, 4)} />
                        </div>
                        <div>
                            <NQTextField className="numberValue" id="currentValue4" type="number" name='NumberFour' value={stateNumber.NumberFour} onChange={(e) => setNumberHandle(e, 5)} />
                        </div>
                        <div>
                            <NQTextField className="numberValue" id="currentValue5" type="number" name='NumberFive' value={stateNumber.NumberFive} onChange={(e) => setNumberHandle(e, 6)} />
                        </div>
                        <div>
                            <NQTextField className="numberValue" id="currentValue6" type="number" name='NumberSix' value={stateNumber.NumberSix} onChange={(e) => setNumberHandle(e, 1)} />
                        </div>
                    </div>
                    <div className="mdactionsVerifyModel">
                        <div className="textTime">{formatCodeTime(timeLeftCode)}</div>
                        {
                            formatCodeTime(timeLeftCode) <= "00:00"
                                ? <button className=" btnui btnuiok" id="resendcode" type="button" onClick={(e) => resendCode(props.memberTypeDataObj[0].emailAddress)} >Resend Code</button>
                                : <button className="btnui btnuiok" id="verifycode" type="button" onClick={(e) => verifyHandle(e)} >Verify</button>
                        }


                    </div>
                </div>

            </Modal>
        </div>
    )


}


const mapStateToProps = (state) => {
    return {
        memberTypeDataObj: state.memberTypeReducer.memberTypeDataObj,
        byMembershipTypeDataObj: state.memberTypeReducer.byMembershipTypeDataObj,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        memberType: () => dispatch(getMemberType()),
        ByMembershipType: () => dispatch(getByMembership()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withAuthenticationRequired(MemberDetail, {
        onRedirecting: () => <Loading />,
    })
);
