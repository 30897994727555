import axios from "axios";
import { API_ERROR, NEWS_LIST, SHOW_LOADER } from "../types";

export const getNewsList = () => async (dispatch) => {
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = "Dashboard/GetLatestNews";
  dispatch(toggleLoading(true));
  newslist(env.apiUrl, url)
    .then((o) => {
      dispatch({
        type: NEWS_LIST,
        payload: o.data.data,
      });
      dispatch(toggleLoading(false));
    })

    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: [],
      });
      dispatch(toggleLoading(false));
    });
};

function newslist(baseURL, url) {
  return axios.get(`${baseURL}${url}`);
}

function toggleLoading(status) {
  return { type: SHOW_LOADER, payload: status };
}
