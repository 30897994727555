import React, { Component } from "react";
import PropTypes from "prop-types";
import   "./AccordionSection.scss"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
class AccordionSection extends Component {
    
  static propTypes = {
    parent: PropTypes.instanceOf(Object).isRequired,
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    
    const { onClick, props: { isOpen, label } } = this;
    return (
      <div
        className="NQ-accordion-section"
        style={{
          background: isOpen ? "rgb(245, 245, 248)" : "#fff",
          borderLeft:  isOpen ? "4px solid rgb(96, 12, 232)" : '',
        //   padding: "25px",
        //   borderBottom:'1px solid #ddd'
        }}
      >
        <div className="panel">
        <div onClick={onClick}  className="NQ-accordion-section-row-1">
          {this.props.children[0]}
          <span className="dropdownIcon">
            <ExpandMoreIcon
              style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
            <span style={{ display: 'none'}}>{label}</span>
          </span>
        </div>
        {isOpen && (
          <div
             className="NQ-accordion-section-row-2"
          >
            {this.props.children[1]}
          </div>
        )}
        </div>
      </div>
    );
  }
}

export default AccordionSection;
