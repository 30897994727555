export const Goals = {
  buyHome: "Buy a home",
  crushDebt: "Crush debt",
  saveForRainyDay: "save for a rainy day",
  somethingElse: "Something else",
};

export const goalHome = {
  annualIncome: 75000,
  mortgageRate: 3,
  percentDownPayment: 20,
  annualInsurance: 0,
  annualPropertyTax: 0,
  debtToIncomeRatio: 33,
  priorSavingAmount: 0,
  goalAmount: 0,

  goalType: "Home",
  monthlyContribution: null,
  name: "Buy Home",
  status: 0,
  isComplete: false,
  plannedDate: null,
  ContactKey: '',
  NucleusKey: ''

};
export const goalDebt = {
  goalType: "CrushDebt",
  name: "Debt",
  status: 0,
  goalAmount: 0,
  plannedDate: null,
  monthlyContribution: 0,
  outstandingBalance: null,
  interestRate: 0,
  factFindGoalDebtSetting: null,
  numberOfMonths: 0,
  isComplete: false,
  factFindGoalDebtCategory: null,
  monthlyPayment: 0,
  ContactKey: '',
  NucleusKey: ''
  
};
export const goalRainyDay = {
  goalType: "RainyDay",
  name: "Rainy Day",
  status: 0,
  goalAmount: 0,
  plannedDate: null,
  monthlyContribution: 0,
  averageMonthlySpending: 0,
  monthsWithoutIncome: 2,
  isComplete: false,
  priorSavingAmount: 0,
  ContactKey: '',
  NucleusKey: ''

};
export const goalOthers = {
  goalType: "Other",
  name: "Other",
  status: 0,
  goalAmount: 0,
  plannedDate: null,
  monthlyContribution: 0,
  factFindGoalOtherCategory: 0,
  isComplete: false,
  otherGoalAmount: 0,
  priorSavingAmount: 0,
  ContactKey: '',
  NucleusKey: ''
};
