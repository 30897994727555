import { MemberType, ByMembershipType, SHOW_LOADER } from "../types";

const initialState = {
  memberTypeDataObj: [],
  byMembershipTypeDataObj: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MemberType:
      return {
        ...state,
        memberTypeDataObj: action.payload,
      };
    case ByMembershipType:
      return {
        ...state,
        byMembershipTypeDataObj: action.payload,
      };
    case SHOW_LOADER:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
}
