import { SHOW_LOADER } from "store/types";
import { DeleteByKeyType } from "store/types";
import { GetByKeyType } from "store/types";

const initialState = {
  response: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GetByKeyType:
    case DeleteByKeyType:
      return {
        ...state,
        response: action.payload,
      };

    case SHOW_LOADER:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
}
