import React, { useEffect } from "react";
import { NQDateInputMonthFormatter } from "utils/formatter";
import "../TextField/NQ-input-text.scss";

export default function NQDateInput(props) {
  useEffect(() => { }, [props.value]);

  const monthformat = () => {
    return NQDateInputMonthFormatter(props.value);
  };
  return (
    <div className="NQ-Input-Text">
      <div className="css-79elbk">
        <div className="css-npjedo">
          <input
            {...props}
            value={monthformat()}
            type="month"
            onChange={props.onChange}
            name={props.name}
          />
          <label>{props.caption}</label>
        </div>
      </div>
    </div>
  );
}
