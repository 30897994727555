import { any } from "prop-types";
import {
  AddDebtGoals,
  AddHomeGoals,
  AddRainyDayGoals,
  DeleteGoals,
  GetAllGoals,
  SHOW_LOADER,
  getGoalById,
  PutGoals,
  API_ERROR
} from "../types";

const initialState = {
  goalsData: [],
  getbyIdData: [],
  response: any,
  loading: false,
  isValid: false,
  isError:false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GetAllGoals:
      return {
        ...state,
        goalsData: action.payload,
      };
    case DeleteGoals:
      return {
        ...state,
        response: action.payload,
      };
    case AddHomeGoals:
      return {
        ...state,
        isValid: action.payload,
        loading: false,
      };
    case AddDebtGoals:
      return {
        ...state,
        isValid: action.payload,
        loading: false,
      };
    case AddRainyDayGoals:
      return {
        ...state,
        isValid: action.payload,
        loading: false,
      };
    case getGoalById:
      return {
        ...state,
        getbyIdData: action.payload,
        loading: false,
      };
    case PutGoals:
      return {
        ...state,
        response: action.payload,
        loading: false,
      };
    case SHOW_LOADER:
      return { ...state, loading: action.payload };
      
    case API_ERROR:
      return { ...state, isError: action.payload };

    default:
      return state;
  }
}
