export default class HelperExtentionMethods {
  static Calculation() {
    return 0;
  }
}

export function FV(rate, nper, pmt, pv, type) {
  var pow = Math.pow(1 + rate, nper),
    fv;
  if (rate) {
    fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
  } else {
    fv = -1 * (pv + pmt * nper);
  }
  return fv.toFixed(2);
}

export function XNPER(rate, pv, fv, pmt) {
  var nper, test;
  if (rate) {
    let pow = Math.abs((pmt - fv * rate) / (pv * rate + pmt));

    nper = Math.abs(Math.log(pow) / Math.log(1 + rate));
  } else {
    nper = Math.abs((-fv - pv) / pmt);
  }

  return nper;
}

export function XPMT(rate, nper, pv, fv) {
  var pow = Math.pow(1 + rate, nper),
    pmt;
  if (rate) {
    pmt = (fv + pv * pow) / ((1 - pow) / rate);
  } else {
    pmt = (-1 * (fv - pv)) / nper;
  }
  return pmt.toFixed(2);
}

export function PMT(rate, nperiod, pv, fv, type) {
  if (!fv) fv = 0;
  if (!type) type = 0;

  if (rate == 0) return -(pv + fv) / nperiod;

  var pvif = Math.pow(1 + rate, nperiod);
  var pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);

  if (type == 1) {
    pmt /= 1 + rate;
  }

  return pmt;
}

export function XPV(rate, nper, pmt) {
  if (rate == 0) return -pmt * nper;

  let pow = Math.pow(1 + rate, nper);
  return (-1 * pmt * (pow - 1)) / (rate * pow);
}

export function NPER(rate, pmt, pv, fv, type) {
  // Initialize type
  var type = typeof type === "undefined" ? 0 : type;

  // Initialize future value
  var fv = typeof fv === "undefined" ? 0 : fv;

  // Evaluate rate and periods (TODO: replace with secure expression evaluator)
  rate = eval(rate);

  // Return number of periods
  var num = pmt * (1 + rate * type) - fv * rate;
  var den = pv * rate + pmt * (1 + rate * type);

  let s = num / den;
  if (s < 0) return null;
  return Math.log(s) / Math.log(1 + rate);
}

export function RoundUpToInteger(value) {
  return Number.isInteger(Number(value))
    ? Number(value)
    : Math.trunc(Number(value) + 1);
}

export function formatPercentage(value, locale = "en-GB") {
  value = value ? value : 0;
  return Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  }).format(value);
}

export function isValidNumber(num) {
  return !isNaN(num);
}

export function isValidValue(value) {
  if (value) return true;
  else return false;
}


export function b64toBlob(b64Data, contentType = 'application/pdf', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}