import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loading from "components/Loading";
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormatDateToText } from 'utils/formatter';
import { getNewsList } from "../../store/action/newsAction";
import "../News/News.scss";

const News = (props) => {
  const large = useMedia("(min-width: 960px)");
  useEffect(() => {
    props.newslist();
  }, []);


  function useMedia(query) {
    const [matches, setMatches] = useState(
      window.matchMedia(query).matches
    );

    // Activity normally for componentDidMount + componentDidUpdate
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }

      const listener = () => setMatches(media.matches);
      media.addListener(listener);

      return () => media.removeListener(listener);
    }, [query]);

    // publish value for render
    return matches;
  }

  if (props.loading) {
    return <Loading />;
  } else {
    return (
      (props.nqSuperSelected && props.isSuperPortfolioExist) || !props.nqSuperSelected ?
      <div className="News">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div className="css-13bzwy9" style={{ margin: '20px 0px' }}>
              <label className="title"
                style={{
                  lineHeight: "1.42857",
                  color: "#aaaaaa",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                Today's news
              </label>
            </div>
          </GridItem>
          {large ?
            <>
              {props.newsDate && props.newsDate.map((item, i) => {
                return (
                  <Fragment key={`news-${i}`}>
                    <GridItem xs={12} sm={6} md={4} lg={4} key={i}>
                      <div className="css-pjo951" key={i}>
                        <div className="css-vy2pbh">
                          <a
                            href={item.news_url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <div className="css-iz0vde" tabIndex="0">
                              <img src={item.image_url} alt="" />
                              <div
                                className="css-1in2x64"
                                style={{ minHeight: "200px" }}
                              >
                                <h3 tabIndex="0">{item.websiteName}</h3>
                                <div className="text-EllipsisTitle">
                                  <h3 className="newsTitle">
                                    {item.title}
                                  </h3>
                                </div>
                                <div className="text-EllipsisText">
                                  <p className="newsSubTitle">
                                    {item.text}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                          <div style={{ paddingLeft: '10px', paddingBottom: '15px', display: 'flex', justifyContent: 'space-between', paddingRight: '15px' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <div>Date : </div>
                              <div>{FormatDateToText(item.date)}</div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <div>Sentiment : </div>
                              <div style={{ color: item.sentiment === 'Negative' ? 'red' : item.sentiment === 'Positive' ? 'green' : '#3c4858' }}>{item.sentiment}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                  </Fragment>
                );
              })}

            </>
            :
            <>
              <div className="NewsCard ismobile-News">
                <div className="scrollbar" id="style-1">
                  <div className="force-overflow">

                    {props.newsDate && props.newsDate.map((item, i) => {

                      return (
                        <>
                          <div className="css-pjo951" key={i} >
                            <div className="css-vy2pbh"  >
                              <a
                                href={item.news_url}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <div className="css-iz0vde" tabIndex="0">
                                  <img src={item.image_url} alt="" />
                                  <div
                                    className="css-1in2x64"
                                    style={{ minHeight: "200px" }}
                                  >
                                    <h3 tabIndex="0">{item.websiteName}</h3>
                                    <div className="text-EllipsisTitle">
                                      <h3 className="newsTitle">
                                        {item.title}
                                      </h3>
                                    </div>
                                    <div className="text-EllipsisText">
                                      <p className="newsSubTitle">
                                        {item.text}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </a>
                              <div style={{ paddingLeft: '10px', paddingBottom: '15px', display: 'flex', justifyContent: 'space-between', paddingRight: '15px' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                  <div>Date : </div>
                                  <div>{FormatDateToText(item.date)}</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                  <div>Sentiment : </div>
                                  <div style={{ color: item.sentiment === 'Negative' ? 'red' : item.sentiment === 'Positive' ? 'green' : '#3c4858' }}>{item.sentiment}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

            </>
          }

        </GridContainer>
      </div> : null
    );
  }
};
const mapStateToProps = (state) => {
  return {
    newsDate: state.newsReducer.newsDate,
    loading: state.newsReducer.loading,
    nqSuperSelected: state.sideBarReducer.nqSuperSelected,
    isSuperPortfolioExist: state.portfoliosReducer.isSuperPortfolioExist
  };
};

const mapDispatchToProps = (dispatch) => {
  return { newslist: () => dispatch(getNewsList()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
