import { connect } from "react-redux";
import "./BankDetail.scss"
import Loading from "components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { getMemberType } from "store/action/memberTypeAction";
import { getByMembership } from "store/action/memberTypeAction";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import axios from "axios";



const BankDetail = (props) => {

    const [bankDetails, setcontactBankDetails] = useState(null);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        getBankAccountDetail();
    }

    const getBankAccountDetail = () => {
        let env = JSON.parse(localStorage.getItem("env"));
        let workSpaceKey = localStorage.getItem("workspaceKey");
        let clientKey = props.clientKey[0];
        axios.get(`${env.apiUrl}Onboarding/${clientKey}/LinkedBankAccount/${workSpaceKey}`).then(res => {
            if (res?.data?.data?.length > 0) {
                setcontactBankDetails(res.data.data);
            }
        }).catch(err => {
        })

    }


    return (
        <GridContainer>
            <GridItem lg={12} sm={12} xs={12} md={12}>
                <div className="css-1ulh703">
                    <div className="css-18akwle">
                        <p className="css-p">Linked Bank Account</p>
                    </div>
                    <div className="css-vy2pbh">

                        <div className="css-xqrdoy">
                        <div id="no-more-tables">
                           
                                    <table id="myTable" className=" table-bordered table-striped table-condensed cf w-100">
                                      <thead className="cf">
                                        <tr className="header">
                                          <th className="w-30">Account Holder Name</th>
                                          <th className="w-20">BSB</th>
                                          <th className="w-20">Account Number</th>
                                          <th className="w-20">Status</th>
                                        </tr>
                                      </thead>
                                      {bankDetails!=null ?
                                      <tbody>
                                        {bankDetails.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td data-title="Account Holder Name" className="mobile-text">{item.accountName}</td>
                                              <td data-title="BSB" className="mobile-text">{item.bsb}</td>
                                              <td data-title="Account Number" className="mobile-text">{item.accountNumber}</td>
                                              <td data-title="Status" className="mobile-text"> 
                                              
                                                    <div className={`css-3or2ho ${item.statusDescription=="Pending" ? 'warning' : ''}`}>
                                                        <span> {item.statusDescription ? item.statusDescription : "--"} </span>
                                                    </div>
                                            
                                              </td>
                                            </tr>
                                          )
                                        })
                                        }
                                      </tbody> : null}
                                    </table> 
                                  </div>

                        </div>
                    </div>
                </div>
            </GridItem>
        </GridContainer>
    )

}


const mapStateToProps = (state) => {
    return {
        memberTypeDataObj: state.memberTypeReducer.memberTypeDataObj,
        byMembershipTypeDataObj: state.memberTypeReducer.byMembershipTypeDataObj,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        memberType: () => dispatch(getMemberType()),
        ByMembershipType: () => dispatch(getByMembership()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withAuthenticationRequired(BankDetail, {
        onRedirecting: () => <Loading />,
    })
);
