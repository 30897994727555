import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "components/Loading";
import News from "components/News/News";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getFactFindList } from "../../store/action/factFindAction";
import { getMemberType } from "../../store/action/memberTypeAction";
import Portfoliolist from "../Portfolio/Portfoliolist/Portfoliolist";
import "./Home.scss";
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from './../../components/Grid/GridItem';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import FileSaver from "file-saver";
import { useState } from "react";
import DocumentsList from '../Documents/Reports/Documents-list'
import { WorkspaceConfig } from "constant/APICalls";

const Home = (props) => {
  const [prescribedDocuments, setprescribedDocuments] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState('');
  const [logos, setLogoFile] = useState([]);

  const history = useHistory()
  useEffect(() => {
    fetchData();
  }, []);

  const getPrescribedDocuments = (isSuperSelected) => {
    let env = JSON.parse(localStorage.getItem("env"));
    let workSpaceKey = localStorage.getItem("workspaceKey");
    axios.get(`${env.apiUrl}ApprovedServices/PrescribedDocuments?workspaceKey=${workSpaceKey}&isSuperSelected=${isSuperSelected}`).then(o => {
      setprescribedDocuments(o.data.data);

    }).catch(err => {
    })
  }

  const getLogos = () => {
    let env = JSON.parse(localStorage.getItem("env"));
    const url = `${WorkspaceConfig.controller}/${WorkspaceConfig.action}`;
    axios.get(`${env.apiUrl}${url}`).then(o => {

      if (o.data.totalRecords > 0) {

        o.data.data.forEach(element => {
          setLogoFile(olddata => [...olddata, element]);
        });

      }

    }).catch(err => {
    })
  }



  const downloadFile = (item) => {
    let env = JSON.parse(localStorage.getItem("env"));
    let workSpaceKey = localStorage.getItem("workspaceKey");
    axios.get(`${env.apiUrl}ApprovedServices/${item.documentKey}/DownloadFile?workspaceKey=${workSpaceKey}&isInstrument=${item.isInstrument}`, { responseType: 'blob' }).then(o => {

      FileSaver.saveAs(o.data, item.documentName)
    }).catch(err => {
    })
  }

  const fetchData = async () => {
    await props.factFind(props.nqSuperSelected);
    await props.memberType();
    getPrescribedDocuments(props.nqSuperSelected);
    getLogos();
  };

  const navigateToDocuments = () => {
    history.push('/admin/prescribedDocument')
  }

  const clicKPorfolio = (value) => {
    setSelectedPortfolio(value)
  }

  return (
    <div className="Home">
      <div className="css-13bzwy9">
        <div className="css-k008qs">
          <ul className="list-logo">
            {logos && logos.length > 0 ?

              logos.map((o, index) => {

                if (index > 0) {
                  return ([<li className="withSymbol" key={index}><span className="AndSymbol">&</span></li>, <li><img src={o} className="imageHeightWidthForLogo"></img></li>])
                } else {
                  return (<li key={index}><img src={o} className="imageHeightWidthForLogo"></img></li>)
                }

              })

              : null}
          </ul>
        </div></div>
      <div className="css-13bzwy9">
        <div className="css-k008qs" style={{ margin: "20px 0" }}>
          <label className="title css-5no472 hello">
            {props.memberTypeDataObj.map((o) => "Hi " + o.firstName + " " + o.lastName)}
          </label>
        </div>
      </div>
      <Portfoliolist showPlugin={false} height={'150px'} handleSelectedPortfolio={clicKPorfolio} />
      {props.nqSuperSelected && props.isSuperOnboardingCompleted ? <GridContainer>
        <GridItem lg={6} sm={12} xs={12} md={12}>
          <div className="css-13bzwy9" style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center'

          }}>
            <div className="css-k008qs" style={{ margin: "20px 0" }}>
              <label className="title css-5no472" style={{ fontSize: '20px !important' }}>
                Important Documents
              </label>
            </div>
            {props.nqSuperSelected && prescribedDocuments ?
              <div onClick={navigateToDocuments} className="viewAll" style={{ cursor: 'pointer' }}>
                View All
              </div> : null}
          </div>

          <div className="NQ-mobile">
            {props.loading ? (
              <div className="flex-container">
                <div className="flex-items loader">Retrieving your Important Documents... <span>🔍</span></div>
              </div>) : !prescribedDocuments || prescribedDocuments.length === 0 ? (
                <div className="flex-container">
                  <div className="flex-items loader">You’re up to date.</div>
                </div>
              ) : null}

            {props.nqSuperSelected && prescribedDocuments && prescribedDocuments.length > 0 ?
              <div className="NQ-card impDoc-card-padding">
                <div className="NQ-card-body" style={{ padding: '0 !important' }}>
                  <DocumentsList />
                </div>
              </div>
              : null}
          </div>
        </GridItem>
        <GridItem lg={6} sm={12} xs={12} md={12}>
        </GridItem>
      </GridContainer> : null}
      <News />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    factFindDataObj: state.factFindReducer.factFindDataObj,
    memberTypeDataObj: state.memberTypeReducer.memberTypeDataObj,
    portfoliosDataObj: state.portfoliosReducer.portfoliosDataObj,
    loading: state.portfoliosReducer.loading,
    nqSuperSelected: state.sideBarReducer.nqSuperSelected,
    isSuperOnboardingCompleted: state.portfoliosReducer.isSuperOnboardingCompleted
  };
};

const mapDispatchToProps = (dispatch) => {
  return { factFind: (isSuperSelected) => dispatch(getFactFindList(isSuperSelected)), memberType: () => dispatch(getMemberType()), };
};
export default connect(mapStateToProps, mapDispatchToProps)(withAuthenticationRequired(Home, { onRedirecting: () => <Loading />, }));
