import axios from "axios";
import { Requests } from "constant/APICalls";


export const getRequest = () => async (dispatch) => {
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const contactKey = localStorage.getItem("contactKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}${Requests.controller}/ByContact/${contactKey}/${WorkspaceKey}`;
  return axios
    .get(url)

};

export const AddNewRequest = (NewRequest, history) => async (dispatch) => {
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  let url = '';
  if (NewRequest.key) {
    url = `${env.apiUrl}${Requests.controller}/${NewRequest.key ? NewRequest.key : ''}/${WorkspaceKey}`;
  }
  else {
    url = `${env.apiUrl}${Requests.controller}/${WorkspaceKey}`;
  }
  return axios
    .post(url, NewRequest)
};