import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TableAndChartLoading from "../../components/TableAndChartLoading/TableAndChartLoading";
import { screenType } from "Enums/screenType";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import EllipsisText from "react-ellipsis-text";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { DeleteGoals } from "store/action/goalsAction";
import { DeleteByKey } from "store/action/httpAction";
import { currencyFormat } from "utils/currencyFormat";
import debt from "../../assets/icon/debt.svg";
import houes from "../../assets/icon/houes.svg";
import other from "../../assets/icon/other.svg";
import rainyday from "../../assets/icon/rainyday.svg";
import { getGoals,loading } from "../../store/action/goalsAction";
import "./Goal.scss";

const Goal = (props) => {
  const [goalsData, setgoalsData] = useState([])
  const history = useHistory();
  const onItemClick = (e) => {
    if (!e) {
      history.push("/admin/addgoalbytype");
      return;
    }
    if (e) {
      if (e.goalType === "Buy a home") {
        history.push({
          pathname: "/admin/AddHome",
          key: `${e.key}`,
          factFindGoalHomeKey: `${e.factFindGoalHomeKey}`,
        });
      } else if (e.goalType === "Crush debt") {
        history.push({
          pathname: "/admin/AddDebt",
          key: `${e.key}`,
          factFindGoalDebtKey: `${e.factFindGoalDebtKey}`,
        });
      } else if (e.goalType === "Save for a rainy day") {
        history.push({
          pathname: "/admin/AddRainyDay",
          key: `${e.key}`,
          factFindGoalRainyDaysKey: `${e.factFindGoalRainyDaysKey}`,
        });
      } else if (e.goalType === "Something Else") {
        history.push({
          pathname: "/admin/AddSomethingElse",
          key: `${e.key}`,
          factFindGoalOtherKey: `${e.factFindGoalOtherKey}`,
        })
      } else {
        history.push({
          pathname: "/admin/addgoalbytype",
          key: `${e.key}`,
          factFindGoalOtherKey: `${e.factFindGoalOtherKey}`,
        });

      }
    }
  };


  const goalList = () => {
    props.goalsList()
  }



  useEffect(() => {
    goalList();
    history.listen((location, action) => {
      if (location.pathname === '/admin/Goal') {
        goalList();
      }
    })
  }, []);

  const ArchiveGoal = (goalObj) => {
    props.DeleteGoals(goalObj.key).then(o => {
      getGoals();
      var index = props.goalsData.indexOf(goalObj);
      delete props.goalsData[index];

    });

  };

  const DeleteWorking = (item) => {
    return (
      <DeleteIcon
        onClick={() => {
          ArchiveGoal(item);
          DeleteButtonClick(item);
        }}
      />
    );
  };

  //zeeshan
  const DeleteButtonClick = (item) => {
    let workspaceKey = localStorage.getItem("workspaceKey");
    const FactFindKey = localStorage.getItem("FactFindKey");
    let url = `FactFind/${FactFindKey}/Goals/${item.key}/${workspaceKey}`;

    props.DeleteByKey(url).then(o => {
      goalList()
    }).catch(er => { });
  };

  const EditGoal = (item) => {
    return (
      <EditIcon
        onClick={() => {
          onItemClick(item);
        }}
      />
    );
  };


  return (
    <div className="Goal">
      <div className="action-area">
        <div>
          <label className="title"> Goals </label>
        </div>
        <div className="action" onClick={onItemClick}>
          <button type="button" className="NQ-btn NQ-btn-blue"> Add Goal </button>
        </div>
      </div>
      {props.loading || props.goalsData.length == 0  ? (
            <TableAndChartLoading isLoad={props.loading} isNoRecord={props.goalsData.length == 0 ? true:false  } isError={props.isError} screen={screenType.Goal} />            
          ) : (

      <GridContainer>
        <div style={{ display: 'contents' }}>
            {props.goalsData.map((item, index) => {
              return (
                <GridItem lg={6} md={6} sm={12} xs={12} key={index}>
                  <div key={index} className="NQ-web">
                    <div className="NQ-card">
                      <div className="NQ-card-body">
                        <div className="NQ-left">
                          <div className="NQ-col-1">
                            <div>
                              {item.goalType === "Buy a home" ? (
                                <img
                                  fill="#4110b7"
                                  stroke="#4110b7"
                                  src={houes}
                                  alt={'Buy a home'}
                                  style={{ width: '70px', height: "70px" }}
                                />
                              ) : item.goalType === "Crush debt" ? (
                                <img
                                  fill="#4110b7"
                                  stroke="#4110b7"
                                  src={debt}
                                  alt={'Crush debt'}
                                  style={{ width: '70px', height: "70px" }}
                                />
                              ) : item.goalType === "Save for a rainy day" ? (
                                <img
                                  fill="#4110b7"
                                  stroke="#4110b7"
                                  style={{ width: '70px', height: "70px" }}
                                  src={rainyday}
                                  alt={'Save for a rainy day'}
                                />
                              ) : (
                                <img
                                  fill="#4110b7"
                                  stroke="#4110b7"
                                  style={{ width: '70px', height: "70px" }}
                                  src={other}
                                  alt={'Other'}
                                />
                              )}
                            </div>
                          </div>
                          <div className="NQ-col-2">
                            <div style={{ display: "grid" }}>
                              <span className="goaltext" >
                                <EllipsisText
                                  title={item.goalType}
                                  text={item.goalType}
                                  length={100}
                                />
                              </span>
                              <span>
                                <h5 className="f-weight-600 m-0 goalamount">
                                  {currencyFormat(item.goalAmount)}
                                </h5>
                              </span>
                              <span className="goalcategory">
                                <EllipsisText
                                  title={item.name}
                                  text={item.name}
                                  length={80}
                                />
                              </span>
                            </div>
                          </div>

                        </div>
                        <div className="NQ-right" style={{ width: '100% !important', }}>
                          <div className="NQ-col-1">
                            <div>
                            </div>
                            <div className="NQ-col-2 align-self-center pointer">

                            </div>
                          </div>
                          <div
                            className="NQ-col-2 align-self-center "
                            style={{ display: "flex !important" }}
                          >
                            <span className="pointer">{EditGoal(item)}</span>
                            <span className="pointer">
                              {DeleteWorking(item)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </GridItem>
              )
            })
          }
        </div>
      </GridContainer>
                )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    goalsData: state.goalsReducer.goalsData,
    Deleteresponse: state.goalsReducer.response,
    loading: state.goalsReducer.loading,
    isError:state.goalsReducer.isError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goalsList: () => dispatch(getGoals()),
    DeleteGoals: (goalKey) => dispatch(DeleteGoals(goalKey)),
    DeleteByKey: (url) => dispatch(DeleteByKey(url)),
    load:(status) => dispatch(loading(status))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Goal);
