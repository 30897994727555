import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import axios from "axios";
import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import Loading from './../../../components/Loading';
import "./Documents.scss";
import { connect } from 'react-redux';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const DocumentsList = (props) => {
  const [prescribedDocuments, setprescribedDocuments] = useState([]);
  const getPrescribedDocuments = (isSuperSelected) => {
    let env = JSON.parse(localStorage.getItem("env"));
    let workSpaceKey = localStorage.getItem("workspaceKey");

    axios.get(`${env.apiUrl}ApprovedServices/PrescribedDocuments?workspaceKey=${workSpaceKey}&isSuperSelected=${isSuperSelected}`).then(o => {
      setprescribedDocuments(o.data.data);

    }).catch(err => {
    })
  }

  const downloadFile = (item) => {
    let env = JSON.parse(localStorage.getItem("env"));
    let workSpaceKey = localStorage.getItem("workspaceKey");
    axios.get(`${env.apiUrl}ApprovedServices/${item.documentKey}/DownloadFile?workspaceKey=${workSpaceKey}&isInstrument=${item.isInstrument}`, { responseType: 'blob' }).then(o => {

      FileSaver.saveAs(o.data, item.documentName)
    }).catch(err => {
    })
  }

  useEffect(() => {
    getPrescribedDocuments(props.nqSuperSelected)
  }, [])


  return (
    <ul className="impDoc">
      {prescribedDocuments.map((o, i) => {
        return (
          <li onClick={() => downloadFile(o)} key={i}>
            <div className="eUedHw">
              <button className="bcxYtT" >
                <div className="fNPVNt">
                  <span className="cnKnPt">
                    {o.title}
                  </span>
                  <div className="eLMudl">
                    <div className="ItfWz">
                      <span className="icon">
                        <ArrowForwardIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </li>
        )
      })}
    </ul>
  );
};

const mapStateToProps = (state) => {
  return {
    nqSuperSelected: state.sideBarReducer.nqSuperSelected
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withAuthenticationRequired(DocumentsList, {
    onRedirecting: () => <Loading />,
  })
);
