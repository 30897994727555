import { Auth0Provider } from "@auth0/auth0-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import App from "../../App";
import store from "../../store/store";
import history from "../../utils/history";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

export default function Configuration(props) {

  const [domain, setDomain] = useState("");
  const [clientId, setClientId] = useState("");
  const [audience, setAudience] = useState("");
  const [isLoaded, setisLoaded] = useState(false);

  const configuration = () => {
    axios.get(`env.json`).then((env) => {
      localStorage.setItem("enviroment", JSON.stringify(env.data.environment));
      axios.get(`${env.data.environment}.json`).then((res) => {
        localStorage.setItem("env", JSON.stringify(res.data));
        setDomain(res.data.auth0Domain);
        setClientId(res.data.auth0ClientId);
        setAudience(res.data.audience);
        setisLoaded(true);
      });
    });
  };

  useEffect(() => {
    configuration();
  }, [isLoaded]);

  return (
    <Provider store={store}>
      {isLoaded && (
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          audience={audience}
          redirectUri={window.location.origin}
          scope="read:current_user update:current_user_metadata"
          onRedirectCallback={onRedirectCallback}
        >
          <App />
        </Auth0Provider>
      )}
    </Provider>
  );
}
