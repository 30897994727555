import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { currencyFormat } from "utils/currencyFormat";
import "./pot-card.scss";
import PotCardChart from "./PotCardChart";

const PotCard = (props) => {

  const history = useHistory();

  const navigateTo = (item) => {
    history.push({
      pathname: "/admin/Potdetaillist",
      CarryValue: props.Carry,
      selected: item,
      Data: props.Data,
    });
  };

  return (
    <div className="potcard">
      <div className="remove-padding">
        <GridItem xs={12} sm={12} md={12} lg={12} >
          <div onClick={() => navigateTo(props.caption)} style={{ display: 'flex', justifyContent: 'end', marginBottom: '10px' }}>
            <span className="pointer viewAll" style={{ background: 'transparent !important' }}>More </span>
          </div>
          <div className="NQ-mobile">
            <div className="NQ-card ">
              <div className="NQ-card-body" style={{ padding: '0 !important' }}>
                <GridContainer >
                  <GridItem xs={12} sm={12} md={12} >
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px !important' }}>
                      <div className="NQ-col-1 align-self-center" style={{ paddingTop: '0px' }}>
                        <div>
                          <h6 className="m-0" style={{ fontWeight: 700, textAlign: 'left !important' }}>
                            {props.caption}
                          </h6>
                        </div>
                      </div>
                      <div>
                        <span className="sc-dSnYFs ebARNv">
                          {currencyFormat(props.total ? props.total : 0)}
                        </span>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
                <div className="NQ-row-2 " style={{ height: '200px !important' }}>
                  <div className="NQ-col-2 align-self-center home-chart">
                    <PotCardChart
                      Data={props.Data}
                      Caption={props.caption}
                    />
                  </div>
                </div>
                <div className="NQ-row-3">
                </div>
              </div>
            </div>
          </div>
        </GridItem>
      </div>

    </div>
  );
};

const mapStateToProps = (state) => { return { Total: state.potsReducer.Total, }; };
const mapDispatchToProps = (dispatch) => { return {}; };

export default connect(mapStateToProps, mapDispatchToProps)(PotCard);
