import React from "react";
import loading from "../assets/newqum.gif";

const Loading = () => (
  <div className="spinner">
    <span style={{ alignSelf: "center", background: "#fff", color: "#fff" }}>
      <img style={{ width: "100px" }} src={loading} alt="Loading" />
    </span>
  </div>
);

export default Loading;
