import { FactFindExpenseCategory } from "store/types";
import { SHOW_LOADER } from "store/types";
import { FactFindIncomeCategory } from "store/types";
import { LiabilityTypes } from "store/types";
import { AssetTypes } from "store/types";

const initialState = {
  loading: false,
  objectList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AssetTypes:
    case LiabilityTypes:
    case FactFindExpenseCategory:
    case FactFindIncomeCategory:
      return {
        ...state,
        objectList: action.payload,
      };
    case SHOW_LOADER:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
}
