import axios from "axios";
import {
  API_ERROR, ClientRequestList, Crush_Debt_Type, GetAllFactFinds, SHOW_LOADER
} from "../types";

export const getFactFindList = (isSuperSelected) => async (dispatch) => {
  const url = `FactFind?isSuperSelected=${isSuperSelected}`;
  const env = JSON.parse(await localStorage.getItem("env"));
  dispatch(toggleLoading(true));
  factFind(env.apiUrl, url)
    .then((o) => {
      dispatch({
        type: GetAllFactFinds,
        payload: o.data.data,
      });
      setFactFindKey(
        o.data.data[0].key,
        o.data.data[0].workspaceKey,
        o.data.data[0].contactKey,
        o.data.data[0].adviserKey,
        o.data.data[0].currency
      );
      dispatch(toggleLoading(false));
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: [],
      });
      dispatch(toggleLoading(false));
    });
};

export const getFactFindDebtList = () => async (dispatch) => {
  const url = "FactFindGoalDebtSettings";
  const env = JSON.parse(await localStorage.getItem("env"));
  dispatch(toggleLoading(true));
  factFind(env.apiUrl, url)
    .then((o) => {
      dispatch({
        type: Crush_Debt_Type,
        payload: o.data,
      });
      dispatch(toggleLoading(false));
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: [],
      });
      dispatch(toggleLoading(false));
    });
};

export const getClients = () => async (dispatch) => {
  let contactKey = localStorage.getItem("contactKey");
  let workspaceKey = localStorage.getItem("workspaceKey");

  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}Contacts/${contactKey}/Clients/${workspaceKey}`;

  dispatch(toggleLoading(true));
  axios
    .get(url)
    .then((o) => {
      dispatch({
        type: ClientRequestList,
        payload: o.data.data,
      });
      dispatch(toggleLoading(false));
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: [],
      });
      dispatch(toggleLoading(false));
    });
};

const setFactFindKey = (
  Key,
  workspaceKey,
  contactKey,
  adviserKey,
  currency
) => {
  localStorage.setItem("FactFindKey", Key);
  localStorage.setItem("workspaceKey", workspaceKey);
  localStorage.setItem("contactKey", contactKey);
  localStorage.setItem("adviserKey", adviserKey);
  localStorage.setItem("currency", currency);
};

function factFind(baseURL, url) {
  return axios.get(`${baseURL}${url}`);
}

function toggleLoading(status) {
  return { type: SHOW_LOADER, payload: status };
}
