import { NQSuperSelected, SideBar } from "store/types";

export const setSideBar = (menu) => async (dispatch) => {
  dispatch({
    type: SideBar,
    payload: menu,
  });
};

export const setNQSuperSelected = (selected) => async (dispatch) => {
  dispatch({
    type: NQSuperSelected,
    payload: selected,
  });
};
