import React, { useEffect } from "react";
import "../Pot/Pot.scss";
import PotCard from "components/PotCard/PotCard";
import { connect } from "react-redux";
import { getPotList } from "store/action/potsAction";
import Loading from "components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { currencyFormat } from "utils/currencyFormat";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
const Pot = (props) => {
  const getAssestData = () => {

    let data = [];
    if (
      props.potsList &&
      props.potsList[0] &&
      props.potsList[0].factFindAssetPots
    ) {
      data =
        props.potsList &&
        props.potsList[0] &&
        props.potsList[0].factFindAssetPots &&
        props.potsList[0].factFindAssetPots.reduce((r, { type }) => {
          if (!r.some((o) => o.type === type)) {
            let amount = 0;
            let types = props.potsList[0].factFindAssetPots.filter(
              (v) => v.type === type
            );
            if (types) {
              types.forEach((element) => {
                amount += element.amount;
              });
            }
            r.push({
              type: type,
              amount: amount,
            });
          }
          return r;
        }, []);
    }
    return data;
  };

  const sumofAssesst = (data) => {
    let amount = 0;
    data.forEach((element) => {
      amount += element.amount;
    });
    return amount;
  };
  //Assesst

  //Liabilities
  const getLiabilitiesData = () => {

    let data = [];
    if (
      props.potsList &&
      props.potsList[0] &&
      props.potsList[0].factFindLiabilityPots
    ) {
      data =
        props.potsList &&
        props.potsList[0] &&
        props.potsList[0].factFindLiabilityPots &&
        props.potsList[0].factFindLiabilityPots.reduce((r, { type }) => {
          if (!r.some((o) => o.type === type)) {
            let amount = 0;
            let types = props.potsList[0].factFindLiabilityPots.filter(
              (v) => v.type === type
            );
            if (types) {
              types.forEach((element) => {
                amount += element.amount;
              });
            }
            r.push({
              type: type,
              amount: amount,
            });
          }
          return r;
        }, []);
    }
    return data;
  };

  const sumofLiabilities = (data) => {
    let amount = 0;
    data.forEach((element) => {
      amount += element.amount;
    });
    return amount;
  };
  // Liabilities
  //Income
  const getIncomeData = () => {

    let data = [];
    if (
      props.potsList &&
      props.potsList[0] &&
      props.potsList[0].factFindIncomePots
    ) {
      data =
        props.potsList &&
        props.potsList[0] &&
        props.potsList[0].factFindIncomePots &&
        props.potsList[0].factFindIncomePots.reduce((r, { type }) => {
          if (!r.some((o) => o.type === type)) {
            let amount = 0;
            let types = props.potsList[0].factFindIncomePots.filter(
              (v) => v.type === type
            );
            if (types) {
              types.forEach((element) => {
                amount += element.amount;
              });
            }
            r.push({
              type: type,
              amount: amount,
            });
          }
          return r;
        }, []);
    }
    return data;
  };

  const sumofIncome = (data) => {
    let amount = 0;
    data.forEach((element) => {
      amount += element.amount;
    });
    return amount;
  };
  //Income

  //Expenses
  const getExpenseData = () => {

    let data = [];
    if (
      props.potsList &&
      props.potsList[0] &&
      props.potsList[0].factFindExpensePots
    ) {
      data =
        props.potsList &&
        props.potsList[0] &&
        props.potsList[0].factFindExpensePots &&
        props.potsList[0].factFindExpensePots.reduce((r, { type }) => {
          if (!r.some((o) => o.type === type)) {
            let amount = 0;
            let types = props.potsList[0].factFindExpensePots.filter(
              (v) => v.type === type
            );
            if (types) {
              types.forEach((element) => {
                amount += element.amount;
              });
            }
            r.push({
              type: type,
              amount: amount,
            });
          }
          return r;
        }, []);
    }
    return data;
  };

  const sumofExpense = (data) => {
    let amount = 0;
    data.forEach((element) => {
      amount += element.amount;
    });
    return amount;
  };
  //Expenses
  const Assets = {
    Caption: "Assets",
    Total: getAssestData().length > 0 ? sumofAssesst(getAssestData()) : 0,
    pieData: {
      labels: getAssestData().map((o) => o.type),
      datasets: [
        {
          label: "Rainfall",
          backgroundColor: [
            "#701aeb",
            "#21094e",
            "#511281",
            "#4ca1a3",
            "#3d84b8",
            "rgba(255, 159, 64, 0.6)",
            "rgba(255, 99, 132, 0.6)",
          ],

          data: getAssestData().map((o) => o.amount),
        },
      ],
    },
    pieChartOptions: {
      title: {
        display: true,
        text: "Assets",
        fontSize: 20,
      },
      maintainAspectRatio: false,

      legend: {
        position: "right",
        display: false,
      },
    },
  };
  const Liabilities = {
    Caption: "Liabilities",
    Total:
      getLiabilitiesData().length > 0
        ? sumofLiabilities(getLiabilitiesData())
        : 0,
    pieData: {
      labels: getLiabilitiesData().map((o) => o.type),
      datasets: [
        {
          label: "Rainfall",
          backgroundColor: [
            "#701aeb",
            "#21094e",
            "#511281",
            "#4ca1a3",
            "#3d84b8",
            "rgba(255, 159, 64, 0.6)",
            "rgba(255, 99, 132, 0.6)",
          ],
          data: getLiabilitiesData().map((o) => o.amount),
        },
      ],
    },
    pieChartOptions: {
      title: {
        display: true,
        text: "Liabilities",
        fontSize: 20,
      },
      maintainAspectRatio: false,

      legend: {
        position: "bottom",
        display: false,
      },
    },
  };
  const Income = {
    Caption: "Income",
    Total: getIncomeData().length > 0 ? sumofIncome(getIncomeData()) : 0,
    pieData: {
      labels: getIncomeData().map((o) => o.type),
      datasets: [
        {
          label: "Rainfall",
          backgroundColor: [
            "#701aeb",
            "#21094e",
            "#511281",
            "#4ca1a3",
            "#3d84b8",
            "rgba(255, 159, 64, 0.6)",
            "rgba(255, 99, 132, 0.6)",
          ],
          data: getIncomeData().map((o) => o.amount),
        },
      ],
    },
    pieChartOptions: {
      title: {
        display: true,
        text: "Income",
        fontSize: 20,
      },
      maintainAspectRatio: false,

      legend: {
        position: "bottom",
        display: false,
      },
    },
  };
  const Expense = {
    Caption: "Expense",
    Total: getExpenseData().length > 0 ? sumofExpense(getExpenseData()) : 0,
    pieData: {
      labels: getExpenseData().map((o) => o.type),
      datasets: [
        {
          label: "Rainfall",
          backgroundColor: [
            "#701aeb",
            "#21094e",
            "#511281",
            "#4ca1a3",
            "#3d84b8",
            "rgba(255, 159, 64, 0.6)",
            "rgba(255, 99, 132, 0.6)",
          ],
          data: getExpenseData().map((o) => o.amount),
        },
      ],
    },
    pieChartOptions: {
      title: {
        display: true,
        text: "Expense",
        fontSize: 20,
      },
      maintainAspectRatio: false,

      legend: {
        position: "bottom",
        display: false,
      },
    },
  };

  useEffect(() => {
    let FactFindKey = localStorage.getItem("FactFindKey");
    props.getPotList(FactFindKey);
  }, []);

  const CarryValue = () => {
    return [
      {
        Id: 1,
        Caption: Assets.Caption,
        Value: currencyFormat(Assets.Total),
        Total: Assets.Total,
      },
      {
        Id: 2,
        Caption: Liabilities.Caption,
        Value: currencyFormat(Liabilities.Total),
        Total: Liabilities.Total,
      },
      {
        Id: 3,
        Caption: Income.Caption,
        Value: currencyFormat(Income.Total),
        Total: Income.Total,
      },
      {
        Id: 4,
        Caption: Expense.Caption,
        Value: currencyFormat(Expense.Total),
        Total: Expense.Total,
      },
    ];
  };
  if (props.loading) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="Pots">
          <div className="action-area">
            <div>
              <label className="title"> Pot </label>
            </div>
          </div>
          <GridContainer >
            <GridItem lg={6} xs={12} sm={12} md={12}>
              <PotCard
                config={Assets}
                caption={Assets.Caption}
                total={Assets.Total}
                Carry={CarryValue()}
                Data={getAssestData()}
              />
            </GridItem>
            <GridItem lg={6} xs={12} sm={12} md={12}>
              <PotCard
                config={Liabilities}
                caption={Liabilities.Caption}
                total={Liabilities.Total}
                Carry={CarryValue()}
                Data={getLiabilitiesData()}
              />
            </GridItem>
            <GridItem lg={6} xs={12} sm={12} md={12}>
              <PotCard
                config={Income}
                caption={Income.Caption}
                total={Income.Total}
                Carry={CarryValue()}
                Data={getIncomeData()}
              />
            </GridItem>
            <GridItem lg={6} xs={12} sm={12} md={12}>
              <PotCard
                config={Expense}
                caption={Expense.Caption}
                total={Expense.Total}
                Carry={CarryValue()}
                Data={getExpenseData()}
              />
            </GridItem>
          </GridContainer>
        </div>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    potsList: state.potsReducer.potsList,
    loading: state.potsReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPotList: (factFindKey) => dispatch(getPotList(factFindKey)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withAuthenticationRequired(Pot, {
    onRedirecting: () => <Loading />,
  })
);
