export const HomeAPICalls = {
  controller: "Portfolios",
  UserInfo: "",
  PortFolios: "ClientPortfoliosByContactEmail",
  UniversePrice: `LatestPerformance`,
  TransactionByPortfolio: "ClientPortfolioFinancialTransactionByContactEmail",
  Performance: "Performance",
};

export const AddGoalsAPIsCall = {
  controller: "FactFind",
  AddGoalHome: "GoalHome",
  AddGoalDebts: "GoalDebts",
  AddGoalRainyDays: "GoalRainyDays",
  AddGoalOther: "GoalOther",
   
};

export const AddInsuranceAPIsCall = {
  controller: "FactFind",
  action: "PersonalInsurances",
  AddTPD: "TPD",
  AddLifeAndTPD: "LifeTPD",
  AddLifeInsurance: "LifeInsurance",
  AddIncomeProtection: "IncomeProtection",
  AddTrauma: "Trauma",
};
export const AddDebtAPIsCall = {
  controller: "FactFind",
  action: "Liabilities",
  AddCarLease: "CarLease",
  AddCarLoan: "CarLoans",
  AddCreditCards: "CreditCards",
  AddHomeLoans: "HomeLoans",
  AddInvestmentLoans: "InvestmentLoans",
  AddOtherLiabilities: "OtherLiabilities",
  AddPersonalLoans: "PersonalLoans",
};

export const AddAssets = {
  controller: "FactFind",
  action: "Assets",
  AddbankAccounts: "BankAccounts",
  Addbusiness: "Business",
  Addcollectibles: "Collectibles",
  Addcontents: "Contents",
  AdddefinedBenefitScheme: "DefinedBenefitScheme",
  AddprivateRetirementScheme: "EPFPRS",
  Addhome: "Home",
  AddinvestmentProperty: "InvestmentProperty",
  AddlifestyleProperty: "LifeStyleProperty",
  AddmotorVehicles: "MotorVehicles",
  AddotherAsets: "OtherAssets",
  Addportfolio: "Portfolio",
  AddprivateEquity: "PrivateEquity",
  Addshares: "Shares",
  Addsuper: "Super",
  AddtermDeposits: "TermDeposits",
};
export const owner = {
  controller: "FactFind",
  action: "Owners",
};

export const Membership = {
  controller: "Membership",
  action: "CurrentMember",
};

export const PortfoliosAPI = {
  controller: "Portfolios",
  action: "ClientPortfolioPerformanceSummaryByContactEmail",
  getClientPortfolios:"GetClientPortfolioByContactEmail",
  checkClientType:"IsPortfolioHasSupernPensionClientType",
  getClientPortfolioByContactEmailAndServiceCode:"GetClientPortfolioByContactEmailAndServiceCode"
};

export const FileAPI = {
  controller: "Files",
  action: "FinancialTransactionDocument",
};

export const GetGoals = {
  controller: "FactFind",
  action: "Goals",
};

export const PotsAPI = {
  controller: "FactFind",
  action: "ClientPortfoliosByContactEmail",
  potlist: "",
};

export const InstrumentPerformanceAPI = {
  controller: "Portfolios",
  action: "InstrumentPerformance",
};


export const Advice = {
  controller: "",
  action: "",
}

export const ByMembership = {
  controller: "Contacts",
  action: "ByMembership",
};
export const Requests = {
  controller: "Requests",
  action: {
    getall: "",
    post: "",
    delete: "",
    put: ""
  }
};
export const ByContribution = {
  controller: "Contacts",
  action: "Contribution",
}

export const ByFundingOption = {
  controller: "Contacts",
  action: "FundingOption",
}

export const Adviser = {
  controller: "Advisers",
  action: "AdviserDetailsByClientKey",
}

export const WorkspaceConfig = {
  controller: "WorkspaceConfiguration",
  action: "WhiteLabelLogo",
}


