import { any } from "prop-types";
import {
  Request,addNewRequest
} from "../types";

const initialState = {
  getRequestsData: [],
  postRequestData : [],
  response: any,
  loading: false,
  isValid: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Request:
      return {
        ...state,
        getRequestsData: action.payload,
      };

    case addNewRequest:
      return {
        ...state,
        postRequestData: action.payload,
      };

    default:
      return state;
  }
}
