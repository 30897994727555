import { withAuthenticationRequired } from "@auth0/auth0-react";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from "@material-ui/icons/Delete";
import Save from "@material-ui/icons/Save";
import NQDropDown from "components/DropDown/NQDropDown";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Homecard from "components/Homecard/Homecard";
import Loading from "components/Loading";
import NQTextField from "components/TextField/NqTextField";
import { EnumTypes, PotTypes } from "Enums/PotTypes";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import { AddNewPot, ChangePot, DeletePotById, getPotByType } from "store/action/potsAction";
import { getEnumbyType, getIncomeExpenseFrequency } from "store/action/readOnlyAction";
import { showError, showSuccess } from "utils/NQToaster";
import PotChart from "../Chart/PotChart";
import { currencyFormat } from './../../../utils/currencyFormat';
import "./Potdetaillist.scss";

const Potdetaillist = (props) => {
  const history = useHistory();

  const [DetailData, setDetailData] = useState([])
  const { ...prop } = useParams();
  const [gridData, setGridData] = useState([]);
  const [EnumTypesList, setEnumTypesList] = useState([])
  const [IncomeExpenseFrequencyList, setIncomeExpenseFrequency] = useState([])
  const [Frequency, setFrequency] = useState(false)
  const [loader, setloader] = useState(false)
  const [selectedBoxValue, setselectedBoxValue] = useState();

  useEffect(() => {
    if (!props.location.CarryValue) {
      history.push("/admin/Pot");
    } else {
      let selected = props.location.CarryValue?.filter((o) => o.Caption === props.location.selected)[0];
      if (selected) {
        let obj = selected.Caption === "Assets" ? EnumTypes.AssetTypes : selected.Caption === "Liabilities" ? EnumTypes.LiabilityTypes : selected.Caption === "Expense"
          ? EnumTypes.FactFindExpenseCategory : selected.Caption === "Income" ? EnumTypes.FactFindIncomeCategory : EnumTypes.FactFindIncomeCategory;
        setselectedBoxValue(selected);
        if (obj === EnumTypes.FactFindExpenseCategory || obj === EnumTypes.FactFindIncomeCategory) {
          setFrequency(true);
        }
        else {
          setFrequency(false);
        }

        getApi(obj, selected.Caption);
      }
    }
  }, []);

  const selectBox = (e) => {
    setselectedBoxValue(e);
    let obj = e.Caption === "Assets" ? EnumTypes.AssetTypes : e.Caption === "Liabilities" ? EnumTypes.LiabilityTypes : e.Caption === "Expense"
      ? EnumTypes.FactFindExpenseCategory : e.Caption === "Income" ? EnumTypes.FactFindIncomeCategory : EnumTypes.FactFindIncomeCategory;

    if (obj === EnumTypes.FactFindExpenseCategory || obj === EnumTypes.FactFindIncomeCategory) {
      setFrequency(true);
    }
    else {
      setFrequency(false);
    }

    getApi(obj, e.Caption);
  };

  const getApi = (obj, caption) => {
    props.getEnumTypes(obj).then(o => {
      setEnumTypesList(prev => ({ ...prev, Data: o.data.map(o => ({ ...o, label: o.text, value: o.id })) }))
    }).catch(erro => { });

    props.getIncomeExpenseFrequency().then(o => {
      setIncomeExpenseFrequency(prev => ({ ...prev, Data: o.data.map(o => ({ ...o, label: o.text, value: o.id })) }))
    }).catch(err => { })

    PotbyType(caption)
  }

  const PotbyType = (caption) => {
    setloader(true);
    caption = caption === "Asset" ? "Assets" : caption === "Liability" ? "Liabilities" : caption;
    props.getPotDetailByType(caption).then(o => {

      setloader(false);
      if (props.location.CarryValue.filter(o => o.Caption.includes(caption))[0]) {
        props.location.CarryValue.filter(o => o.Caption.includes(caption))[0].Total = sumOfType(o.data.data);
        props.location.CarryValue.filter(o => o.Caption.includes(caption))[0].Value = sumOfType(o.data.data) > 0 ? currencyFormat(sumOfType(o.data.data)) : "$0.00";
      }

      let data = o.data.data.sort((a, b) => a.name.localeCompare(b.name));

      setDetailData(prev => ({ ...prev, Data: data }))
      setGridData(prev => ({ ...prev, Data: data }))
    }).catch(error => {
    })
  }

  const addPot = () => {
    let newArray = gridData.Data && gridData.Data.length > 0 ? [...gridData.Data] : [];
    newArray.unshift({ categoryType: '', categoryTypeId: '', name: "", currentValue: "0", isNew: true, frequencyType: '', frequencyTypeId: '', frequencyValue: "0" })
    setGridData(prev => ({ ...prev, Data: newArray }))
  };


  const onTypeChange = (event, index, item) => {

    let params = gridData.Data.filter((_, i) => i === index)[0];
    if (params) {
      params.categoryType = event && event.value ? event.value : '';
      params.categoryTypeId = event && event.value ? event.value : 0;
      setGridData(prev => ({ ...prev, Data: gridData.Data }))
    }
    if (!item.isNew)
      onCellValueChanged(params);
  }

  const onFreQuencyTypeChange = (event, index, item) => {

    let params = gridData.Data.filter((_, i) => i === index)[0];
    if (params) {
      params.frequencyType = event && event.value
      params.frequencyTypeId = event && event.value
      if (params.frequencyValue) {
        params.currentValue = calculateFrequency(params.frequencyType, Number(params.frequencyValue));
      }

      setGridData(prev => ({ ...prev, Data: gridData.Data }))
    }
    if (!item.isNew)
      onCellValueChanged(params);
  }

  const calculateFrequency = (type, value) => {

    switch (type) {
      case 0:
        return value * 1
      case 1:
        return value * 2
      case 2:
        return value * 4
      case 3:
        return value * 12
      case 4:
        return value * 52
      case 5:
        return value * 26
      case 6:
        return value * 1
    }
  }
  const onfrequencyValueChange = (event, index, item) => {

    if (Number(event.target.value)) {
      let frtype = gridData.Data.filter((_, i) => i === index)[0].frequencyType
      gridData.Data.filter((_, i) => i === index)[0].currentValue = calculateFrequency(frtype, Number(event.target.value))
    }

    let params = gridData.Data.filter((_, i) => i === index)[0];
    gridData.Data.filter((_, i) => i === index)[0].frequencyValue = Number(event.target.value)

    setGridData(prev => ({ ...prev, Data: gridData.Data }))
    if (!item.isNew)
      onCellValueChanged(params);
  }

  const onNameChange = (event, index, item) => {
    gridData.Data.filter((_, i) => i === index)[0].name = event.target.value;
    let params = gridData.Data.filter((_, i) => i === index)[0];
    setGridData(prev => ({ ...prev, Data: gridData.Data }))

    if (!item.isNew)
      onCellValueChanged(params);
  }

  const onValueChange = (event, index, item) => {
    gridData.Data.filter((_, i) => i === index)[0].currentValue = Number(event.target.value);
    let params = gridData.Data.filter((_, i) => i === index)[0];
    setGridData(prev => ({ ...prev, Data: gridData.Data }))
    if (!item.isNew)
      onCellValueChanged(params);
  }

  const sumOfType = (data) => {
    let value = 0;
    if (data && data.length > 0) {
      data.forEach(element => {
        value += element.currentValue;
      });
    }
    return value;
  }

  const onCellValueChanged = (params) => {
    if (params.categoryTypeId == null || params.categoryTypeId === undefined) {
      showError("Please select Type");
      return;
    }
    if (!params.name) {
      showError("Please Enter your Name");
      return;
    }

    if (Frequency) {
      if (params.frequencyTypeId == null || params.frequencyTypeId === undefined) {
        showError("Please select Frequency type");
        return;
      }

      if (!params.frequencyValue || Number(params.frequencyValue) <= 0) {
        showError("Please Enter Frequency value");
        return;
      }
    }

    if (Frequency) {
      params.currentValue = calculateFrequency(params.frequencyTypeId, params.frequencyValue);
    }

    params.categoryType = params.categoryTypeId
    params.frequencyType = params.frequencyTypeId


  }

  const ClearRow = (indx) => {
    gridData.Data.filter((_, i) => i === indx)[0].categoryType = '';
    gridData.Data.filter((_, i) => i === indx)[0].name = '';
    gridData.Data.filter((_, i) => i === indx)[0].currentValue = 0;
    gridData.Data.filter((_, i) => i === indx)[0].frequencyType = '';
    gridData.Data.filter((_, i) => i === indx)[0].frequencyValue = 0;
    gridData.Data.splice(indx, 1);
    setGridData(prev => ({ ...prev, Data: [...gridData.Data] }))
  }

  const cellAddButtonClick = (e) => {
    setloader(true);
    let obj = selectedBoxValue.Caption === "Assets" ? PotTypes.Asset : selectedBoxValue.Caption === "Liabilities" ? PotTypes.Liability
      : selectedBoxValue.Caption === "Expense" ? PotTypes.Expense : selectedBoxValue.Caption === "Income" ? PotTypes.Income : PotTypes.Income;


    let AddCategory = EnumTypesList?.Data?.filter((o) => o.text.includes(e.categoryType))[0];
    e.PotType = obj;
    e.categoryType = AddCategory ? AddCategory.value : e.categoryType;
    e.label = AddCategory ? AddCategory.text : e.categoryType;
    e.contactKey = localStorage.getItem("contactKey");

    props.AddNewPot(e).then(o => {
      PotbyType(selectedBoxValue.Caption);
      showSuccess('Record successfully saved...');
      setloader(false);
    }).catch(error => {
      showError(error);
      setloader(false);
    });
  };

  const cellEditButtonClick = async (e) => {
    let obj = selectedBoxValue.Caption === "Assets" ? PotTypes.Asset : selectedBoxValue.Caption === "Liabilities" ? PotTypes.Liability : selectedBoxValue.Caption === "Expense"
      ? PotTypes.Expense : selectedBoxValue.Caption === "Income" ? PotTypes.Income : PotTypes.Income;

    let objCategory = EnumTypesList?.Data.filter((o) => o.text.includes(e.categoryType))[0];
    e.PotType = obj;
    e.label = objCategory ? objCategory.text : e.categoryType;
    e.categoryType = objCategory ? objCategory.value : e.categoryType;
    e.contactKey = localStorage.getItem("contactKey");

    props.ChangePot(e).then(o => {
      PotbyType(selectedBoxValue.Caption);
      showSuccess('Record successfully saved...')
    }).catch(error => {
      showError(error);
    });
  };

  const saveRow = (params) => {
    if (!params.key) {
      cellAddButtonClick(params);
    } else if (params.key) {
      cellEditButtonClick(params);
    }
  }

  const cellDeleteButtonClick = (e) => {

    let obj = selectedBoxValue.Caption === "Assets" ? PotTypes.Asset : selectedBoxValue.Caption === "Liabilities" ? PotTypes.Liability
      : selectedBoxValue.Caption === "Expense" ? PotTypes.Expense : selectedBoxValue.Caption === "Income" ? PotTypes.Income : PotTypes.Income;

    setloader(true);
    props.DeletePotById(e.key, obj).then(o => {
      PotbyType(obj)
    }).catch(erro => {
      PotbyType(obj)
    });

  };

  if (loader) {
    return <Loading />
  } else {
    return (
      <div>
        <div className="Potdetail">
          <div className="action-area">
            <div>
              <label className="title">Pot Detail(s) </label>
            </div>
            <div className="Homecard">
              <div className="scrollbar" id="style-1">
                <div className="force-overflow">
                  {props.location.CarryValue?.map((item, index) => {
                    return (
                      <Homecard
                        key={index}
                        {...item}
                        selectedBoxValu={selectedBoxValue}
                        onClick={() => selectBox(item)}
                        isClick={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Potdetaillist">
          <div className="css-ea35n1">
            <div className="css-1kmimlc">
              <div className="css-4lc1dm">
                <div className="css-1ialerq">
                  <GridContainer>
                    <GridItem lg={6} md={6} xs={6} sm={6}>
                      <h3> {selectedBoxValue ? selectedBoxValue.Caption : prop.type}</h3>
                    </GridItem>
                    <GridItem lg={6} md={6} xs={6} sm={6}>
                      <div className="action addpot">
                        <button onClick={addPot} type="button" className="NQ-btn NQ-btn-blue"> Add Pot </button>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
              <GridContainer>
                <GridItem lg={12} md={12} xs={12} sm={12}>
                  <div className="ag-theme-alpine" style={{ minHeight: '500px' }}>
                    <div id="no-more-tables">
                      <table id="myTable" className=" table-bordered table-striped table-condensed cf w-100" >
                        <thead className="cf">
                          <tr className="header">
                            <th></th>
                            <th>Type</th>
                            <th className="numeric">Name</th>
                            {Frequency ? <th style={{ width: '300px' }}>Type</th> : null}
                            {Frequency ? <th style={{ width: '300px' }} className="numeric">Amount</th> : null}
                            <th className="numeric">Annual Amount</th>
                          </tr>
                        </thead>
                        <tbody>

                          {gridData && gridData.Data && gridData.Data.length > 0 && gridData.Data.map((item, index) => {
                            return (
                              <tr key={index} className="margin-l-r-10">
                                <td data-title="" className="mobile-text action-Icon ">
                                  {item.isNew ?
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                      <div className="clear">
                                        <CheckIcon onClick={() => cellAddButtonClick(item)} />
                                      </div>

                                      <div className="clear">
                                        <ClearIcon onClick={() => ClearRow(index)} />
                                      </div>
                                    </div> :
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                      <div className="delete">
                                        <DeleteIcon titleAccess="Delete" onClick={() => cellDeleteButtonClick(item)} />
                                      </div>
                                      <div className="delete">
                                        <Save titleAccess="Save" onClick={() => saveRow(item)} />
                                      </div>
                                    </div>
                                  }
                                </td>
                                <td data-title="Type" className="mobile-text">
                                  <NQDropDown variant="outlined" caption="Type" label="text" required={true} name="text" onChange={(e) => onTypeChange(e, index, item)}
                                    data={EnumTypesList?.Data} value={item.categoryTypeId} />
                                </td>
                                <td
                                  data-title="Name" className="mobile-text">
                                  <NQTextField id="name" onChange={(e) => onNameChange(e, index, item)} value={item.name} />
                                </td>

                                {Frequency ? <td data-title="FrequencyType" className="mobile-text">
                                  <NQDropDown isClearable="false" caption="Type" variant="outlined" label="text" required={true} name="text"
                                    onChange={(e) => onFreQuencyTypeChange(e, index, item)} data={IncomeExpenseFrequencyList?.Data} value={item.frequencyTypeId}
                                  />
                                </td> : null}
                                {Frequency ? <td data-title="FrequencyValue" className="mobile-text text-right">
                                  <NQTextField className="numberValue" id="frequencyValue" type="number" onChange={(e) => onfrequencyValueChange(e, index, item)} value={item.frequencyValue} />
                                </td> : null}

                                <td data-title="Value" className="mobile-text text-right ">
                                  <NQTextField className="numberValue" id="currentValue" type="number" disabled={Frequency} onChange={(e) => onValueChange(e, index, item)} value={item.currentValue} />
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </GridItem>
                {/* <GridItem lg={5} md={12} xs={12} sm={12}>
                  <div className="NQ-Chart">
                    <div style={{ alignSelf: "center" }}>
                      {DetailData?.Data && DetailData?.Data?.length > 0 ? <PotChart
                        Data={DetailData?.Data}
                        showLegend={false}
                        LegendPosition={"left"}
                        height={500}
                        width={500}
                      />
                        : null}
                    </div>
                  </div>
                </GridItem> */}
              </GridContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    Total: state.potsReducer.Total,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPotDetailByType: (potType) => dispatch(getPotByType(potType)),
    AddNewPot: (newpot) => dispatch(AddNewPot(newpot)),
    ChangePot: (changePot) => dispatch(ChangePot(changePot)),
    getEnumTypes: (enumType) => dispatch(getEnumbyType(enumType)),
    getIncomeExpenseFrequency: () => dispatch(getIncomeExpenseFrequency()),
    DeletePotById: (potKey, PotTypeKey) => dispatch(DeletePotById(potKey, PotTypeKey)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuthenticationRequired(Potdetaillist,
  {
    onRedirecting: () => <Loading />
  })
);
