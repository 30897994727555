import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NQDateInput from "components/Date/NQDateInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import * as moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { EditGoal, getById, PostAddOtherGoal } from "store/action/goalsAction";
import { currencyFormat } from "utils/currencyFormat";
import { getMonthDifference } from "utils/date-time-helper";
import { NQDateInputMonthFormatter, textDateFormatter } from "utils/formatter";
import { isValidNumber, RoundUpToInteger } from "utils/helper";
import { showError } from "utils/NQToaster";
import babies from "../../../assets/goals/babies.svg";
import billandTax from "../../../assets/goals/billandTax.svg";
import buyCar from "../../../assets/goals/buyCar.svg";
import education from "../../../assets/goals/education.svg";
import electronics from "../../../assets/goals/electronics.svg";
import furniture from "../../../assets/goals/furniture.svg";
import improveHome from "../../../assets/goals/improveHome.svg";
import othere from "../../../assets/goals/othere.svg";
import shopping from "../../../assets/goals/shopping.svg";
import travel from "../../../assets/goals/travel.svg";
import weeding from "../../../assets/goals/weeding.svg";
import other from "../../../assets/icon/other.svg";
import NQInputText from "../../../components/TextField/NQ-input-text";
import { goalOthers } from "../../../constant/GoalType";
import "../AddGoal/AddSomethingElse.scss";
import "./AddHome.scss";
const AddSomethingElse = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setgoalOthersObj({
      ...goalOthersObj,
    });
    // goalOthersObj
    if (props.location.factFindGoalOtherKey) {
      dispatch(getById(props.location.factFindGoalOtherKey, 'GoalOther'));
    } else {
      goalOthersObj.name = ""
      goalOthersObj.status = 0
      goalOthersObj.goalAmount = 0
      goalOthersObj.plannedDate = null
      goalOthersObj.monthlyContribution = 0
      goalOthersObj.isComplete = false
      goalOthersObj.otherGoalAmount = 0
      goalOthersObj.priorSavingAmount = 0
      goalOthersObj.factFindGoalOtherCategory = ""

      let obj = categoryArray.filter(
        (o) => o.value === goalOthersObj.factFindGoalOtherCategory
      )[0];
      setCategory(goalOthersObj.factFindGoalOtherCategory);

      goalOthersObj.factFindGoalOtherCategory = obj?.field;
      setgoalOthersObj({
        ...goalOthersObj,
      });
    }
    //return () => { };
    step1OtherCalculation();
  }, []);

  React.useEffect(() => {
    mapResponse();
  }, [props]);
  const mapResponse = useCallback(() => {
    if (props.getbyIdData && props.getbyIdData[0]) {
      Object.keys(props.getbyIdData[0]).forEach((key) => {
        goalOthersObj[key] = props.getbyIdData[0][key];
      });

      let obj = categoryArray.filter(
        (o) => o.value === goalOthersObj.factFindGoalOtherCategory
      )[0];
      setCategory(goalOthersObj.factFindGoalOtherCategory);

      goalOthersObj.factFindGoalOtherCategory = obj?.field;
      goalOthersObj.otherGoalAmount =
        goalOthersObj.goalAmount + goalOthersObj.priorSavingAmount;

      setgoalOthersObj((prevState) => {
        return {
          ...prevState,
          goalOthersObj,
          //
        };
      });

      // setgoalOthersObj(goalOthersObj)
    }
  }, [props.getbyIdData]);

  const useStyles = makeStyles((theme) => ({
    root: { width: "100%" },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

  const span = (text) => {
    return `<span style="font-size: 0.66rem;display: flex;/* flex: 1; *//* width: auto; */text-align: center;position: relative;top: -7px;font-weight: 500;letter-spacing: 0.02rem !important;">${text} </span>`;
  };

  const categoryArray = [
    {
      image: babies,
      value: "Babies and Kids",
      span: span("Babies & Kids"),
      field: "BabiesAndKids",
    },
    {
      image: billandTax,
      value: "Bill and Taxes",
      span: span("Bill & Taxes"),
      field: "BillAndTaxes",
    },
    {
      image: buyCar,
      value: "Buy a car",
      span: span("Buy a car"),
      field: "BuyAcar",
    },
    {
      image: electronics,
      value: "Electronics",
      span: span("Electronics"),
      field: "BabiesAndKids",
    },
    {
      image: furniture,
      value: "Furniture",
      span: span("Furniture"),
      field: "Furniture",
    },
    {
      image: shopping,
      value: "Gifts and Shopping",
      span: span("Gifts & Shopping"),
      field: "GiftsAndShopping",
    },
    {
      image: improveHome,
      value: "Improve my home",
      span: span("Improve my home"),
      field: "ImproveMyHome",
    },
    {
      image: othere,
      value: "Other",
      span: span("Other"),
      field: "Other",
    },
    {
      image: education,
      value: "Save for Education",
      span: span("Save for Education"),
      field: "SaveforEducation",
    },
    {
      image: travel,
      value: "Take a trip",
      span: span("Take a trip"),
      field: "TakeATrip",
    },
    {
      image: weeding,
      value: "Wedding",
      span: span("Wedding"),
      field: "Wedding",
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [goalOthersObj, setgoalOthersObj] = useState(goalOthers);
  const history = useHistory();
  const [category, setCategory] = useState();
  const handleNext = (e) => {
    e.preventDefault();
    if (isValidate()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      step1OtherCalculation();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const navigateTo = () => {
    if (history.location && history.location.key) {
      history.push("/admin/Goal");
    } else {
      history.push("/admin/addgoalbytype");
    }
  };

  const classes = useStyles();
  const handleChange = (e, x) => {

    e.preventDefault();

    setgoalOthersObj({
      ...goalOthersObj,
      [typeof e.target !== "undefined"
        ? [e.target.name]
        : "plannedDate"]: e.target ? e.target.value : x,
    });

    goalOthersObj[e.target.name] = e.target.value ? e.target.value : e.target;

    if (e.target.name !== "name")
      step1OtherCalculation();
  };

  useEffect(() => {
    setgoalOthersObj((prevState) => {
      return {
        ...prevState,
        goalOthersObj,
        //
      };
    });
    step1OtherCalculation();
  },[goalOthersObj.otherGoalAmount,goalOthersObj.priorSavingAmount])

  const changePlannedDate = (event) => {
    var monthControl = document.querySelector('input[type="month"]');
    setgoalOthersObj((state) => {
      return {
        ...state,
        plannedDate: NQDateInputMonthFormatter(monthControl.value),
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidate()) {
      let factFindOwner = [];
      if (props.location.factFindGoalOtherKey) {
        // goalHomeObj.goalType = "Home";
        factFindOwner.push({
          ownerKey: localStorage.getItem("contactKey"),
          percentage: 100,
        });
        goalOthersObj.factFindOwner = factFindOwner;
        goalOthersObj.goalType = "Other";
        goalOthersObj.plannedDate = new Date(goalOthersObj.plannedDate);
        goalOthersObj.ContactKey = localStorage.getItem("contactKey");
        goalOthersObj.NucleusKey = localStorage.getItem("contactKey");
        await dispatch(
          EditGoal(
            props.location.key,
            props.location.factFindGoalOtherKey,
            "GoalOther",
            goalOthersObj,
            history
          )
        );
      } else {
        goalOthersObj.plannedDate = moment(goalOthersObj.plannedDate).format(
          "YYYY-MMM-DD"
        );
        goalOthersObj.ContactKey = localStorage.getItem("contactKey");
        goalOthersObj.NucleusKey = localStorage.getItem("contactKey");
        props.AddotherGoal(goalOthersObj, history);
      }
    }
  };

  function getSteps() {
    return ["Detail", "Summary"];
  }

  const OtherCategory = (item) => {
    setCategory(item.value);
    goalOthers.factFindGoalOtherCategory = item.value;
    goalOthersObj.factFindGoalOtherCategory = item.field;
  };

  const step1OtherCalculation = () => {
    const priorSavingAmount = isValidNumber(goalOthersObj.priorSavingAmount)
      ? Number(goalOthersObj.priorSavingAmount)
      : 0;
    const otherGoalAmount = goalOthersObj.otherGoalAmount;
    goalOthersObj.goalAmount = otherGoalAmount - priorSavingAmount;
    let date = new Date();
    goalOthersObj.plannedDate = date;
    goalOthersObj.plannedDate = new Date(
      new Date(date).setMonth(date.getMonth() + 12)
    );
    goalOthersObj.monthlyContribution = recalcMonthlyContribution();
  };

  const recalcMonthlyContribution = () => {

    let monthlyContirbution = 0;
    const goalAmount = Number(goalOthersObj.goalAmount);
    const plannedDate = goalOthersObj.plannedDate;
    const monthDifference = getMonthDifference(
      new Date(),
      new Date(plannedDate)
    );
    monthlyContirbution = RoundUpToInteger(goalAmount / monthDifference);

    return monthlyContirbution === Infinity ? 0 : monthlyContirbution;
  };

  const isValidate = () => {
    let isValid = true;
    if (activeStep === 0) {
      if(!category){
        isValid = false
        showError("Please select a category")
      }
      if (!goalOthersObj.otherGoalAmount) {
        isValid = false;
        showError("Goal Ammount can't be empty");
      }
      if (!goalOthersObj.priorSavingAmount) {
        isValid = false;
        showError("Saving Amount can't be empty");
      }
    }
    if (activeStep === 1) {
      if (!goalOthersObj.name) {
        isValid = false;
        showError("Goal Name can't be empty");
      }
      if (!goalOthersObj.goalAmount) {
        isValid = false;
        showError("Goal Amount can't be empty");
      }
      if (!goalOthersObj.plannedDate) {
        isValid = false;
        showError("Plan Date can't be empty");
      }
      if (!goalOthersObj.monthlyContribution) {
        isValid = false;
        showError("Monthly Contribution can't be empty");
      }
    }

    return isValid;
  };
  return (
    <div className="addgoalhome somethingelse">
      <React.Fragment>
        {activeStep === steps.length ? (
          <div className="mainDiv">
            <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div className={classes.instructions}>
            {activeStep === 0 ? (
              <div className="firststep">
                <GridContainer>
                  <GridItem lg={12} sm={12} xs={12} md={12}>
                    <div className="css-q4good">
                      <label
                        className="cat-title"
                        style={{
                          borderBottom: "1px solid #ddd",
                          paddingBottom: "10px",
                          fontWeight: "700",
                          color: "#191919",
                        }}
                      >
                        <img
                          height="60"
                          src={other}
                          width="50"
                          alt={"Something else"}
                        />
                        <div style={{ alignSelf: "center" }}>
                          Something else
                        </div>
                      </label>
                      <h1 className="css-1tu684v">Tell us about your goal</h1>
                      <label className="cat-title">Category</label>
                      <div className="category">
                        {categoryArray.map((item, index) => (
                          <div
                            key={index}
                            className="NQ-web"
                            onClick={() => OtherCategory(item)}
                          >
                            <div className="NQ-card goal-card">
                              <div
                                 className={`${item.value === category && "cartory-border "
                                   } NQ-card-body`}
                              >
                                <div className="NQ-left">
                                  <div className="NQ-col-1">
                                    <img
                                      height="40"
                                      src={item.image}
                                      width="80"
                                      alt={item.value}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="cat-text"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: item.span,
                              }}
                            ></div>
                          </div>
                        ))}
                      </div>

                      <form>
                        <div className="css-1yjvs5a">
                          <div
                            className="sc-oefIU cAmEDQ"
                            style={{ padding: "10px 15px !important" }}
                          >
                            <div className="sc-hhIhEF fsTaMx">
                              <div className="sc-oefIU ecNPTU">
                                <div className="sc-hhIhEF enPTrg">
                                  <GridItem lg={12} sm={12} xs={12} md={12}>
                                    <NQInputText
                                      caption="Enter the amount you want to save"
                                      id="otherGoalAmount"
                                      value={goalOthersObj.otherGoalAmount}
                                      variant="outlined"
                                      label="Enter the amount you want to save"
                                      type="number"
                                      required
                                      name="otherGoalAmount"
                                      onChange={handleChange}
                                    />
                                  </GridItem>
                                  <GridItem lg={12} sm={12} xs={12} md={12}>
                                    <NQInputText
                                      caption="current saving for your goal"
                                      id="priorSavingAmount"
                                      value={goalOthersObj.priorSavingAmount}
                                      variant="outlined"
                                      label="current saving for your goal"
                                      required
                                      type="number"
                                      name="priorSavingAmount"
                                      onChange={handleChange}
                                    />
                                  </GridItem>
                                </div>
                              </div>
                            </div>
                            <div
                              className="sc-oefIU cAmEDQ"
                              style={{ padding: "10px 15px !important" }}
                            >
                              <div className="sc-hhIhEF fsTaMx">
                                <div className="css-1ucoqeb">
                                  <div className="sc-oefIU ecNPTU">
                                    <div className="sc-hhIhEF enPTrg">
                                      <h6
                                        style={{
                                          color: "black",
                                          fontWeight: "500",
                                        }}
                                      >
                                        How much do you need?
                                      </h6>
                                      <p className="css-xi606m">
                                        Based on your goal and current saving,
                                        we recommend you set a goal of
                                        <strong
                                          style={{
                                            fontWeight: "700",
                                            marginLeft: 5,
                                            marginRight: 5,
                                          }}
                                        >
                                          {
                                            currencyFormat(
                                              goalOthersObj.goalAmount > 0
                                                ? goalOthersObj.goalAmount
                                                : 0
                                            )
                                          }
                                        </strong>
                                        to save the money to achieve the goal.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="css-dvxtzn">
                          <button
                            onClick={handleNext}
                            type="submit"
                            className="css-jhd49u"
                          >
                            Next
                          </button>
                          <button
                            onClick={() => navigateTo()}
                            type="button"
                            className="css-1gld5x9"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            ) : (
              <div className="nextstep">
                <GridContainer>
                  <GridItem lg={12} sm={12} xs={12} md={12}>
                    <div className="css-q4good">
                      <h1 className="css-1tu684v">Your Goal/Plan</h1>
                      <div className="css-1yjvs5a next">
                        <div className="sc-oefIU cAmEDQ">
                          <div className="sc-hhIhEF fsTaMx">
                            <div className="sc-oefIU ecNPTU">
                              <div className="sc-hhIhEF enPTrg">
                                <GridItem lg={12} sm={12} xs={12} md={12}>
                                  <NQInputText
                                    caption="Your Goal Name"
                                    id="name"
                                    value={goalOthersObj.name}
                                    variant="outlined"
                                    label="Your Goal Name"
                                    required
                                    name="name"
                                    onChange={handleChange}
                                    size="medium"
                                  />
                                </GridItem>
                                <GridItem lg={12} sm={12} xs={12} md={12}>
                                  <NQInputText
                                    caption="Your Goal Amount"
                                    id="goalAmount"
                                    value={goalOthersObj.goalAmount}
                                    variant="outlined"
                                    label="Your Goal Amount"
                                    required
                                    type="number"
                                    name="goalAmount"
                                    onChange={handleChange}
                                  />
                                </GridItem>
                                <GridItem lg={12} sm={12} xs={12} md={12}>
                                  <NQDateInput
                                    caption="Planned Date"
                                    id="plannedDate"
                                    value={goalOthersObj.plannedDate}
                                    variant="outlined"
                                    label="Planned Date"
                                    required
                                    name="plannedDate"
                                    // onChange={handleChange}
                                    onChange={(event) =>
                                      changePlannedDate(event)
                                    }
                                  />
                                </GridItem>
                                <GridItem lg={12} sm={12} xs={12} md={12}>
                                  <NQInputText
                                    caption="Your Monthly Contribution"
                                    id="monthlyContribution"
                                    value={goalOthersObj.monthlyContribution}
                                    variant="outlined"
                                    label="Your Monthly Contribution"
                                    required
                                    name="monthlyContribution"
                                    type="number"
                                    onChange={handleChange}
                                  />
                                </GridItem>
                              </div>
                            </div>
                          </div>
                          <div
                            className="sc-oefIU cAmEDQ"
                            style={{ padding: "10px 15px !important" }}
                          >
                            <div className="sc-hhIhEF fsTaMx">
                              <div className="css-1ucoqeb">
                                <div className="sc-oefIU ecNPTU">
                                  <div className="sc-hhIhEF enPTrg">
                                    <h6
                                      style={{
                                        color: "black",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Predicted Date:
                                    </h6>
                                    <p className="css-xi606m">
                                      Nice! By saving
                                      <strong
                                        style={{
                                          fontWeight: "700",
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        {currencyFormat(
                                          goalOthersObj.monthlyContribution > 0
                                            ? goalOthersObj.monthlyContribution
                                            : 0
                                        )}
                                      </strong>
                                      per month, we project that you will reach
                                      your goal by your planned date of
                                      <strong
                                        style={{
                                          fontWeight: "700",
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        {textDateFormatter(
                                          goalOthersObj.plannedDate
                                        )}
                                      </strong>
                                      .<br></br>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="css-dvxtzn">
                        <button
                          onClick={handleSubmit}
                          type="submit"
                          className="css-jhd49u"
                        >
                          Save
                        </button>
                        <button
                          disabled={activeStep === 0}
                          type="button"
                          onClick={handleBack}
                          className="css-1gld5x9"
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getbyIdData: state.goalsReducer.getbyIdData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddotherGoal: (otherObject, history) =>
      dispatch(PostAddOtherGoal(otherObject, history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSomethingElse);
