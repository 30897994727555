import { NIL as NIL_UUID } from 'uuid';

export const RequestType = {
  adviserFirmKey: null,
  adviserFirmName: null,
  adviserKey: "",
  adviserName: "",
  amount: "",
  portfolioKey: "",
  clientName: "",
  clientKey: NIL_UUID,
  comment: "",
  name: "",
  requestType: "",
  status: "",
  typeOfRequiredChanges: [],
  documentKey: "",
  contactKey: ""
};