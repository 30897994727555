import React, { useEffect } from "react";
import "./Homecard.scss";

const Homecard = (props) => {
  useEffect(() => {
  }, []);

  const selectbox = (e) => {
    if (props.isClick)
      props.onClick(e);
  };

  return (
    <div className="vueNow-calculation" onClick={() => selectbox(props)}>
      <div
        className="vueNow-calculation-box"
        style={{
          background:
            props?.Id === props?.selectedBoxValu?.Id
              ? "#600ce8"
              : props.backgroundColor,
          borderColor:
            props?.Id === props?.selectedBoxValu?.Id
              ? "#600ce8"
              : props.bordercolor,
        }}
      >
        <label
          className="w-100 lable"
          style={{
            color:
              props?.Id === props?.selectedBoxValu?.Id ? "#fff" : props.color,
          }}
        >
          {props.Caption}
        </label>
        <span
          className="w-100 count"
          style={{
            color:
              props?.Id === props?.selectedBoxValu?.Id ? "#fff" : props.color,
          }}
        >
          {props.Value}
        </span>
      </div>
    </div>
  );
};

export default Homecard;
