import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./Advice.scss";
import Loading from "components/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { getAdvice } from "store/action/adviceAction";
import TableAndChartLoading from "../../components/TableAndChartLoading/TableAndChartLoading";
import { advicestatusType } from "constant/adviceType";
import { FormatDateToText } from "../../utils/formatter";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { saveAs } from "file-saver";
import { b64toBlob } from "utils/helper";
import { showError } from "utils/NQToaster";

const Advice = (props) => {
  const [innerWidthClass, setinnerWidthClass] = useState(window.innerWidth);
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const resizeFunction = () => {
      setinnerWidthClass(window.innerWidth);
    };

    getAccessTokenSilently().then((x) => {
      axios.defaults.headers.common["Authorization"] = `bearer ${x}`;
    });

    props.adviceList(props.nqSuperSelected);
    window.addEventListener("resize", resizeFunction);
    resizeFunction();
    return () => window.removeEventListener("resize", resizeFunction);
  }, []);

  const downloadAdvice = (item) => {
    let env = JSON.parse(localStorage.getItem("env"));
    let workspaceKey = localStorage.getItem("workspaceKey");

    let url = `${env.apiUrl}Proposal/${item.proposalKey}/${workspaceKey}/PreviewProposalDocument`;
    axios
      .post(url)
      .then((o) => {
        saveAs(b64toBlob(o.data.data, o.data.contentType), o.data.fileName);
      })
      .catch((err) => {
        showError(`File doesn't exist `);
      });
  };

  const downloadFactFind = (item) => {
    let env = JSON.parse(localStorage.getItem("env"));
    let workspaceKey = localStorage.getItem("workspaceKey");
    let url = `${env.apiUrl}Onboarding/${item.factFindKey}/PreviewQuestionnaireDocument/${workspaceKey}`;
    axios.defaults.headers.common["Content-Type"] = `application/pdf`;
    axios
      .get(url, {
        responseType: "blob",
        timeout: 120,
        headers: {
          Accept: "application/octet-stream",
        },
      })
      .then((o) => {
        var blob = new Blob([o.data], {
          type: "application/pdf;charset=utf-8",
        });
        saveAs(blob, "FactFind");
      })
      .catch((err) => {
        showError(`File doesn't exist `);
      });
  };


    return (
      <div className="Advice">
        <div className="action-area">
          <div>
            <label className="title"> Advice </label>
          </div>
        </div>

        {innerWidthClass >= 960 ? (
          <div>
            {props.loading || props.adviceData.length == 0 ? (
              <TableAndChartLoading isLoad={props.loading} isNoRecord={props.adviceData.length == 0 ? true : false} isError={props.isError}/>
            ) : (
              props.adviceData.map((item, index) => {
                return (
                  <div key={index}>
                    <div key={index} className="NQ-web">
                      <div className="NQ-card">
                        <div className="NQ-card-body">
                          <GridContainer alignItems="center">
                            <GridItem lg={7} md={4} sm={12} xs={12}>
                              <GridContainer alignItems="center">
                                <GridItem lg={9} md={9} sm={10} xs={10}>
                                  <span>
                                    {item.name ? item.name : "--"} ({" "}
                                    {item.version ? item.version : "--"})
                                  </span>
                                </GridItem>
                                <GridItem lg={3} md={3} sm={2} xs={2}>
                                  <span className="download-Icon">
                                    {item.status === advicestatusType.active || item.proposalKey != null? (
                                      <SystemUpdateAltOutlinedIcon
                                        onClick={() => downloadAdvice(item)}
                                      />
                                    ) : null}
                                  </span>
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                            <GridItem lg={3} md={4} sm={12} xs={12}>
                              <GridContainer alignItems="center">
                                <GridItem lg={8} md={9} sm={10} xs={10}>
                                  <span>
                                    {" "}
                                    {item.factFindSnapshotName
                                      ? item.factFindSnapshotName
                                      : "--"}{" "}
                                  </span>
                                </GridItem>
                                <GridItem lg={4} md={3} sm={2} xs={2}>
                                  <span className="download-Icon">
                                    {item.status === advicestatusType.active && item.factFindKey != null? (
                                      <SystemUpdateAltOutlinedIcon
                                        onClick={() => downloadFactFind(item)}
                                      />
                                    ) : null}
                                  </span>
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                            <GridItem lg={2} md={4} sm={12} xs={12}>
                              <GridContainer alignItems="center">
                                <div style={{ paddingRight: '15px', display: 'flex', alignItems: 'center',marginLeft:'-22px', width:'100%' }}>
                                  <GridItem lg={9} md={9} sm={10} xs={10}>
                                  
                                      {item.signedDate
                                        ?
                                        <span>Signed <br></br>{FormatDateToText(item.signedDate)}</span>
                                        : "Not Signed"}
                                    
                                  </GridItem>
                                  <GridItem lg={3} md={3} sm={2} xs={2}>
                                    <span 
                                      className={
                                        item.status === advicestatusType.active
                                          ? "activeStatus"
                                          : item.status ===
                                            advicestatusType.pending
                                            ? "pendingStatus"
                                            : item.status ===
                                              advicestatusType.notactive
                                              ? "notactiveStatus"
                                              : ""
                                      }
                                    >
                                      <FiberManualRecordIcon />
                                    </span>
                                  </GridItem>
                                </div>
                              </GridContainer>
                            </GridItem>
                          </GridContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        ) : (
          <div>
            {props.loading || props.adviceData.length == 0 ? (
              <TableAndChartLoading isLoad={props.loading} isNoRecord={props.adviceData.length == 0 ? true : false} isError={props.isError}/>
            ) : (
              props.adviceData.map((item, index) => {
                return (
                  <div className="NQ-web" key={index}>
                    <div className="NQ-card">
                      <div className="NQ-card-body">
                        <GridContainer>
                          <GridItem lg={9} md={9} sm={10} xs={10}>
                            <span>
                              {" "}
                              {item.name ? item.name : "--"} ({" "}
                              {item.version ? item.version : "--"}){" "}
                            </span>
                          </GridItem>
                          <GridItem lg={3} md={3} sm={2} xs={2}>
                            <span className="download-Icon">
                              {item.status === advicestatusType.active ? (
                                <SystemUpdateAltOutlinedIcon
                                  onClick={() => downloadAdvice(item)}
                                />
                              ) : null}
                            </span>
                          </GridItem>
                          <GridItem lg={9} md={9} sm={10} xs={10}>
                            <span>
                              {" "}
                              {item.factFindSnapshotName
                                ? item.factFindSnapshotName
                                : "--"}{" "}
                            </span>
                          </GridItem>
                          <GridItem lg={3} md={3} sm={2} xs={2}>
                            <span className="download-Icon">
                              {item.status === advicestatusType.active ? (
                                <SystemUpdateAltOutlinedIcon
                                  onClick={() => downloadFactFind(item)}
                                />
                              ) : null}
                            </span>
                          </GridItem>
                          <GridItem lg={9} md={9} sm={10} xs={10}>
                              {item.signedDate
                                ?
                                <span>Signed <br></br>{FormatDateToText(item.signedDate)}</span>
                                : "Not Signed"}
                          </GridItem>
                          <GridItem lg={3} md={3} sm={2} xs={2}>
                            <span
                              className={
                                item.status === advicestatusType.active
                                  ? "activeStatus"
                                  : item.status === advicestatusType.pending
                                    ? "pendingStatus"
                                    : item.status === advicestatusType.notactive
                                      ? "notactiveStatus"
                                      : ""
                              }
                            >
                              <FiberManualRecordIcon />
                            </span>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
    );
  };

const mapStateToProps = (state) => {
  return {
    adviceData: state.adviceReducer.adviceData,
    loading: state.adviceReducer.loading,
    nqSuperSelected: state.sideBarReducer.nqSuperSelected,
    isError: state.adviceReducer.isError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adviceList: (isSuperSelected) => dispatch(getAdvice(isSuperSelected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Advice);
