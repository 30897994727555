export const API_ERROR = "API_ERROR";
export const NEWS_LIST = "NEWS_LIST";
export const Recent_Transaction = "Recent_Transaction";
export const Latest_Performance = "Latest_Performance";
export const Holding_PriceUnit = "Holding_PriceUnit";
export const Owners = "OwnersList";
export const GetAllFactFinds = "FactFindList";
export const MemberType = "MemberType";
export const Portfolios = "Portfolios";
export const GetAllGoals = "GetAllGoals";
export const DeleteGoals = "DeleteGoals";
export const AddHomeGoals = "AddHomeGoals";
export const AddDebtGoals = "AddDebtGoals";
export const AddRainyDayGoals = "AddRainyDayGoals";
export const AddOtherGoal = "AddOtherGoal";
export const Pots = "Pots";
export const PotsByType = "PotsByType";
export const DeletePot = "DeletePot";
export const PostAddNewPot = "PostAddNewPot";
export const EditPot = "EditPot";
export const InstrumentPerformance = "GetInstrumentPerformance";
export const SideBar = "SideBar";
export const PotsDetailList = "PotsDetailList";
export const PotTotal = "PotTotal";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const Crush_Debt_Type = "Crush_Debt_Type";
export const AssetTypes = "AssetTypes";
export const LiabilityTypes = "LiabilityTypes";
export const FactFindExpenseCategory = "FactFindExpenseCategory";
export const FactFindIncomeCategory = "FactFindIncomeCategory";
export const GetAdvice = "GetAdvice";
export const ByMembershipType = "ByMembershipType";
export const Request = "Request";
export const addNewRequest = "addNewRequest";
export const ClientRequestList = "ClientRequestList";
export const GetByKeyType = "GetByKeyType";
export const DeleteByKeyType = "DeleteByKeyType";
export const PostByObjectType = "PostByObjectType";
export const PutByObjectType = "PutByObjectType";
export const getGoalById = "getGoalById";
export const PutGoals = "PutGoals";
export const NQSuperSelected = "NQSuperSelected";
export const portfolioLoader = "portfolioLoader";
export const isSuperOnboardingCompleted = "isSuperOnboardingCompleted";
export const isSuperPortfolioExist = "isSuperPortfolioExist";