import React, { useEffect, useState } from "react";
import "../TextField/NQ-input-text.scss";

export default function NQInputText(props) {
  const [focus, setonFocus] = useState(false)
  
  useEffect(() => {
  }, [props.value]);
  const onFocus = () => {

    if (props.value) {
      setonFocus(false)
    } else {
      setonFocus(true)
    }
  }
  const onBlur = () => {

    if (props.value) {
      setonFocus(false)
    } else {
      setonFocus(true)
    }
  }

  return (
    <div className={(focus && props?.required ?
      (!props?.value ? 'isError' : '')
      : '')}>
      <div className="NQ-Input-Text">
        <div className="css-79elbk">
          <div className="css-npjedo">
            <input
              {...props}
              value={props.value ? props.value : null}
              type={props.type ? props.type : "text"}
              onChange={props.onChange}
              name={props.name} 
              onFocus={() => onFocus()}
              onBlur={onBlur}
            />
            <label>{props.caption}
              {
                props && props.required ? <span >*</span> : null
              }
            </label>
          </div>
        </div>

        {
          focus ? (props && props.required ?
            (!props.value ? <span className="errormsg">{props.caption}  {' is required'}</span> : null)
            : null)
            : null
        }
      </div>

    </div>
  );
}
