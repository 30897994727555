import { useAuth0 } from "@auth0/auth0-react";
import { Container } from "@material-ui/core";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import SignIn from "auth/login/login";
import axios from "axios";
import { createHashHistory } from "history";
import Admin from "layouts/Admin.js";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Router, Switch, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showError } from "utils/NQToaster";
import "./App.scss";
import initFontAwesome from "./utils/initFontAwesome";
import Unauthorized from "components/401/unauthorized";

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";



initFontAwesome();

const hashHistory = createHashHistory();
const browserHistory = createBrowserHistory({ basename: '' });
const App = () => {
 const env = JSON.parse(localStorage.getItem("env"));
  if (env.appInsightConnectionString) {
    var reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: env.appInsightConnectionString,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        enableRequestHeaderTracking: true,
        enableCorsCorrelation: true,
        correlationHeaderDomains: [new URL(env.apiUrl).hostname],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
      },
    });
    appInsights.loadAppInsights();
  }


  const showMaintenance = false;
  const { getAccessTokenSilently, logout } = useAuth0();
  const [unauthorized, setunauthorized] = useState(0);

  const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  }
  axios.interceptors.request.use(
    function (config) {
      let token = getToken();
      if (token) {
        config.headers.Authorization = `bearer ${token}`;
        return config;
      }
      else {
        return getAccessTokenSilently().then((token) => {
          localStorage.setItem("token", token);
          config.headers.Authorization = `bearer ${token}`;
          return config;
        });
      }
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (!error.response) {
        showError(
          "Failed to connect to server. Please try again or contact administrator."
        );
        return Promise.reject(error);
      }
      const {
        config,
        response: { status },
      } = error;
      if (status === 401 || status === 403 && (!error.config.headers.Authorization)) {
        // we need to indroduce Unauthorized page
        setunauthorized(1)
      } else if (status === 500) {
        showError("No Data Found... 🔍");
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  );
  useEffect(() => {
    localStorage.removeItem('token')
  }, []);

  return (
    <>
      {showMaintenance ? (
        <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", maxWidth: 600, margin: 20 }}>
            <img style={{ maxWidth: 500, width: '75%' }} src="/newquantum-logo.png" alt="New Quantum" />
            <h1 style={{ fontSize: 40, fontWeight: 700 }}>We'll be back soon!</h1>
            <p style={{ fontSize: 18 }}>
              We're currently performing maintenance.<br />
              We apologise for the inconvenience.<br />
              We won't be long, please check back shortly.
            </p>
          </div>
        </div>
      ) : (
        <Router history={hashHistory}>
          {unauthorized === 1 ? (
            <Unauthorized />
          ) : (
            <div id="app">
              <Container className="flex-grow-1 mt-5 no-padding-in-portfolio">
                <Switch>
                  <Route path="/SignIn" component={SignIn} />
                  <Route path="/admin" component={Admin} />
                  <Redirect from="/" to="/admin/Home" />
                </Switch>
              </Container>
              <ToastContainer
                closeButton={true}
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          )}
        </Router>
      )}
    </>
  );
};

export default App;
