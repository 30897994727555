import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NQDateInput from "components/Date/NQDateInput";
import NQDropDown from "components/DropDown/NQDropDown";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loading from "components/Loading";
import { FactFindGoalInvestmentStyles } from "Enums/fact-find-goal-investment-style";
import { FactFindGoalTypeEnum } from "Enums/fact-find-goal-type";
import { FactFindGoalDebtSettingEnum } from "Enums/FactFindGoalDebtSettingEnum";
import * as moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getFactFindDebtList } from "store/action/factFindAction";
import { AddDebtGoal, EditGoal, getById } from "store/action/goalsAction";
import { currencyFormat } from "utils/currencyFormat";
import { getMonthDifference } from "utils/date-time-helper";
import { FormatDate, MonthlyDateFormatter, NQDateInputMonthFormatter } from "utils/formatter";
import { isValidNumber, isValidValue, NPER, PMT, RoundUpToInteger, XNPER, XPMT } from "utils/helper";
import { showError } from "utils/NQToaster";
import car from "../../../assets/icon/car.svg";
import creditcard from "../../../assets/icon/creditcard.svg";
import debt from "../../../assets/icon/debt.svg";
import family_loan from "../../../assets/icon/family_loan.svg";
import houes from "../../../assets/icon/houes.svg";
import othercategory from "../../../assets/icon/othercategory.svg";
import secure_loan from "../../../assets/icon/secure_loan.svg";
import unsecure_loan from "../../../assets/icon/unsecure_loan.svg";
import NQInputText from "../../../components/TextField/NQ-input-text";
import { goalDebt } from "../../../constant/GoalType";
import "../AddGoal/AddDebt.scss";
import { showSuccess } from './../../../utils/NQToaster';
import "./AddHome.scss";

const span = (text) => {
  return `<span style="font-size: 0.66rem;display: flex;/* flex: 1; *//* width: auto; */text-align: center;position: relative;top: -7px;font-weight: 500;letter-spacing: 0.02rem !important;">${text} </span>`;
};

const categoryArray = [
  {
    image: unsecure_loan,
    value: "Unsecured Loan",
    span: span("Unsecure Loan"),
    field: "UnsecuredLoan",
  },
  {
    image: secure_loan,
    value: "Secure Loan",
    span: span("Secure Loan"),
    field: "SecureLoan",
  },
  {
    image: creditcard,
    value: "Credit Card",
    span: span("Credit Card"),
    field: "CreditCard",
  },
  {
    image: car,
    value: "Car Loan",
    span: span("Car Loan"),
    field: "CarLoan",
  },
  {
    image: houes,
    value: "Home Loan",
    span: span("Home Loan"),
    field: "HomeLoan",
  },
  {
    image: family_loan,
    value: "Family Loan",
    span: span("Family Loan"),
    field: "FamilyLoan",
  },
  {
    image: othercategory,
    value: "Other",
    span: span("Other"),
    field: "Other",
  },
];

const AddDebt = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    props.getFactFindDebtList();


    if (props.location.factFindGoalDebtKey) {
      dispatch(getById(props.location.factFindGoalDebtKey, "GoalDebts"));
    } else {
      goalDebtCrushObj.name = null;
      goalDebtCrushObj.status = 0;
      goalDebtCrushObj.goalAmount = 0;
      goalDebtCrushObj.plannedDate = null;
      goalDebtCrushObj.monthlyContribution = 0;
      goalDebtCrushObj.outstandingBalance = null;
      goalDebtCrushObj.interestRate = 0;
      goalDebtCrushObj.factFindGoalDebtSetting = null;
      goalDebtCrushObj.numberOfMonths = 0;
      goalDebtCrushObj.isComplete = false;
      goalDebtCrushObj.factFindGoalDebtCategory = null;
      goalDebtCrushObj.monthlyPayment = 0;
      setselectedOption("PayOffMyCardInASpecificPeriod");
      goalDebtCrushObj.factFindGoalDebtSetting =
        "PayOffMyCardInASpecificPeriod";
      setenablenoOfMonth(false);

      setgoalDebtCrushObj({
        ...goalDebtCrushObj,
      });
    }
  }, []);

  React.useEffect(() => {
    mapResponse();
    step1CrushDebtCalculation();
  }, [props]);
  const mapResponse = useCallback(() => {
    if (props.getbyIdData && props.getbyIdData[0]) {
      Object.keys(props.getbyIdData[0]).forEach((key) => {
        goalDebtCrushObj[key] = props.getbyIdData[0][key];
      });

      let obj = categoryArray.filter(
        (o) => o.value === goalDebtCrushObj.factFindGoalDebtCategory
      )[0];
      setCategory(goalDebtCrushObj.factFindGoalDebtCategory);
      goalDebtCrushObj.factFindGoalDebtCategory = obj?.field;

      //zeeshan
      let mapArray = [];

      for (var i = 0; i < props.getbyIdData.length; i++) {
        // goalDebtCrushObj.factFindGoalDebtSetting
        if (props.getbyIdData[0].factFindGoalDebtSetting) {
          let a = props.Crush_Debt_TypeList.filter(
            (a) => a.text === props.getbyIdData[0].factFindGoalDebtSetting
          )[0];
          if (a) {
            a["label"] = a.text;
            mapArray.push(a);
          }
        }
      }
      if (mapArray && mapArray.length > 0) {
        goalDebtCrushObj.factFindGoalDebtSetting = mapArray[0].value;
      }

      setgoalDebtCrushObj((prevState) => {
        return {
          ...prevState,
          goalDebtCrushObj,
          //
        };
      });
    }
  }, [props.getbyIdData]);

  const useStyles = makeStyles((theme) => ({
    root: { width: "100%" },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [category, setCategory] = useState();
  const [goalDebtCrushObj, setgoalDebtCrushObj] = useState(goalDebt);
  const [monthlyContribution, setmonthlyContribution] = useState(0);
  const [investmentStyleId] = useState(2);
  const history = useHistory();
  const [selectedGoalType] = useState(FactFindGoalTypeEnum.CrushDebt);
  const [enablenoOfMonth, setenablenoOfMonth] = useState(false);
  const [selectedOption, setselectedOption] = useState(
    "PayOffMyCardInASpecificPeriod"
  );
  const [numberOfMonths, setnumberOfMonths] = useState(0);
  const handleNext = (e) => {
    e.preventDefault();
    if (isValidate()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      step1CrushDebtCalculation();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    step1OtherCalculation();
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const navigateTo = () => {
    if (history.location && history.location.key) {
      history.push("/admin/Goal");
    } else {
      history.push("/admin/addgoalbytype");
    }

  };
  const classes = useStyles();
  const handleChange = e => {
    e.preventDefault();
    // setgoalDebtCrushObj({
    //   ...goalDebtCrushObj,
    //   [typeof e.target !== "undefined"
    //     ? [e.target.name]
    //     : "plannedDate"]: e.target ? e.target.value : x,
    // });

    setgoalDebtCrushObj({...goalDebtCrushObj, [e.target.name]: e.target.value});
  };

  useEffect(() => {
    step1CrushDebtCalculation();
  },[goalDebtCrushObj])

  const step1CrushDebtCalculation = () => {
     // Crush Debt
    //get the value
    let outstandingBalance = Number(goalDebtCrushObj.outstandingBalance ? goalDebtCrushObj.outstandingBalance : 0);
    setnumberOfMonths(Number(goalDebtCrushObj.numberOfMonths ? goalDebtCrushObj.numberOfMonths : 0));
    let numberOfMonths = goalDebtCrushObj.numberOfMonths
      ? Number(goalDebtCrushObj.numberOfMonths)
      : 0;
    let factFindGoalDebtSetting = goalDebtCrushObj.factFindGoalDebtSetting;
    let monthlyPayment =
      goalDebtCrushObj.monthlyPayment !== Infinity
        ? Number(goalDebtCrushObj.monthlyPayment)
        : 0;

    //calculation
    goalDebtCrushObj.goalAmount = outstandingBalance;

    if (
      factFindGoalDebtSetting !==
      FactFindGoalDebtSettingEnum.PayTheSameEachMonthUntilPaidOff
    ) {
      //calc contribution if period provided

      let date = new Date();
      //set planned date
      date.setMonth(date.getMonth() + Number(numberOfMonths));
      let formattedDate = moment(date);
      goalDebtCrushObj.plannedDate = formattedDate;

      //calc contribution
      let contribution = recalcMonthlyContribution(goalDebtCrushObj.goalAmount);
      setmonthlyContribution(contribution);
      goalDebtCrushObj.monthlyContribution =
        contribution !== Infinity ? contribution : 0;
      goalDebtCrushObj.monthlyPayment =
        contribution !== Infinity ? contribution : 0;
    } else {
      goalDebtCrushObj.monthlyPayment = monthlyPayment =
        goalDebtCrushObj.monthlyPayment > 0
          ? goalDebtCrushObj.monthlyPayment
          : monthlyContribution;
      setmonthlyContribution(monthlyPayment);
      goalDebtCrushObj.monthlyContribution = monthlyPayment;
      //calc months
      let months = recalcMonths();
      goalDebtCrushObj.numberOfMonths = months;
      setnumberOfMonths(months);
      let date = new Date();
      date.setMonth(date.getMonth() + Number(months));
      let formattedDate = moment(date);
      goalDebtCrushObj.plannedDate = formattedDate;
    }
  };          

  const TypeChange = (selectedOption) => {
    setselectedOption(selectedOption.value);
    setgoalDebtCrushObj((state) => {
      return {
        ...state,
        factFindGoalDebtSetting: selectedOption.value,
      };
    });
  
    selectedOption.value ===
      FactFindGoalDebtSettingEnum.PayTheSameEachMonthUntilPaidOff
      ? setenablenoOfMonth(true)
      : setenablenoOfMonth(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidate()) {
      let factFindOwner = [];
      if (props.location.factFindGoalDebtKey) {
        factFindOwner.push({
          ownerKey: localStorage.getItem("contactKey"),
          percentage: 100,
        });
        goalDebtCrushObj.factFindOwner = factFindOwner;
        goalDebtCrushObj.goalType = "CrushDebt";
        goalDebtCrushObj.plannedDate = new Date(goalDebtCrushObj.plannedDate);
        goalDebtCrushObj.ContactKey = localStorage.getItem("contactKey");
        goalDebtCrushObj.NucleusKey = localStorage.getItem("contactKey");
        await dispatch(
          EditGoal(
            props.location.key,
            props.location.factFindGoalDebtKey,
            "GoalDebts",
            goalDebtCrushObj,
            history
          )
        );
      } else {
        goalDebtCrushObj.plannedDate = moment(
          goalDebtCrushObj.plannedDate
        ).format("YYYY-MMM-DD");
        goalDebtCrushObj.ContactKey = localStorage.getItem("contactKey");
        goalDebtCrushObj.NucleusKey = localStorage.getItem("contactKey");
        props.AddDebtGoal(goalDebtCrushObj, history).then(o => {
          showSuccess();
          history.push("/admin/Goal");
        }).catch(er => {
          let msg = "";
          Object.keys(er.response.data).forEach((o) => {
            msg += er.response.data[o];
          });
          showError(msg);
        });
      }
    }
  };

  function getSteps() {
    return ["Detail", "Summary"];
  }

  const step1OtherCalculation = () => {
    const priorSavingAmount = isValidNumber(goalDebtCrushObj.priorSavingAmount)
      ? Number(goalDebtCrushObj.priorSavingAmount)
      : 0;
    const otherGoalAmount = goalDebtCrushObj.otherGoalAmount;
    let goalAmount = otherGoalAmount - priorSavingAmount;
    goalDebtCrushObj.goalAmount = goalAmount;

    if (!isValidValue(goalDebtCrushObj.plannedDate)) {
      let date = new Date();
      date.setMonth(date.getMonth() + 12);
      goalDebtCrushObj.plannedDate = date;
    }

    let contribution = recalcMonthlyContribution(goalAmount);
    setmonthlyContribution(contribution);
    goalDebtCrushObj.monthlyContribution = contribution;
  };

  const recalcMonthlyContribution = (goalAmount) => {
    let monthlyContirbution = 0;
    const plannedDate = goalDebtCrushObj.plannedDate;
    const monthDifference = getMonthDifference(
      new Date(),
      new Date(plannedDate)
    );
    const priorSavingAmount = isValidNumber(
      Number(goalDebtCrushObj.priorSavingAmount)
    )
      ? Number(goalDebtCrushObj.priorSavingAmount)
      : 0;
    const returnRate = FactFindGoalInvestmentStyles[investmentStyleId].value;
    const interestRate = Number(goalDebtCrushObj.interestRate) / 100;
    const outstandingBalance = Number(goalDebtCrushObj.outstandingBalance);

    if (selectedGoalType === FactFindGoalTypeEnum.RainyDay) {
      monthlyContirbution = RoundUpToInteger(goalAmount / monthDifference);
    } else if (selectedGoalType === FactFindGoalTypeEnum.Retirement) {
      monthlyContirbution = -XPMT(
        returnRate / 12,
        monthDifference,
        -priorSavingAmount,
        goalAmount
      );
      monthlyContirbution = RoundUpToInteger(monthlyContirbution);
    } else if (selectedGoalType === FactFindGoalTypeEnum.Home) {
      monthlyContirbution = RoundUpToInteger(goalAmount / monthDifference);
    } else if (selectedGoalType === FactFindGoalTypeEnum.Other) {
      monthlyContirbution = RoundUpToInteger(goalAmount / monthDifference);
    } else if (selectedGoalType === FactFindGoalTypeEnum.CrushDebt) {
      monthlyContirbution = -PMT(
        interestRate / 12,
        monthDifference,
        outstandingBalance,
        0,
        0
      );
      monthlyContirbution = RoundUpToInteger(monthlyContirbution);
    }

    return monthlyContirbution === Infinity ? 0 : monthlyContirbution;
  };

  const recalcMonths = () => {
    const goalAmount = Number(goalDebtCrushObj.goalAmount);
    const monthlyContribution = Number(goalDebtCrushObj.monthlyContribution);
    const returnRate = FactFindGoalInvestmentStyles[investmentStyleId].value;
    const interestRate = Number(goalDebtCrushObj.interestRate) / 100;
    const priorSavingAmount = isValidNumber(
      Number(goalDebtCrushObj.priorSavingAmount)
    )
      ? Number(goalDebtCrushObj.priorSavingAmount)
      : 0;
    const outstandingBalance = Number(goalDebtCrushObj.outstandingBalance);

    let calcMonths = null;

    if (selectedGoalType === FactFindGoalTypeEnum.RainyDay) {
      calcMonths = RoundUpToInteger(goalAmount / monthlyContribution);
    } else if (selectedGoalType === FactFindGoalTypeEnum.Retirement) {
      calcMonths = XNPER(
        returnRate / 12,
        -priorSavingAmount,
        goalAmount,
        -monthlyContribution
      );
      calcMonths = RoundUpToInteger(calcMonths);
    } else if (selectedGoalType === FactFindGoalTypeEnum.Home) {
      calcMonths = RoundUpToInteger(goalAmount / monthlyContribution);
    } else if (selectedGoalType === FactFindGoalTypeEnum.Other) {
      calcMonths = RoundUpToInteger(goalAmount / monthlyContribution);
    } else if (selectedGoalType === FactFindGoalTypeEnum.CrushDebt) {
      calcMonths = NPER(
        interestRate / 12,
        -monthlyContribution,
        outstandingBalance,
        0,
        0
      );
      calcMonths = RoundUpToInteger(calcMonths);
    }

    return calcMonths;
  };

  const getDebtList = () => {
    return props.Crush_Debt_TypeList.map((x) => ({
      ...x,
      label: x.text,
    }));
  };

  const setSelectedCategory = (item) => {
    setCategory(item.value);
    goalDebtCrushObj.factFindGoalDebtCategory = item.field;
  };

  const changePlannedDate = (event) => {
    // if (event.value) {
    //   goalDebtCrushObj.plannedDate = event.value;
    //   step1OtherCalculation();
    // }
    var monthControl = document.querySelector('input[type="month"]');
    if (monthControl && monthControl.value) {
      setgoalDebtCrushObj((state) => {
        return {
          ...state,
          plannedDate: NQDateInputMonthFormatter(monthControl.value),
        };
      });
      step1OtherCalculation();
    }
  };
  const isValidate = () => {
    let isValid = true;
    if(!category){
      isValid = false
      showError("Please select a category")
    }
    if (activeStep === 0) {
      if (!goalDebtCrushObj.outstandingBalance) {
        isValid = false;
        showError("Outstanding Balance can't be empty");
      }
      if (!goalDebtCrushObj.numberOfMonths) {
        isValid = false;
        showError("Number of Month   can't be empty");
      }
    }
    if (activeStep === 1) {
      if (!goalDebtCrushObj.name) {
        isValid = false;
        showError("Goal Name can't be empty");
      }
      if (!goalDebtCrushObj.goalAmount) {
        isValid = false;
        showError("Goal Amount can't be empty");
      }
      if (!goalDebtCrushObj.monthlyContribution) {
        isValid = false;
        showError("Monthly Contribution can't be empty");
      }
    }

    return isValid;
  };
  if (props.loading) {
    return <Loading />;
  } else {
    return (
      <div className="addgoalhome adddebt">
        <form>
          <React.Fragment>
            {activeStep === steps.length ? (
              <div className="mainDiv">
                <Typography className={classes.instructions}>
                  All steps completed
                </Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div className={classes.instructions}>
                {activeStep === 0 ? (
                  <div className="firststep">
                    <GridContainer>
                      <GridItem lg={12} sm={12} xs={12} md={12}>
                        <div className="css-q4good">
                          <label
                            className="cat-title"
                            style={{
                              borderBottom: "1px solid #ddd",
                              paddingBottom: "10px",
                              fontWeight: "700",
                              color: "#191919",
                            }}
                          >
                            <img
                              height="60"
                              src={debt}
                              width="50"
                              alt={"Crush debt"}
                            />
                            <div
                              style={{
                                alignSelf: "center",
                                marginLeft: "15px",
                              }}
                            >
                              Crush debt
                            </div>
                          </label>
                          <h1 className="css-1tu684v">
                            Tell us about your debts
                          </h1>
                          <div>
                            <label className="cat-title">Category</label>
                            <div className="category">
                              {categoryArray.map((item, index) => (
                                <div
                                  key={index}
                                  className="NQ-web"
                                  onClick={() => setSelectedCategory(item)}
                                >
                                  <div className="NQ-card goal-card">
                                    <div
                                      className={`${item.value === category &&
                                        "cartory-border "
                                        } NQ-card-body`}
                                    >
                                      <div className="NQ-left">
                                        <div className="NQ-col-1">
                                          <img
                                            height="40"
                                            src={item.image}
                                            width="60"
                                            alt={item.value}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: item.span,
                                    }}
                                  ></div>
                                </div>
                              ))}
                            </div>

                            <div className="css-1yjvs5a">
                              <div className="sc-oefIU cAmEDQ">
                                <div className="sc-hhIhEF fsTaMx">
                                  <div className="sc-oefIU ecNPTU">
                                    <div className="sc-hhIhEF enPTrg">
                                      <NQInputText
                                        caption="Outstanding balance"
                                        id="outstandingBalance"
                                        value={
                                          goalDebtCrushObj.outstandingBalance
                                        }
                                        variant="outlined"
                                        label="Outstanding balance"
                                        type="number"
                                        required={true}
                                        name="outstandingBalance"
                                        onChange={handleChange}
                                      />
                                      <NQInputText
                                        caption="Interest rate % p.a."
                                        id="interestRate"
                                        value={goalDebtCrushObj.interestRate}
                                        variant="outlined"
                                        label="Interest rate"
                                        name="interestRate"
                                        // onChange={handleChange}
                                        onChange={handleChange}
                                        type="number"
                                        size="medium"
                                      />
                                      <NQDropDown
                                        caption="Type"
                                        id="factFindGoalDebtSetting-id"
                                        variant="outlined"
                                        label="factFindGoalDebtSetting"
                                        required={true}
                                        name="factFindGoalDebtSetting"
                                        onChange={TypeChange}
                                        data={getDebtList()}
                                        defaultValue={selectedOption}
                                        value={
                                          goalDebtCrushObj.factFindGoalDebtSetting
                                        }
                                      />
                                      {!enablenoOfMonth ? (
                                        <NQInputText
                                          caption="Number of Months"
                                          id="numberOfMonths"
                                          value={
                                            goalDebtCrushObj.numberOfMonths
                                          }
                                          variant="outlined"
                                          label="Number of Months"
                                          required={true}
                                          type="number"
                                          name="numberOfMonths"
                                          //onChange={handleChange}
                                          onChange={handleChange}
                                        />
                                      ) : (
                                        <NQInputText
                                          caption="Monthly Payment"
                                          id="monthlyPayment"
                                          value={
                                            goalDebtCrushObj.monthlyPayment
                                          }
                                          variant="outlined"
                                          label="Monthly Payment"
                                          type="number"
                                          required={true}
                                          name="monthlyPayment"
                                          onChange={handleChange}
                                          //onChange={(e, x) => handleChange(e, x)}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="sc-oefIU cAmEDQ">
                                  <div className="sc-hhIhEF fsTaMx">
                                    <div className="css-1ucoqeb">
                                      <div className="sc-oefIU ecNPTU">
                                        <div className="sc-hhIhEF enPTrg">
                                          <h4
                                            style={{
                                              color: "black",
                                              fontWeight: "500",
                                            }}
                                          >
                                            How much do you need?
                                          </h4>
                                          {
                                            <p className="css-xi606m">
                                              Based on your debt and your plan,
                                              we recommend you pay &nbsp;
                                              <b
                                                style={{
                                                  fontWeight: "600",
                                                  marginLeft: 5,
                                                  marginRight: 5,
                                                }}
                                              >
                                                {currencyFormat(
                                                  monthlyContribution
                                                )}
                                              </b>
                                              &nbsp; each month until your
                                              balance will be paid off in &nbsp;
                                              <strong
                                                style={{
                                                  fontWeight: "600",
                                                  marginLeft: 5,
                                                  marginRight: 5,
                                                }}
                                              >
                                                {isNaN(numberOfMonths) || (numberOfMonths === -Infinity)  ? 0 : numberOfMonths} 
                                              </strong>
                                              &nbsp; months
                                            </p>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="css-dvxtzn">
                              <button
                                onClick={handleNext}
                                type="submit"
                                className="css-jhd49u"
                              >
                                Next
                              </button>
                              <button
                                onClick={() => navigateTo()}
                                type="button"
                                className="css-1gld5x9"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                ) : (
                  <div className="nextstep">
                    <GridContainer>
                      <GridItem lg={12} sm={12} xs={12} md={12}>
                        <div className="css-q4good">
                          <h1 className="css-1tu684v">Your Goal/Plan</h1>
                          <div className="css-1yjvs5a next">
                            <div className="sc-oefIU cAmEDQ">
                              <div className="sc-hhIhEF fsTaMx">
                                <div className="sc-oefIU ecNPTU">
                                  <div className="sc-hhIhEF enPTrg">
                                    <NQInputText
                                      caption="Your Goal Name"
                                      id="name"
                                      value={goalDebtCrushObj.name}
                                      variant="outlined"
                                      label="Your Goal Name"
                                      required={true}
                                      name="name"
                                      onChange={handleChange}
                                      size="medium"
                                    />
                                    <NQInputText
                                      caption="Your Goal Amount"
                                      id="goalAmount"
                                      value={goalDebtCrushObj.goalAmount}
                                      variant="outlined"
                                      label="Your Goal Amount"
                                      required={true}
                                      type="number"
                                      name="goalAmount"
                                      onChange={handleChange}
                                    />

                                    <NQDateInput
                                      caption="Planned Date"
                                      id="outlined-number"
                                      value={FormatDate(
                                        goalDebtCrushObj.plannedDate
                                      )}
                                      variant="outlined"
                                      label="Planned Date"
                                      required={true}
                                      name="plannedDate"
                                      onChange={(event) =>
                                        changePlannedDate(event)
                                      }
                                    />

                                    <NQInputText
                                      caption="Your Monthly Contribution"
                                      id="monthlyContribution"
                                      value={
                                        goalDebtCrushObj.monthlyContribution
                                      }
                                      variant="outlined"
                                      label="Your Monthly Contribution"
                                      name="monthlyContribution"
                                      type="number"
                                      required={true}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="sc-oefIU cAmEDQ">
                                <div className="sc-hhIhEF fsTaMx">
                                  <div className="css-1ucoqeb">
                                    <div className="sc-oefIU ecNPTU">
                                      <div className="sc-hhIhEF enPTrg">
                                        <p className="css-xi606m">
                                          Nice! By saving
                                          <b>
                                            <strong
                                              style={{
                                                fontWeight: "700",
                                                marginLeft: 5,
                                                marginRight: 5,
                                              }}
                                            >
                                              &nbsp;
                                              {currencyFormat(
                                                goalDebtCrushObj.monthlyContribution
                                              )}
                                              &nbsp;
                                            </strong>
                                          </b>
                                          per month, we project that you will
                                          reach your goal by your planned date
                                          of
                                          <b>
                                            <strong
                                              style={{
                                                fontWeight: "700",
                                                marginLeft: 5,
                                                marginRight: 5,
                                              }}
                                            >
                                              &nbsp;
                                              {MonthlyDateFormatter(
                                                goalDebtCrushObj.plannedDate
                                              )}
                                              &nbsp;
                                            </strong>
                                          </b>
                                          <br></br>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="css-dvxtzn">
                            <button
                              onClick={handleSubmit}
                              type="button"
                              className="css-jhd49u"
                            >
                              Save
                            </button>
                            <button
                              disabled={activeStep === 0}
                              type="button"
                              onClick={handleBack}
                              className="css-1gld5x9"
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                )}
              </div>
            )}
          </React.Fragment>
        </form>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    Crush_Debt_TypeList: state.factFindReducer.Crush_Debt_Type,
    loading: state.goalsReducer.loading,
    getbyIdData: state.goalsReducer.getbyIdData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFactFindDebtList: () => dispatch(getFactFindDebtList()),
    AddDebtGoal: (debtobject, history) =>
      dispatch(AddDebtGoal(debtobject, history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDebt);
