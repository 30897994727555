import React, { useState, useEffect } from "react";
import "./Transaction.scss";
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import { getRecentTransaction, loading } from "store/action/recentTransactionAction";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import GridItem from "components/Grid/GridItem";
import { currencyFormat } from "utils/currencyFormat";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { FormatDateToText } from "utils/formatter";
import Loading from "components/Loading";
import { CSVLink } from "react-csv";
import { useHistory } from 'react-router-dom';
import Accordion from './../../../src/components/Accordion/Accordion';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import NQDropDown from "components/DropDown/NQDropDown";
import TableAndChartLoading from "../../components/TableAndChartLoading/TableAndChartLoading";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import { FileAPI } from "constant/APICalls";
import axios from "axios";
import { saveAs } from "file-saver";
import * as moment from "moment";
import { showError } from "utils/NQToaster";

const Transaction = (props) => {
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [transactionsData, setTransactionsData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isError, setIsError] = useState(false);

  const history = useHistory();
  const csvHeaders = [
    { label: "Date", key: "transactionDate" },
    { label: "Account #", key: "accountNumber" },
    { label: "Transaction Type", key: "gLTranType" },
    { label: "Description", key: "transactionDescription" },
    { label: "Category", key: "gLCategory" },
    { label: "Provider", key: "accountProvider" },
    { label: "Value", key: "transactionValue" }
  ];

  // Go to reports page
  const navigateToReports = () => {
    history.push("/admin/reports");
  };

  // Set category filter on change
  const updateCategoryFilter = (option) => {
    setCategoryFilter(option ? option.label : '');
  };

  // Set search filter to empty
  const clearSearchQuery = () => {
    setSearchQuery('');
  };

  // Set search filter on change
  const updateSearchQuery = (event) => {
    setSearchQuery(event.target.value);
  };

  // Set filter category options
  useEffect(() => {
    if (transactionsData.length) {
      const categoriesSet = [...new Set(transactionsData.map(t => t.gLCategory))];
      const options = categoriesSet.filter(Boolean).map(o => {
        return {
          label: o,
          value: o.replaceAll(' ', ''),
        };
      });
      setCategoryOptions(options);
    }
  }, [transactionsData]);

  // Filter transactions
  useEffect(() => {
    let transactions = [...transactionsData];
    
    // Category filter
    if (categoryFilter) {
      transactions = transactions.filter(t => {
        return t.gLCategory && t.gLCategory.toLowerCase() === categoryFilter.toLowerCase();
      });
    }

    // Search filter
    if (searchQuery) {
      transactions = transactions.filter(t => {
        return (t.transactionDate && FormatDateToText(t.transactionDate).toLowerCase().includes(searchQuery.toLowerCase())) ||
          (t.accountNumber && t.accountNumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (t.gLTranType && t.gLTranType.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (t.transactionDescription && t.transactionDescription.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (t.accountProvider && t.accountProvider.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (t.transactionValue && t.transactionValue.toFixed(2).includes(searchQuery)) ||
          (t.contractNote && t.contractNote.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (t.externalTransactionId && t.externalTransactionId.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (t.gLCategory && t.gLCategory.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (t.contractNote && t.instrumentCode && t.instrumentCode.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (t.contractNote && t.instrumentName && t.instrumentName.toLowerCase().includes(searchQuery.toLowerCase()));
          
      });
    }

    setFilteredTransactions(transactions);
  }, [transactionsData, categoryFilter, searchQuery]);

  // Things to execute on load
  useEffect(() => {
    // Get transactions data
    props.transactionList().then(response => {
      const data = sortTransactionsByDate(response.data.data);
      setTransactionsData(data);
    }).catch(err => {
      setIsError(true);
    }).finally(() => {
      props.loaded(false);
    });
  }, []);

  return (
    <>
      <div className="Transaction-page">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div
              className="css-naxvie"
            >
              <div className="css-10ym0te">
                <label className="title">Transactions</label>
              </div>
              <div className="report">
                <div onClick={navigateToReports}>Reports</div>
              </div>
            </div>
            <div className="help-text">
                <p> <i>Click each transaction to learn more.</i></p>
              </div>
          </GridItem>

          <GridItem lg={12} xs={12} sm={12} md={12}>
            <div className="css-2mqgjc">
              <div className="css-ea35n1">
                <div className="css-1kmimlc">
                  <div className="css-4lc1dm">
                    <Grid container spacing={4} alignItems="flex-end">
                      <Grid item xs={12} lg={4}>
                        <NQDropDown
                          caption="Category"
                          id="id"
                          variant="outlined"
                          label="stxt"
                          name="stxt"
                          onChange={updateCategoryFilter}
                          data={categoryOptions}
                        />
                      </Grid>
                      <Grid item xs={12} lg>
                        <TextField
                          type="search"
                          name="search-input"
                          variant="standard"
                          placeholder="Search your Transaction history..."
                          inputProps={{ 'aria-label': 'search' }}
                          value={searchQuery}
                          onChange={updateSearchQuery}
                          size="medium"
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                aria-label="submit search"
                                onClick={searchQuery ? clearSearchQuery : updateSearchQuery }
                                classes={{root: 'search-button'}}
                                edge="end"
                              >
                                {searchQuery ? <CloseIcon /> : <SearchIcon />}
                              </IconButton>
                            )
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid container item xs={12} lg={2} alignItems="center" justifyContent="flex-end">
                        <CSVLink className="download-csv-button" data={filteredTransactions} headers={csvHeaders} >
                          <FileCopyIcon style={{ color: '#333' }} title="Export CSV" />
                        </CSVLink>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="ag-theme-alpine" style={{ minHeight: '200px' }}>
                    {props.loading || !filteredTransactions.length ? (
                      <TableAndChartLoading isLoad={props.loading} isNoRecord={!filteredTransactions.length} isError={isError} />
                    ) : (
                      <div>
                        {filteredTransactions.map((item, index) => {
                          return (
                            <div className="NQ-accordion" key={index}>
                              <Accordion allowMultipleOpen key={index}>
                                <div label="Question 1" key={index}>
                                  <div className="dpTvjJ">
                                    <div className="sc-fujyUd">
                                      <div className="eOsVek">
                                        <p >
                                          {FormatDateToText(item.transactionDate)}
                                        </p>
                                      </div>
                                      <div className="eOsVek">
                                        <p >
                                          {item.accountNumber}
                                        </p>
                                      </div>
                                      <div className="eelevm">
                                        <div className="hukjwq">
                                          <p className="hSaKVG">
                                            {item.gLTranType}
                                          </p>
                                        </div>
                                        <div className="eSQXjd">
                                          <p>
                                            {item.gLCategory}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="cnwwRC">
                                      <p className="hSaKVG">
                                        {currencyFormat(item.transactionValue)}
                                      </p>
                                    </div>
                                    <div className="w-30">
                                      <span className="download-Icon">
                                        {(item.documentName) ? (
                                          <SystemUpdateAltOutlinedIcon
                                            onClick={(event) => downloadFile(event, item)}
                                          />
                                        ) : null
                                        }
                                      </span>
                                    </div>

                                  </div>
                                  <div className="grVxVP">
                                    <div className="fusNkn">
                                      <div className="grVxVP">
                                        <div className="jCasOz">
                                          <div className="cxKfdi">
                                            <div className="QEnpQ">

                                              <div className="jPBbio">
                                                <div className="grVxVP">
                                                  <div className="fQhVUh">
                                                    <p className="itLnTj">Account #</p>
                                                  </div>
                                                  <div className="fQhVUh">
                                                    <p className="jtfDWi"> {item.accountNumber}</p>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="jPBbio p-l-mobile">
                                                <div className="grVxVP">
                                                  <div className="fQhVUh">
                                                    <p className="itLnTj">Account Provider</p>
                                                  </div>
                                                  <div className="fQhVUh">
                                                    <p className="jtfDWi"> {item.accountProvider}</p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="jPBbio">
                                                <div className="grVxVP">
                                                  <div className="fQhVUh">
                                                    <p className="itLnTj">Description</p>
                                                  </div>
                                                  <div className="fQhVUh">
                                                    <p className="jtfDWi"> {item.transactionDescription === null ? (item.contractNote === null ? item.gLTranType + '' + item.units + '' + item.instrumentCode + '' + item.unitPrice:item.contractNote):item.transactionDescription}</p>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="jPBbio">
                                                <div className="grVxVP">
                                                  <div className="fQhVUh">
                                                    <p className="itLnTj">Reference #</p>
                                                  </div>
                                                  <div className="fQhVUh">
                                                    <p className="jtfDWi">
                                                      {
                                                        (item.contractNote) ?
                                                          (item.contractNote) :
                                                          (item.externalTransactionId)
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              {item.contractNote !== null &&
                                                  (<>
                                                    <div className="jPBbio">
                                                      <div className="grVxVP">
                                                        <div className="fQhVUh">
                                                          <p className="itLnTj">InstrumentCode </p>
                                                        </div>
                                                        <div className="fQhVUh">
                                                          <p className="jtfDWi">
                                                            {(item.instrumentCode) ?
                                                              (item.instrumentCode) :
                                                              ('')}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="jPBbio">
                                                      <div className="grVxVP">
                                                        <div className="fQhVUh">
                                                          <p className="itLnTj">InstrumentName</p>
                                                        </div>
                                                        <div className="fQhVUh">
                                                          <p className="jtfDWi">
                                                            {(item.instrumentName) ?
                                                              (item.instrumentName) :
                                                              ('')}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="jPBbio">
                                                      <div className="grVxVP">
                                                        <div className="fQhVUh">
                                                          <p className="itLnTj">Fees</p>
                                                        </div>
                                                        <div className="fQhVUh">
                                                          <p className="jtfDWi">
                                                            {(item.transactionFee !== null) ?
                                                              (item.transactionFee) :
                                                              ('')}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>)
                                              }                                              
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>

                                  </div>
                                </div>
                                <div label="Question 2"   >
                                  <div></div>
                                  <div></div>
                                </div>
                              </Accordion>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div >
    </>
  );

};

const mapStateToProps = (state) => {
  return {
    loading: state.recentTransactionReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    transactionList: () => dispatch(getRecentTransaction()),
    loaded: (data) => dispatch(loading(data)),
  };
};

const sortTransactionsByDate = (array) => {
  return array.sort((a, b) => new Date(a.transactionDate).getTime() - new Date(b.transactionDate).getTime()).reverse();
};

const downloadFile = (e, item) => {
  e.stopPropagation();

  const env = JSON.parse(localStorage.getItem("env"));
  let workspaceKey = localStorage.getItem("workspaceKey");
  const url = `${env.apiUrl}${FileAPI.controller}/${item.financialTransactionKey}/${FileAPI.action}/${workspaceKey}`;
  let financialDate = moment(item.transactionDate).format("YYYYMMDD");
  let glType = item.gLTranType ? `_${item.gLTranType}` : "";
  let contractNote = item.contractNote ? `_${item.contractNote}` : "";
  let accountNumber = item.accountNumber ? `_${item.accountNumber}` : "";
  let fileName = `${financialDate}${contractNote}${glType}${accountNumber}`
  axios
    .get(url, { responseType: 'blob' })
    .then((o) => {
      saveAs(o.data, fileName);
    })
    .catch((err) => {
      showError(`File doesn't exist `);
    });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withAuthenticationRequired(Transaction, {
    onRedirecting: () => <Loading />,
  })
);
