import { Latest_Performance, SHOW_LOADER } from "../types";

const initialState = {
  latestPerformanceDataObj: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Latest_Performance:
      return {
        ...state,
        latestPerformanceDataObj: action.payload,
      };
    case SHOW_LOADER:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
}
