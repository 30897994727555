import Button from "@material-ui/core/Button";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NQDateInput from "components/Date/NQDateInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { FactFindGoalTypeEnum } from "Enums/fact-find-goal-type";
import * as moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AddRainyDayGoal, EditGoal, getById } from "store/action/goalsAction";
import { currencyFormat } from "utils/currencyFormat";
import { getMonthDifference } from "utils/date-time-helper";
import { NQDateInputMonthFormatter, textDateFormatter } from "utils/formatter";
import { RoundUpToInteger } from "utils/helper";
import { showError } from "utils/NQToaster";
import rainyday from "../../../assets/icon/rainyday.svg";
import NQInputText from "../../../components/TextField/NQ-input-text";
import { goalRainyDay } from "../../../constant/GoalType";
import { showSuccess } from './../../../utils/NQToaster';
import "./AddHome.scss";
import "./AddRainyDay.scss";
const AddRainyDay = (props) => {
  const monthsWithoutIncome = React.createRef();
  const useStyles = makeStyles((theme) => ({
    root: { width: "100%" },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [goalRainyDayObj, setgoalRainyDayObj] = useState(goalRainyDay);
  const [selectedGoalType] = useState(FactFindGoalTypeEnum.RainyDay);
  const history = useHistory();

  const handleNext = (e) => {
    e.preventDefault();
    if (isValidate()) {
      goalRainyDayObj.plannedDate = new Date(
        new Date().getFullYear() + 1,
        new Date().getMonth() + 1,
        0
      );

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      step1RainyDayCalculation();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidate()) {
      let factFindOwner = [];
      if (props.location.factFindGoalRainyDaysKey) {
        factFindOwner.push({
          ownerKey: localStorage.getItem("contactKey"),
          percentage: 100,
        });
        goalRainyDayObj.factFindOwner = factFindOwner;
        goalRainyDayObj.goalType = "RainyDay";
        goalRainyDayObj.ContactKey = localStorage.getItem("contactKey");
        goalRainyDayObj.NucleusKey = localStorage.getItem("contactKey");
        await dispatch(
          EditGoal(
            props.location.key,
            props.location.factFindGoalRainyDaysKey,
            "GoalRainyDays",
            goalRainyDayObj,
            history
          )
        );
      } else {
        goalRainyDayObj.plannedDate = moment(
          goalRainyDayObj.plannedDate
        ).format("YYYY-MMM-DD");
        goalRainyDayObj.ContactKey = localStorage.getItem("contactKey");
        goalRainyDayObj.NucleusKey = localStorage.getItem("contactKey");
        props.AddRainyDayGoal(goalRainyDayObj, history).then(o => {
          showSuccess();
          history.push("/admin/Goal");
        }).catch(er => {
          let msg = "";
          Object.keys(er.response.data).forEach((o) => {
            msg += er.response.data[o];
          });
          showError(msg);
        });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const navigateTo = () => {
    if (history.location && history.location.key) {
      history.push("/admin/Goal");
    } else {
      history.push("/admin/addgoalbytype");
    }
  };

  const classes = useStyles();
  const handleChange = (e, x) => {
    setgoalRainyDayObj({
      ...goalRainyDayObj,
      [typeof e.target !== "undefined"
        ? [e.target.name]
        : "plannedDate"]: e.target ? e.target.value : x,
    });

    goalRainyDayObj[e.target.name] = e.target.value ? e.target.value : e.target;

    setgoalRainyDayObj((state) => {
      return {
        ...state,
        plannedDate: NQDateInputMonthFormatter(goalRainyDayObj.plannedDate),
      };
    });
    if (e.target.name !== "name")
      step1RainyDayCalculation();
  };


  function getSteps() {
    return ["Detail", "Summary"];
  }

  const step1RainyDayCalculation = () => {
    const averageMonthlySpending = goalRainyDayObj.averageMonthlySpending
      ? Number(goalRainyDayObj.averageMonthlySpending)
      : 0;
    const monthsWithoutIncome = goalRainyDayObj.monthsWithoutIncome
      ? Number(goalRainyDayObj.monthsWithoutIncome)
      : 0;
    const priorSavingAmount = goalRainyDayObj.monthsWithoutIncome
      ? Number(goalRainyDayObj.priorSavingAmount)
      : 0;
    let goalAmount = Math.round(
      averageMonthlySpending * monthsWithoutIncome - priorSavingAmount
    );
    goalRainyDayObj.goalAmount = goalAmount;
    goalRainyDayObj.monthsWithoutIncome = monthsWithoutIncome;
    goalRainyDayObj.monthlyContribution = recalcMonthlyContribution();
  };

  const recalcMonthlyContribution = () => {
    let monthlyContirbution = 0;
    const goalAmount = Number(goalRainyDayObj.goalAmount);
    const plannedDate = goalRainyDayObj.plannedDate;
    const monthDifference = getMonthDifference(
      new Date(),
      new Date(plannedDate)
    );
    if (selectedGoalType == FactFindGoalTypeEnum.RainyDay) {
      monthlyContirbution = RoundUpToInteger(goalAmount / monthDifference);
    }

    return monthlyContirbution === Infinity ? 0 : monthlyContirbution;
  };
  const dispatch = useDispatch();
  useEffect(() => {
    goalRainyDayObj.plannedDate = new Date(
      new Date().getFullYear() + 1,
      new Date().getMonth() + 1,
      0
    );

    if (props.location.factFindGoalRainyDaysKey) {
      dispatch(
        getById(props.location.factFindGoalRainyDaysKey, "GoalRainyDays")
      );
    } else {
      goalRainyDayObj.name = "";
      goalRainyDayObj.status = 0;
      goalRainyDayObj.goalAmount = 0;
      goalRainyDayObj.plannedDate = null;
      goalRainyDayObj.monthlyContribution = 0;
      goalRainyDayObj.averageMonthlySpending = 0;
      goalRainyDayObj.monthsWithoutIncome = 2;
      goalRainyDayObj.isComplete = false;
      goalRainyDayObj.priorSavingAmount = 0;
      setgoalRainyDayObj({ ...goalRainyDayObj });
    }
    return () => { };
  }, []);

  React.useEffect(() => {
    mapResponse();
  }, [props]);
  const mapResponse = useCallback(() => {
    if (props.getbyIdData && props.getbyIdData[0]) {
      Object.keys(props.getbyIdData[0]).forEach((key) => {
        goalRainyDayObj[key] = props.getbyIdData[0][key];
      });
      //zeeshan
      goalRainyDayObj.monthsWithoutIncome = Number(
        goalRainyDayObj.monthsWithoutIncome
      );

      setgoalRainyDayObj((prevState) => {
        return {
          ...prevState,
          goalRainyDayObj,
          //
        };
      });

      // setgoalOthersObj(goalOthersObj)
    }
  }, [props.getbyIdData]);

  const isValidate = () => {
    let isValid = true;
    if (activeStep === 0) {
      if (!goalRainyDayObj.averageMonthlySpending) {
        isValid = false;
        showError("Monthly Spending can't be empty");
      }
      if (!goalRainyDayObj.priorSavingAmount) {
        isValid = false;
        showError("Saving Amount can't be empty");
      }
    }
    if (activeStep === 1) {
      if (!goalRainyDayObj.name) {
        isValid = false;
        showError("Goal Name can't be empty");
      }
      if (!goalRainyDayObj.goalAmount) {
        isValid = false;
        showError("Goal Amount can't be empty");
      }
      if (!goalRainyDayObj.plannedDate) {
        isValid = false;
        showError("Plan Date can't be empty");
      }
      if (!goalRainyDayObj.monthlyContribution) {
        isValid = false;
        showError("Monthly Contribution can't be empty");
      }
    }

    return isValid;
  };
  return (
    <div className="addgoalhome add-rain">
      <form className="">
        <React.Fragment>
          {activeStep === steps.length ? (
            <div className="mainDiv">
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div className={classes.instructions}>
              {activeStep === 0 ? (
                <div className="firststep">
                  <GridContainer>
                    <GridItem lg={12} sm={12} xs={12} md={12}>
                      <div className="css-q4good">
                        <label
                          className="cat-title"
                          style={{
                            borderBottom: "1px solid #ddd",
                            paddingBottom: "10px",
                            fontWeight: "700",
                            color: "#191919",
                          }}
                        >
                          <img
                            height="60"
                            src={rainyday}
                            width="50"
                            alt={"Save for rainy day"}
                          />
                          <div
                            style={{ alignSelf: "center", marginLeft: "15px" }}
                          >
                            Save for a rainy day
                          </div>
                        </label>
                        <h1 className="css-1tu684v">
                          Tell us about your expenses
                        </h1>
                        <div className="css-1yjvs5a">
                          <div className="sc-oefIU cAmEDQ">
                            <div className="sc-hhIhEF fsTaMx">
                              <div className="sc-oefIU ecNPTU">
                                <div className="sc-hhIhEF enPTrg">
                                  <GridItem lg={12} sm={12} xs={12} md={12}>
                                    <NQInputText
                                      caption="Average Monthly Spending"
                                      id="outlined-textarea"
                                      value={
                                        goalRainyDayObj.averageMonthlySpending
                                      }
                                      variant="outlined"
                                      label="Average Monthly Spending"
                                      required
                                      name="averageMonthlySpending"
                                      onChange={handleChange}
                                      type="number"
                                      size="medium"
                                    />
                                  </GridItem>
                                  <GridItem lg={12} sm={12} xs={12} md={12}>
                                    <NQInputText
                                      caption="Current Saving Amount"
                                      id="outlined-number"
                                      value={goalRainyDayObj.priorSavingAmount}
                                      variant="outlined"
                                      label="Current Saving Amount"
                                      type="number"
                                      required
                                      name="priorSavingAmount"
                                      onChange={handleChange}
                                    />
                                  </GridItem>
                                  <GridItem lg={12} sm={12} xs={12} md={12}>
                                    <div className="text-center">
                                      How many months without income do you want
                                      <br></br> to be prepared for?
                                    </div>
                                    <Slider
                                      onChange={(x,newValue) => {
                                        setgoalRainyDayObj((prevState) => ({
                                          ...prevState,
                                          monthsWithoutIncome: newValue 
                                        }));
                                        goalRainyDayObj[
                                          "monthsWithoutIncome"
                                        ] = x.target
                                            ? x.target.newValue
                                            : 0; 
                                      }}
                                      style={{ marginTop: "35px" }}
                                      step={1}
                                      min={2}
                                      max={36}
                                      valueLabelDisplay="on"
                                      name="monthsWithoutIncome"
                                      aria-label="pretto slider"
                                      value={
                                        goalRainyDayObj?.monthsWithoutIncome
                                      }
                                      ref={monthsWithoutIncome}
                                    // value={
                                    //   goalRainyDayObj?.monthsWithoutIncome
                                    // }
                                    />
                                  </GridItem>
                                </div>
                              </div>
                            </div>
                            <div className="sc-oefIU cAmEDQ">
                              <div className="sc-hhIhEF fsTaMx">
                                <div className="css-1ucoqeb">
                                  <div className="sc-oefIU ecNPTU">
                                    <div className="sc-hhIhEF enPTrg">
                                      <h4
                                        style={{
                                          color: "black",
                                          fontWeight: "500",
                                        }}
                                      >
                                        How much do you need?
                                      </h4>
                                      <p className="css-xi606m">
                                        Based on your expenses we recommend you
                                        set a goal of
                                        <b
                                          style={{
                                            fontWeight: "600",
                                            marginLeft: 5,
                                            marginRight: 5,
                                          }}
                                        >
                                          {currencyFormat(
                                            goalRainyDayObj.averageMonthlySpending *
                                            goalRainyDayObj.monthsWithoutIncome -
                                            goalRainyDayObj.priorSavingAmount
                                          )}
                                        </b>
                                        to save the money you will need for an
                                        Emergency Fund
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="css-dvxtzn">
                          <button
                            onClick={handleNext}
                            type="submit"
                            className="css-jhd49u"
                          >
                            Next
                          </button>
                          <button
                            onClick={() => navigateTo()}
                            type="button"
                            className="css-1gld5x9"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              ) : (
                <div className="nextstep">
                  <GridContainer>
                    <GridItem lg={12} sm={12} xs={12} md={12}>
                      <div className="css-q4good">
                        <h1 className="css-1tu684v">Your Goal/Plan</h1>
                        <div>
                          <div className="css-1yjvs5a next">
                            <div className="sc-oefIU cAmEDQ">
                              <div className="sc-hhIhEF fsTaMx">
                                <div className="sc-oefIU ecNPTU">
                                  <div className="sc-hhIhEF enPTrg">
                                    <GridItem lg={12} sm={12} xs={12} md={12}>
                                      <NQInputText
                                        caption="Your Goal Name"
                                        id="outlined-textarea"
                                        value={goalRainyDayObj.name}
                                        variant="outlined"
                                        label="Your Goal Name"
                                        required
                                        name="name"
                                        type="text"
                                        size="medium"
                                        onChange={handleChange}
                                      />
                                    </GridItem>
                                    <GridItem lg={12} sm={12} xs={12} md={12}>
                                      <NQInputText
                                        caption="Your Goal Amount"
                                        id="outlined-number"
                                        value={goalRainyDayObj.goalAmount}
                                        variant="outlined"
                                        label="Your Goal Amount"
                                        type="number"
                                        required
                                        name="goalAmount"
                                        onChange={handleChange}
                                      />
                                    </GridItem>
                                    <GridItem lg={12} sm={12} xs={12} md={12}>
                                      <NQDateInput
                                        caption="Planned Date"
                                        id="outlined-number"
                                        value={goalRainyDayObj.plannedDate}
                                        variant="outlined"
                                        label="Planned Date"
                                        required
                                        name="plannedDate"
                                        onChange={handleChange}
                                      />
                                    </GridItem>
                                    <GridItem lg={12} sm={12} xs={12} md={12}>
                                      <NQInputText
                                        caption="Your Monthly Contribution"
                                        id="outlined-number"
                                        value={
                                          goalRainyDayObj.monthlyContribution
                                        }
                                        variant="outlined"
                                        label="Your Monthly Contribution"
                                        required
                                        name="monthlyContribution"
                                        type="number"
                                        onChange={handleChange}
                                      />
                                    </GridItem>
                                  </div>
                                </div>
                              </div>
                              <div className="sc-oefIU cAmEDQ">
                                <div className="sc-hhIhEF fsTaMx">
                                  <div className="css-1ucoqeb">
                                    <div className="sc-oefIU ecNPTU">
                                      <div className="sc-hhIhEF enPTrg">
                                        <h4
                                          style={{
                                            color: "black",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Predicted Date:
                                        </h4>
                                        <p className="css-xi606m">
                                          Nice! By saving
                                          <strong
                                            style={{
                                              fontWeight: "700",
                                              marginLeft: 5,
                                              marginRight: 5,
                                            }}
                                          >
                                            {currencyFormat(
                                              goalRainyDayObj.monthlyContribution
                                            )}
                                          </strong>
                                          per month, we project that you will
                                          reach your goal by your planned date
                                          of
                                          <strong
                                            style={{
                                              fontWeight: "700",
                                              marginLeft: 5,
                                              marginRight: 5,
                                            }}
                                          >
                                            {textDateFormatter(
                                              goalRainyDayObj.plannedDate
                                            )}
                                          </strong>
                                          .<br></br>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="css-dvxtzn">
                            <button
                              onClick={handleSubmit}
                              type="button"
                              className="css-jhd49u"
                            >
                              Save
                            </button>
                            <button
                              disabled={activeStep === 0}
                              type="button"
                              onClick={handleBack}
                              className="css-1gld5x9"
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getbyIdData: state.goalsReducer.getbyIdData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddRainyDayGoal: (rainyobject, history) =>
      dispatch(AddRainyDayGoal(rainyobject, history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRainyDay);
