import axios from "axios";
import { HomeAPICalls } from "constant/APICalls";
import { SHOW_LOADER } from "../types";

export const getRecentTransaction = () => async (dispatch) => {
  const url = `${HomeAPICalls.controller}/${HomeAPICalls.TransactionByPortfolio}`;
  const env = JSON.parse(await localStorage.getItem("env"));
  dispatch(toggleLoading(true));
  return transactionList(env.apiUrl, url)
};

function transactionList(baseURL, url) {
  return axios.get(`${baseURL}${url}`);
}
function toggleLoading(status) {
  return { type: SHOW_LOADER, payload: status };
}

export const loading = (status) => async (dispatch) => 
{

    dispatch(toggleLoading(status));
};
