import axios from "axios";
import { ByMembership, Membership } from "constant/APICalls";
import { API_ERROR, ByMembershipType, MemberType, SHOW_LOADER } from "../types";

export const getMemberType = () => async (dispatch) => {
  const url = `${Membership.controller}/${Membership.action}`;
  const env = JSON.parse(await localStorage.getItem("env"));
  dispatch(toggleLoading(true));
  memberType(env.apiUrl, url)
    .then((o) => {
      dispatch({
        type: MemberType,
        payload: o.data.data,
      });
      dispatch(toggleLoading(false));
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: [],
      });
      dispatch(toggleLoading(false));
    });
};

function memberType(baseURL, url) {
  return axios.get(`${baseURL}${url}`);
}
function toggleLoading(status) {
  return { type: SHOW_LOADER, payload: status };
}

export const getByMembership = () => async (dispatch) => {
  const workspaceKey = localStorage.getItem("workspaceKey");
  const url = `${ByMembership.controller}/${ByMembership.action}/${workspaceKey}`;
  const env = JSON.parse(await localStorage.getItem("env"));
  dispatch(toggleLoading(true));
  memberType(env.apiUrl, url)
    .then((o) => {
      dispatch({
        type: ByMembershipType,
        payload: o.data.data,
      });
      dispatch(toggleLoading(false));
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: [],
      });
      dispatch(toggleLoading(false));
    });
};