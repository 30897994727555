import GridItem from "components/Grid/GridItem";
import "./Contribution.scss";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import GridContainer from './../../../components/Grid/GridContainer';
import { connect } from 'react-redux';
import { getMemberType } from './../../../store/action/memberTypeAction';
import { getByMembership } from 'store/action/memberTypeAction';
import axios from "axios";
import Loading from './../../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import {ByContribution } from "constant/APICalls";
import { b64toBlob } from "../../../utils/helper";
import IconCopy from "Icons/iconCopy";
import { showSuccess } from "utils/NQToaster";

const Contribution = (props) => {
  const [contribution,Setcontribution]= useState([]);
  const clientList = useSelector((state) => state.factFindReducer.ClientRequestList) || [];
  const [downloadingForm, setDownloadingForm] = useState(false);
  const [downloadingCS, setDownloadingCS] = useState(false);
  const [loading , SetLoading] = useState(false);

  useEffect(() => {
    props.memberType();
    props.ByMembershipType();
  }, []);
  useEffect(() => {
    if(props.byMembershipTypeDataObj !== null){
      ContributionData(props.byMembershipTypeDataObj[0]?.key);
    }   
  },[props.byMembershipTypeDataObj])

  const ContributionData = async (NuclesKey) => {
  SetLoading(true);
  const workspaceKey = localStorage.getItem("workspaceKey");
  const url = `${ByContribution.controller}/${NuclesKey}/${ByContribution.action}/${workspaceKey}`;
  const env = JSON.parse(localStorage.getItem("env"));
  axios.get(`${env.apiUrl}${url}`).then(o => {
   Setcontribution(o.data.data);
   SetLoading(false);
  }).catch(err => {
    SetLoading(false);
  })
}

const copyText = async (text) =>{
  navigator.clipboard.writeText(text);
  showSuccess("Copied successfully.");
}

  const downloadSuperChoiceForm = async () => {
    const env = JSON.parse(localStorage.getItem("env"));
    const workspaceKey = localStorage.getItem("workspaceKey");
    const clientKey = clientList[0] && clientList[0].key;

    if (!downloadingForm && clientKey && workspaceKey) {
      setDownloadingForm(true);
      const url = `${env.apiUrl}ClientOnboarding/${clientKey}/SuperChoiceDownload/${workspaceKey}`;

      try {
        const response = await axios.get(url);
        const fileBlob = b64toBlob(response.data.data, 'application/pdf');
        const fileUrl = window.URL.createObjectURL(fileBlob);
        const tab = window.open();
        tab.location.href = fileUrl;
      } catch (err) {
        // pass
      } finally {
        setDownloadingForm(false);
      }
    }
  };

  const downloadComplianceStatement = async () => {
    const env = JSON.parse(localStorage.getItem("env"));
    const workspaceKey = localStorage.getItem("workspaceKey");
    const clientKey = clientList[0] && clientList[0].key;

    if (!downloadingCS && clientKey && workspaceKey) {
      setDownloadingCS(true);
      const url = `${env.apiUrl}ClientOnboarding/CompilanceStatementDownload/${workspaceKey}`;

      try {
        const response = await axios.get(url);
        const fileBlob = b64toBlob(response.data.data, 'application/pdf');
        const fileUrl = window.URL.createObjectURL(fileBlob);
        const tab = window.open();
        tab.location.href = fileUrl;
      } catch (err) {
        // pass
      } finally {
        setDownloadingCS(false);
      }
    }
  };


  return (
    <div className="">
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{ display: "flex", justifyContent: "space-between", }}>
            <div className="css-10ym0te">
              <p className="css-p">Contribution</p>
            </div>
          </div>
        </GridItem>

        {loading ? 
            <GridItem lg={12} sm={12} xs={12} md={12}>
            <div className="flex-container">
            <div className="flex-items loader">Retrieving your data... <span>🔍</span></div>
            </div>
            </GridItem> : null}

        { contribution!=null && contribution.filter(x=> x.name == "BPAYReferenceNumber").length > 0 ? 
        <GridItem lg={12} sm={12} xs={12} md={12}>
          <div className="css-1ulh703">
            <div className="css-18akwle">
              <p className="css-p">BPay Details</p>
              {/* <a className="css-phtnxg">Edit</a> */}
            </div>
            <div className="css-vy2pbh">
              <div className="css-xqrdoy">
                <div>
                <div className="css-1qm1lh">
                    <p className="css-fqk8rb">Biller Name</p>
                    <span className="css-1crpfoq">
                     AMG Super
                    </span>
                  </div>
                  <div className="css-1qm1lh">
                    <p className="css-fqk8rb">Biller Code</p>
                    <span className="css-1crpfoq">
                       745976
                    </span>
                    <span className="text-align-middle ml-3 pointer" onClick={() => {copyText("745976")}}>
                    <IconCopy/>
                    </span>
                  </div>
                  <div className="css-1qm1lh">
                    <p className="css-fqk8rb">Reference Number</p>
                    <span className="css-1crpfoq">
                      {
                      contribution[0]?.value ?
                      contribution.filter(a => a.name === 'BPAYReferenceNumber')[0]?.value || '--'
                        : "--"}
                    </span>
                    { contribution.filter(a => a.name === 'BPAYReferenceNumber')[0]?.value ?
                    <span className="text-align-middle ml-3 pointer" onClick={() => {copyText(contribution.filter(a => a.name === 'BPAYReferenceNumber')[0]?.value)}}>
                    <IconCopy/>
                    </span>
                    : null
                    }
                  </div>

                  <div className="css-1qm1lh">
                    <p className="css-fqk8rb">Only member voluntary contributions can be processed via BPay. 
                    For other contribution types (including Employer Contributions), you will need to use <a href="https://www.ato.gov.au/Super/SuperStream/" target="_blank"> SuperStream</a>.<br></br> 
                    Please contact your Financial Adviser should you require more information.</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </GridItem> : null}
        { contribution!=null && contribution.filter(x=> x.name == "NQSPMemberNo").length > 0 ? 
        <GridItem lg={12} sm={12} xs={12} md={12}>
          <div className="css-1ulh703">
            <div className="css-18akwle">
              <p className="css-p">EFT Details</p>
              {/* <a className="css-phtnxg">Edit</a> */}
            </div>
            <div className="css-vy2pbh">
              <div className="css-xqrdoy">
                <div>

                  <div className="css-0">
                    <p className="css-fqk8rb">Bank</p>
                    <span className="css-1crpfoq">
                    Bank of Queensland
                    </span>
                  </div>
                  <div className="css-0">
                    <p className="css-fqk8rb">BSB Number</p>
                    <span className="css-1crpfoq">
                    124001
                    </span>
                  </div>
                  <div className="css-0">
                    <p className="css-fqk8rb">Account No</p>
                    <span className="css-1crpfoq">
                    11010139
                    </span>
                  </div>
                  <div className="css-0">
                    <p className="css-fqk8rb">Reference</p>
                    <span className="css-1crpfoq">
                    {
                      contribution[0]?.value ?
                      contribution.filter(a => a.name === 'NQSPMemberNo')[0]?.value || '--'
                        : "--"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GridItem>  : null}
        <GridItem lg={12} sm={12} xs={12} md={12}>
          <div className="css-1ulh703">
            <div className="css-18akwle">
              <p className="css-p">Employer Contributions</p>
              {/* <a className="css-phtnxg">Edit</a> */}
            </div>
            <div className="css-vy2pbh">
              <div className="css-xqrdoy">
                <div>
                  <div className="css-1qm1lh">
                    <p className="css-fqk8rb">
                      If you'd like your employer to pay your super contributions to NQ Super,
                      please complete this form and provide it to your payroll office.  
                    </p>
                  </div>
                  <Divider />
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <p>Super Choice Form</p>
                    <Button
                      color="default"
                      startIcon={<GetAppIcon />}
                      onClick={downloadSuperChoiceForm}
                      disabled={downloadingForm}
                    >
                      {downloadingForm ? 'Downloading' : 'Download' }
                    </Button>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <p>Compliance Statement</p>
                    <Button
                      color="default"
                      startIcon={<GetAppIcon />}
                      onClick={downloadComplianceStatement}
                      disabled={downloadingCS}
                    >
                      {downloadingCS ? 'Downloading' : 'Download' }
                    </Button>
                  </Grid>
                  <Divider />
                </div>
              </div>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );

};

const mapStateToProps = (state) => {
  return {
    memberTypeDataObj: state.memberTypeReducer.memberTypeDataObj,
    byMembershipTypeDataObj: state.memberTypeReducer.byMembershipTypeDataObj,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    memberType: () => dispatch(getMemberType()),
    ByMembershipType: () => dispatch(getByMembership()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withAuthenticationRequired(Contribution, {
    onRedirecting: () => <Loading />,
  })
);
