import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import NQDateInput from "components/Date/NQDateInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { FactFindGoalInvestmentStyles } from "Enums/fact-find-goal-investment-style";
import { FactFindGoalTypeEnum } from "Enums/fact-find-goal-type";
import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AddHomeGoal, EditGoal, getById } from "store/action/goalsAction";
import { currencyFormat, NQDateInputMonthFormatter, textDateFormatter } from "utils/formatter";
import { NPER, PMT, XNPER, XPMT, XPV } from "utils/helper";
import { showError } from "utils/NQToaster";
import houes from "../../../assets/icon/houes.svg";
import NQInputText from "../../../components/TextField/NQ-input-text";
import { goalHome } from "../../../constant/GoalType";
import "./AddHome.scss";

const AddHome = (props) => {
  const [dropdown, setDropdown] = useState(false);
  const [homeCost, sethomeCost] = useState(0);
  const [repayment, setrepayment] = useState(0);
  const [goalAmount, setgoalAmount] = useState(0);
  const [investmentStyleId] = useState(2);
  const [debtToIncomeRatios] = useState([33, 28]);
  const [selectedGoalType] = useState(FactFindGoalTypeEnum.Home);
  const [monthlyContirbutionCalulation, setmonthlyContirbutionCalulation] = useState(false);
  const [monthlyContirbutionValue, setmonthlyContirbutionValue] = useState();
  const dispatch = useDispatch();
  const [debtToIncomeRatiosSelector, setdebtToIncomeRatiosSelector] = useState(1);
  const useStyles = makeStyles((theme) => ({
    root: { width: "100%" },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [goalHomeObj, setGoalHomeObj] = useState(goalHome);
  const history = useHistory();
  const classes = useStyles();

  const handleChange = (e, x) => {
    setGoalHomeObj({
      ...goalHomeObj,
      [typeof e.target !== "undefined"
        ? [e.target.name]
        : "plannedDate"]: e.target ? e.target.value : x,
    });

    goalHomeObj[e.target.name] = e.target.value ? e.target.value : e.target;

    if (e.target.name !== "name") {
      if (monthlyContirbutionValue > Number(goalHomeObj.monthlyContribution)) {

        setmonthlyContirbutionCalulation(true)
        step3Calculation()
      }

      else {
        if (goalAmount < Number(goalHomeObj.monthlyContribution)) {
        //  showError("Monthly Contribution must be less than or equal to goal amount.");
        }
        setmonthlyContirbutionCalulation(false)
      }
      step1HomeCalculation();
    };
  }

  const recalcMonths = () => {
    const goalAmount = Number(goalHomeObj.goalAmount);
    const monthlyContribution = Number(goalHomeObj.monthlyContribution);
    const returnRate = FactFindGoalInvestmentStyles[investmentStyleId].value;
    const interestRate = Number(goalHomeObj.interestRate) / 100;
    const priorSavingAmount = isValidNumber(
      Number(goalHomeObj.priorSavingAmount)
    )
      ? Number(goalHomeObj.priorSavingAmount)
      : 0;
    const outstandingBalance = Number(goalHomeObj.outstandingBalance);

    let calcMonths = null;

    if (selectedGoalType == FactFindGoalTypeEnum.RainyDay) {
      calcMonths = RoundUpToInteger(goalAmount / monthlyContribution);
    } else if (selectedGoalType == FactFindGoalTypeEnum.Retirement) {
      calcMonths = XNPER(
        returnRate / 12,
        -priorSavingAmount,
        goalAmount,
        -monthlyContribution
      );
      calcMonths = RoundUpToInteger(calcMonths);
    } else if (selectedGoalType == FactFindGoalTypeEnum.Home) {
      calcMonths = RoundUpToInteger(goalAmount / monthlyContribution);
    } else if (selectedGoalType == FactFindGoalTypeEnum.Other) {
      calcMonths = RoundUpToInteger(goalAmount / monthlyContribution);
    } else if (selectedGoalType == FactFindGoalTypeEnum.CrushDebt) {
      calcMonths = NPER(
        interestRate / 12,
        -monthlyContribution,
        outstandingBalance,
        0,
        0
      );
      calcMonths = RoundUpToInteger(calcMonths);
    }

    return calcMonths;
  };

  const changePlannedDate = (event) => {
    var monthControl = document.querySelector('input[type="month"]');
    setGoalHomeObj((state) => {
      return {
        ...state,
        plannedDate: NQDateInputMonthFormatter(monthControl.value),
      };
    });
  };

  const onDebtToIncomeRatioChange = () => {
    const debtToIncomeRatio = goalHomeObj.debtToIncomeRatio;

    debtToIncomeRatios[debtToIncomeRatiosSelector] = debtToIncomeRatio;

    step1HomeCalculation();
  };

  const onDebtToIncomeRatiosChanged = (index) => {
    setdebtToIncomeRatiosSelector(index);
    goalHomeObj.debtToIncomeRatio = debtToIncomeRatios[index];
    step1HomeCalculation();
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (isValidate()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      step1HomeCalculation();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    step1HomeCalculation();
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const navigateTo = () => {
    if (history.location && history.location.key) {
      history.push("/admin/Goal");
    } else {
      history.push("/admin/addgoalbytype");
    }
  };

  const handleSave = async () => {
    if (isValidate()) {
      let factFindOwner = [];
      if (props.location.factFindGoalHomeKey) {
        goalHomeObj.goalType = "Home";
        factFindOwner.push({
          ownerKey: localStorage.getItem("contactKey"),
          percentage: 100,
        });
        goalHomeObj.factFindOwner = factFindOwner;
        goalHomeObj.plannedDate = new Date(goalHomeObj.plannedDate);
        goalHomeObj.ContactKey = localStorage.getItem("contactKey");
        goalHomeObj.NucleusKey = localStorage.getItem("contactKey");
        await dispatch(
          EditGoal(
            props.location.key,
            props.location.factFindGoalHomeKey,
            "GoalHome",
            goalHomeObj,
            history
          )
        );
      } else {
        goalHomeObj.plannedDate = new Date(goalHomeObj.plannedDate);
        goalHomeObj.goalType = "Home";
        goalHomeObj.status = 0;
        goalHomeObj.isComplete = false;
        goalHomeObj.priorSavingAmount = goalHomeObj.priorSavingAmount
          ? goalHomeObj.priorSavingAmount
          : 0;
        goalHomeObj.ContactKey = localStorage.getItem("contactKey");
        goalHomeObj.NucleusKey = localStorage.getItem("contactKey");  
        await dispatch(AddHomeGoal(goalHomeObj));
        history.push("/admin/Goal");
      }
    }
  };

  function getSteps() {
    return ["Detail", "Summary"];
  }

  const [alignment, setAlignment] = React.useState("right");

  const handleChangeT = async (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  useEffect(() => {
    setDropdown(false);

    if (props.location.key) {
      dispatch(getById(props.location.key, "Goals"));
    } else {
      setGoalHomeObj({});

      setGoalHomeObj({
        ...goalHome,
      });
    }
    setGoalHomeObj((prevState) => ({
      ...prevState,     
      debtToIncomeRatio : debtToIncomeRatios[1]
    }));
    // goalHomeObj.debtToIncomeRatio = debtToIncomeRatios[1];

    return () => { };
  }, []);

  useEffect(() => {
    step1HomeCalculation();
  },[goalHomeObj.debtToIncomeRatio])
  React.useEffect(() => {
    mapResponse();
    return () => {
    };
  }, [props]);

  const mapResponse = useCallback(() => {
    if (props.getbyIdData && props.getbyIdData[0]) {
      Object.keys(props.getbyIdData[0]).forEach((key) => {
        goalHomeObj[key] = props.getbyIdData[0][key];
        setGoalHomeObj((prevState) => {
          return {
            ...prevState,
            [key]: props.getbyIdData[0][key],
          };
        });
      });
    }
  }, [props.getbyIdData]);


  //calculation
  const getMonthDifference = (date1, date2) => {
    let months = 0;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months <= 0 ? 0 : months;
  }

  const RoundUpToInteger = (value) => {
    return Number.isInteger(Number(value)) ? Number(value) : Math.trunc(Number(value) + 1);

  }

  const isValidValue = (T) => {
    if (T == null || T == undefined)
      return false;
    return true;
  }

  const isValidNumber = (T) => {
    if (isValidValue(T))
      if (Number(T))
        return true;
    return false;
  }

  var calcMonthlyContribution = 0;

  const step3Calculation = () => {

    const monthlyContribution = Number(goalHomeObj.monthlyContribution);
    const plannedDate = goalHomeObj.plannedDate;
    const monthDifference = getMonthDifference(new Date(), new Date(plannedDate));
    let calcMonths = recalcMonths();
    calcMonths = RoundUpToInteger(calcMonths);
    const unplannedMonths = Math.round(monthDifference - calcMonths);
    if (monthlyContribution >= 1 && unplannedMonths == 0) {
      goalHomeObj.plannedDate = plannedDate;
      goalHomeObj.monthlyContribution = monthlyContribution;
    }
    else if (monthlyContribution >= 1 && unplannedMonths > 0) {
      goalHomeObj.plannedDate = plannedDate;
      goalHomeObj.monthlyContribution = monthlyContribution;
    }
    else if (monthlyContribution >= 1 && unplannedMonths < 0) {

      //re-calc contribution 
      calcMonthlyContribution = recalcMonthlyContribution();;
      goalHomeObj.monthlyContribution = calcMonthlyContribution;

      //re-calc date
      let calcDate = new Date();

      calcDate.setMonth(calcDate.getMonth() + calcMonths);
      goalHomeObj.monthlyContribution = calcDate;

    }
    else {
      goalHomeObj.plannedDate = null;
      goalHomeObj.monthlyContribution = null;
      // this.projectedDateText = null;
    }
  }
  //calculation


  const isValidate = () => {
    let isValid = true;
    if (activeStep === 0) {
      if (!goalHomeObj.annualIncome) {
        isValid = false;
        showError("Annual Income can't be empty");
      }
    }
    if (activeStep === 1) {
      if (goalHomeObj.monthlyContribution && goalAmount < Number(goalHomeObj.monthlyContribution)) {
        isValid = false;
        showError("Monthly Contribution must be less than or equal to goal amount.");
      }
      if (!goalHomeObj.name) {
        isValid = false;
        showError("Goal Name can't be empty");
      }
    }

    return isValid;
  };

  const step1HomeCalculation = () => {
    //get the values

    let annualIncome = goalHomeObj.annualIncome;
    let mortgageRate = goalHomeObj.mortgageRate / 100;
    let percentDownPayment = goalHomeObj.percentDownPayment / 100;
    let debtToIncomeRatio = goalHomeObj.debtToIncomeRatio / 100;
    let priorSavingAmount = isValidNumber(goalHomeObj.priorSavingAmount)
      ? Number(goalHomeObj.priorSavingAmount)
      : 0;

    //calculation
    let repay = (annualIncome / 12) * debtToIncomeRatio;
    setrepayment(repay);

    let loanAmount = XPV(mortgageRate / 12, 30 * 12, -1 * repay);
    let home = loanAmount / (1 - percentDownPayment);
    sethomeCost(home);
    let goal = RoundUpToInteger(home * percentDownPayment - priorSavingAmount);
    setgoalAmount(goal);

    goalHomeObj.goalAmount = goal;
    goalHomeObj.plannedDate = new Date();
    let nextDate = new Date(goalHomeObj.plannedDate).setMonth(
      new Date(goalHomeObj.plannedDate).getMonth() + 12
    );
    goalHomeObj.plannedDate = nextDate;
    setGoalHomeObj((state) => {
      return {
        ...state,
        plannedDate: NQDateInputMonthFormatter(goalHomeObj.plannedDate),
      };
    });
    let val = recalcMonthlyContribution(goal);
    setmonthlyContirbutionValue(val);
    goalHomeObj.monthlyContribution = val;
  };

  const recalcMonthlyContribution = (goalAmount) => {
    let monthlyContirbution = 0;
    const plannedDate = goalHomeObj.plannedDate;
    const monthDifference = getMonthDifference(
      new Date(),
      new Date(plannedDate)
    );
    const priorSavingAmount = isValidNumber(
      Number(goalHomeObj.priorSavingAmount)
    )
      ? Number(goalHomeObj.priorSavingAmount)
      : 0;
    const returnRate = FactFindGoalInvestmentStyles[investmentStyleId].value;
    const interestRate = Number(goalHomeObj.interestRate) / 100;
    const outstandingBalance = Number(goalHomeObj.outstandingBalance);

    if (selectedGoalType == FactFindGoalTypeEnum.RainyDay) {
      monthlyContirbution = RoundUpToInteger(goalAmount / monthDifference);
    } else if (selectedGoalType == FactFindGoalTypeEnum.Retirement) {
      monthlyContirbution = -XPMT(
        returnRate / 12,
        monthDifference,
        -priorSavingAmount,
        goalAmount
      );
      monthlyContirbution = RoundUpToInteger(monthlyContirbution);
    } else if (selectedGoalType == FactFindGoalTypeEnum.Home) {
      monthlyContirbution = RoundUpToInteger(goalHomeObj.goalAmount / monthDifference);
    } else if (selectedGoalType == FactFindGoalTypeEnum.Other) {
      monthlyContirbution = RoundUpToInteger(goalHomeObj.goalAmount / monthDifference);
    } else if (selectedGoalType == FactFindGoalTypeEnum.CrushDebt) {
      monthlyContirbution = -PMT(
        interestRate / 12,
        monthDifference,
        outstandingBalance,
        0,
        0
      );
      monthlyContirbution = RoundUpToInteger(monthlyContirbution);
    }

    return monthlyContirbution;
  };
  return (
    <div className="addgoalhome">
      {dropdown == false ? (
        <React.Fragment>
          {activeStep == steps.length ? (
            <div className="mainDiv">
              <Typography component={"span"} className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div className={classes.instructions}>
              {activeStep == 0 ? (
                <div className="firststep">
                  <GridContainer>
                    <GridItem lg={12} sm={12} xs={12} md={12}>
                      <div className="css-q4good">
                        <label
                          className="cat-title"
                          style={{
                            borderBottom: "1px solid #ddd",
                            paddingBottom: "10px",
                            fontWeight: "700",
                            color: "#191919",
                          }}
                        >
                          <img
                            height="60"
                            src={houes}
                            width="50"
                            alt={"Buy a home"}
                          />
                          <div
                            style={{
                              alignSelf: "center",
                              marginLeft: "15px",
                            }}
                          >
                            Buy a home
                          </div>
                        </label>
                        <h1
                          className="css-1tu684v"
                          style={{ marginBottom: "10px" }}
                        >
                          How much can I afford?
                          <ToggleButtonGroup
                            size="small"
                            value={alignment}
                            exclusive
                            onChange={handleChangeT}
                          >
                            <ToggleButton
                              value="left"
                              onClick={() => onDebtToIncomeRatiosChanged(0)}
                            >
                              Aggressive
                            </ToggleButton>
                            <ToggleButton
                              value="right"
                              onClick={() => onDebtToIncomeRatiosChanged(1)}
                            >
                              Conservative
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </h1>

                        <div className="note">
                          <GridItem lg={12} sm={12} xs={12} md={12}>
                            <label className="css-label">
                              You can afford a <b>home that costs</b>
                              &nbsp;{currencyFormat(homeCost)}
                            </label>
                          </GridItem>

                          <GridItem lg={12} sm={12} xs={12} md={12}>
                            <label className="css-label">
                              For a <b>total monthly payment of</b>
                              &nbsp;{currencyFormat(repayment)}
                            </label>
                          </GridItem>

                          <GridItem lg={12} sm={12} xs={12} md={12}>
                            <label className="css-label">
                              You will need a <b>down payment of </b>
                              &nbsp;{currencyFormat(goalAmount)}
                            </label>
                          </GridItem>
                        </div>
                        <div>
                          <div className="css-1yjvs5a">
                            <div className="sc-oefIU cAmEDQ">
                              <div className="sc-hhIhEF fsTaMx">
                                <div className="sc-oefIU ecNPTU">
                                  <div className="sc-hhIhEF enPTrg">
                                    <GridItem lg={12} sm={12} xs={12} md={12}>
                                      <NQInputText
                                        caption="Annual Income"
                                        id="annualincome"
                                        value={goalHomeObj.annualIncome}
                                        variant="outlined"
                                        required={true}
                                        label="Annual Income"
                                        name="annualIncome"
                                        onChange={handleChange}
                                        type="number"
                                        size="medium"
                                      />
                                    </GridItem>
                                    <GridItem lg={12} sm={12} xs={12} md={12}>
                                      <NQInputText
                                        id="outlined-number"
                                        value={goalHomeObj.mortgageRate}
                                        variant="outlined"
                                        label="Mortgage Rate"
                                        type="number"
                                        name="mortgageRate"
                                        onChange={handleChange}
                                        caption="Mortgage Rate"
                                      />
                                    </GridItem>
                                    <GridItem lg={12} sm={12} xs={12} md={12}>
                                      <NQInputText
                                        id="outlined-number"
                                        value={goalHomeObj.percentDownPayment}
                                        variant="outlined"
                                        label="Down Payment"
                                        name="percentDownPayment"
                                        type="number"
                                        caption="Down Payment"
                                        onChange={handleChange}
                                      />
                                    </GridItem>
                                    <GridItem lg={12} sm={12} xs={12} md={12}>
                                      <NQInputText
                                        id="outlined-number"
                                        variant="outlined"
                                        value={goalHomeObj.priorSavingAmount}
                                        required={true}
                                        label="Current Saving for Buying"
                                        name="priorSavingAmount"
                                        caption="Current Saving for buying"
                                        onChange={handleChange}
                                        type="number"
                                      />
                                    </GridItem>
                                    <GridItem lg={12} sm={12} xs={12} md={12}>
                                      <Accordion
                                        className="test"
                                        style={{
                                          marginTop: "5px",
                                          marginBottom: " 5px",
                                        }}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <Typography
                                            component={"span"}
                                            className=""
                                          >
                                            Assumptions
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="Assumptions">
                                          <GridItem
                                            lg={12}
                                            sm={12}
                                            xs={12}
                                            md={12}
                                          >
                                            <NQInputText
                                              style={{
                                                marginLeft: "0%",
                                              }}
                                              onChange={() =>
                                                onDebtToIncomeRatioChange
                                              }
                                              value={
                                                goalHomeObj.debtToIncomeRatio
                                              }
                                              id="outlined-number"
                                              variant="outlined"
                                              label="Debt to Income Ratio"
                                              required={true}
                                              name="debtToIncomeRatio"
                                              type="number"
                                              caption="Debt to Income Ratio"
                                            />
                                          </GridItem>
                                        </AccordionDetails>
                                      </Accordion>
                                    </GridItem>
                                  </div>
                                </div>
                              </div>

                              <GridContainer>
                                <GridItem lg={12} sm={12} xs={12} md={12}>
                                  <div className="sc-oefIU cAmEDQ mobileview">
                                    <div className="sc-hhIhEF fsTaMx">
                                      <div className="css-1ucoqeb">
                                        <div className="sc-oefIU ecNPTU">
                                          <div className="sc-hhIhEF enPTrg">
                                            <p className="css-xi606m">
                                              We recommend you set a goal of
                                              <b
                                                style={{
                                                  marginLeft: 5,
                                                  marginRight: 5,
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {currencyFormat(goalAmount)}
                                              </b>{" "}
                                              to save the money you will
                                              <br></br> need for a down payment
                                              on your home.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </GridItem>
                              </GridContainer>
                            </div>
                          </div>
                          <div className="css-dvxtzn">
                            <button
                              onClick={handleNext}
                              type="submit"
                              className="css-jhd49u"
                            >
                              Next
                            </button>
                            <button
                              onClick={() => navigateTo()}
                              type="button"
                              className="css-1gld5x9"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              ) : (
                <div className="nextstep">
                  <GridContainer>
                    <GridItem lg={12} sm={12} xs={12} md={12}>
                      <div className="css-q4good">
                        <h1 className="css-1tu684v">Your Goal/Plan</h1>
                        <div>
                          <div className="css-1yjvs5a next">
                            <div className="sc-oefIU cAmEDQ">
                              <div className="sc-hhIhEF fsTaMx">
                                <div className="sc-oefIU ecNPTU">
                                  <div className="sc-hhIhEF enPTrg">
                                    <NQInputText
                                      caption="Your Goal Name"
                                      id="outlined-textarea"
                                      value={goalHomeObj.name}
                                      variant="outlined"
                                      label="Your Goal Name"
                                      required={true}
                                      name="name"
                                      type="text"
                                      size="medium"
                                      onChange={handleChange}
                                    />

                                    <NQInputText
                                      caption="Your Goal Amount"
                                      id="outlined-number"
                                      value={goalAmount}
                                      variant="outlined"
                                      label="Your Goal Amount"
                                      required
                                      type="number"
                                      disabled
                                      name="goalAmount"
                                    />
                                    <NQDateInput
                                      caption="Planned Date"
                                      value={goalHomeObj.plannedDate}
                                      //value="2017-09"
                                      variant="outlined"
                                      label="Planned Date"
                                      required
                                      name="plannedDate"
                                      id="plannedDate"
                                      onChange={(event) =>
                                        changePlannedDate(event)
                                      }
                                    />

                                    <NQInputText
                                      caption="Your Monthly Contribution"
                                      id="outlined-number"

                                      value={goalHomeObj.monthlyContribution}
                                      variant="outlined"
                                      label="Your Monthly Contribution"
                                      required
                                      name="monthlyContribution"
                                      type="number"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              {
                                monthlyContirbutionCalulation ?
                                  <div className="claculation-toogle">
                                    <div className="m-b-10">
                                      <ToggleButtonGroup
                                        size="large"
                                        exclusive
                                      >
                                        <ToggleButton
                                          value="left"
                                        >
                                          Increase my <br></br>contribution
                                        </ToggleButton>
                                        <ToggleButton
                                          value="right"
                                        >
                                          Move my planned <br></br> date
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                    </div>
                                    <div className="sc-oefIU cAmEDQ">
                                      <div className="sc-hhIhEF fsTaMx">
                                        <div className="css-1ucoqeb claculation" >
                                          <GridContainer>
                                            <GridItem lg={6} md={6} xs={6} sm={6}  >
                                              <div className="border-r-1">
                                                <div  >
                                                  <label>
                                                    PER MONTH
                                                  </label>
                                                  <p className="m-t-0 m-b-0 claculation-ammount">
                                                    A {currencyFormat(goalHomeObj.monthlyContribution)}

                                                  </p>
                                                </div>
                                                {/* <hr></hr> */}
                                                <div>
                                                  <label>
                                                    PROJECTED
                                                  </label>
                                                  <p className="m-t-0 m-b-0 claculation-ammount">
                                                    Jun-2022
                                                  </p>
                                                </div>
                                              </div>
                                            </GridItem>
                                            <GridItem lg={6} md={6} xs={6} sm={6}>
                                              <div>
                                                <label>
                                                  PER MONTH
                                                </label>
                                                <p className="m-t-0 m-b-0 claculation-ammount">
                                                  A$8,648
                                                </p>
                                              </div>
                                              {/* <hr></hr> */}
                                              <div>
                                                <label>
                                                  PROJECTED
                                                </label>
                                                <p className="m-t-0 m-b-0 claculation-ammount">
                                                  ---
                                                  {/* {calcDate} */}
                                                </p>
                                              </div>
                                            </GridItem>
                                          </GridContainer>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  : ''
                              }



                              <div className="sc-oefIU cAmEDQ">
                                <div className="sc-hhIhEF fsTaMx">
                                  <div className="css-1ucoqeb">
                                    <div className="sc-oefIU ecNPTU">
                                      <div className="sc-hhIhEF enPTrg">
                                        <h4
                                          style={{
                                            color: "black",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Projected Date:
                                        </h4>
                                        <p
                                          className="css-xi606m"
                                          style={{ width: "280.583px" }}
                                        >
                                          Nice! By saving
                                          <strong
                                            style={{
                                              marginLeft: 5,
                                              marginRight: 5,
                                              fontWeight: "600",
                                            }}
                                          >
                                            {currencyFormat(
                                              goalHomeObj.monthlyContribution
                                            )}
                                          </strong>
                                          per month, we project that you will
                                          reach your goal by your planned date
                                          of
                                          <strong
                                            style={{
                                              marginLeft: 5,
                                              marginRight: 5,
                                              fontWeight: "600",
                                            }}
                                          >
                                            {textDateFormatter(
                                              goalHomeObj.plannedDate
                                            )}
                                          </strong>
                                          <br></br>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="css-dvxtzn">
                            <button
                              onClick={handleSave}
                              type="button"
                              className="css-jhd49u"
                            >
                              Save
                            </button>
                            <button
                              disabled={activeStep == 0}
                              type="button"
                              onClick={handleBack}
                              className="css-1gld5x9"
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getbyIdData: state.goalsReducer.getbyIdData,
    response: state.goalsReducer.response,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddHome);
// export default  AddHome;
