import axios from "axios";
import {
  SHOW_LOADER
} from "../types";

export const getEnumbyType = (enumType) => async (dispatch) => {
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}${enumType}`;
  dispatch(toggleLoading(true));
  return axios.get(url)

};

export const getIncomeExpenseFrequency = () => async (dispatch) => {
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}IncomeExpenseFrequency`;
  dispatch(toggleLoading(true));
  return axios.get(url)

};

function toggleLoading(status) {
  return { type: SHOW_LOADER, payload: status };
}
