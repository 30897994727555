import { enableRipple } from '@syncfusion/ej2-base';
import { AccumulationChartComponent, AccumulationDataLabel, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationTooltip, Inject, PieSeries } from '@syncfusion/ej2-react-charts';
import * as React from 'react';
import { convertToInternationalCurrencySystem } from 'utils/formatter';
enableRipple(true);


export default function PotChart(props) {

  const PotTypes = () => {
    let data = [];
    if (props && props.Data && props.Data && props.Data.length > 0) {
      data = props.Data.reduce((r, { categoryType }) => {
        let valu = 0;
        props.Data.filter((o) => o.categoryType === categoryType).forEach(
          (element) => {
            valu += element.currentValue;
          }
        );

        if (!r.some((o) => o.categoryType === categoryType)) {
          r.push({
            label: categoryType,
            value: valu,
            text: `${categoryType} : ${valu}`,
            total: valu
          });
        }
        return r;
      }, []);
    }
    if (data && data.length > 0) {
      data.forEach(element => {
        element.label = `${element.label} ${convertToInternationalCurrencySystem(element.total)}`
      });
    }
    else data = null;
    return data;
  };

  React.useEffect(() => {
    return () => {
    }
  }, [])

  const load = (args) => {
    let selectedTheme = 'Material';
    args.accumulation.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).
      replace(/-dark/i, "Dark");
  }

  const chartId = () => {
    const min = 1;
    const max = 100;
    const rand = min + Math.random() * (max - min);
    return 'pie-chart' + rand
  }

  return (
    <div >
      <div >
        <AccumulationChartComponent height='250px'  enableAnimation={true} key={chartId} tooltip={{ enable: true, format: '${point.x}' }} load={load} legendSettings={{
          visible: false
        }}>
          <Inject services={[AccumulationDataLabel, AccumulationTooltip, PieSeries]} />
          <AccumulationSeriesCollectionDirective>
            <AccumulationSeriesDirective key={chartId} name={props.Caption} dataSource={PotTypes()} xName='label' yName='value' startAngle={60} dataLabel={{
              visible: true, position: 'Outside',
              connectorStyle: { length: '20px', type: 'Line' }, name: 'text',
              showZero: true, enableRotation: true,
            }}>
            </AccumulationSeriesDirective>
          </AccumulationSeriesCollectionDirective>
        </AccumulationChartComponent>
      </div>
    </div>
  )
}
