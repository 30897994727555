import {
  primaryColor,
  primaryBoxShadow,
  blackColor,
  whiteColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react.js";

const menuStyle = (theme) => {
  return {
    mtAuto: {
      marginTop: 'auto'
    },
    appBar: {
      color: blackColor,
      backgroundColor: 'transparent',
      boxShadow: 'none',

      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    appBarTitle: {
      width: 'calc(100% - 72px)',
      textAlign: 'center',
      textTransform: 'none',
    },
    title: {
      fontSize: '0.7rem',
      fontWeight: 600,
      textTransform: 'uppercase',

      [theme.breakpoints.up('sm')]: {
        marginTop: 10,
      }
    },
    menuDrawerPaper: {
      borderRightWidth: 0,
    },
    navBar: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      [theme.breakpoints.up('sm')]: {
        minWidth: '100%',
      },
    },
    navList: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    navListItem: {
      width: '100%',
      display: 'block',

      '& .MuiTouchRipple-child': {
        backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.4)",
      }
    },
    navListItemLink: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: 1.2,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'left',
      padding: '12px 25px',
      color: blackColor,
      boxSizing: 'border-box',
      
      '&:hover,&:focus': {
        color: blackColor,
      },
      
      [theme.breakpoints.up('sm')]: {
        padding: '18px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
      }
    },
    navListItemLinkActive: {
      ...primaryBoxShadow,
      color: whiteColor,
      backgroundColor: primaryColor[0],
      padding: '16px 25px',

      '&:hover,&:focus': {
        color: whiteColor,
      },

      [theme.breakpoints.up('sm')]: {
        padding: '18px 14px',
      },

      '& svg': {
        fill: whiteColor,
      },
    },
    navListItemText: {
      whiteSpace: 'nowrap',
    },
    navListIcon: {
      width: 35,
      height: 35,
      marginRight: 10,

      [theme.breakpoints.up('sm')]: {
        marginRight: 0,
      }
    }
  }
};

export default menuStyle