import { Recent_Transaction, SHOW_LOADER } from "../types";

const initialState = {
  recentTransactionData: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Recent_Transaction:
      return {
        ...state,
        recentTransactionData: action.payload,
      };
    case SHOW_LOADER:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
}
