import * as moment from "moment";

export function monthNames() {
  return [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
}

export function formatDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const dateString =
    year +
    "-" +
    (month > 9 ? "" : "0") +
    month +
    "-" +
    (day > 9 ? "" : "0") +
    day;
  return dateString;
}

export function formatDateTime(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const formatted_date =
    date.getFullYear() +
    "-" +
    (month > 9 ? "" : "0") +
    month +
    "-" +
    (day > 9 ? "" : "0") +
    day +
    "T" +
    (hours > 9 ? "" : "0") +
    hours +
    ":" +
    (minutes > 9 ? "" : "0") +
    minutes +
    ":" +
    (seconds > 9 ? "" : "0") +
    seconds +
    "Z";
  return formatted_date;
}

export function getLocalDate(addDays, includeTime = false) {
  const nowDate = new Date();
  let date;
  if (!includeTime) {
    date = new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      nowDate.getDate() + addDays
    );
  } else {
    date = new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      nowDate.getDate() + addDays,
      nowDate.getHours(),
      nowDate.getMinutes(),
      nowDate.getSeconds()
    );
  }
  return date;
}

export function getUTCDate(addDays, includeTime = false) {
  const nowDate = new Date();
  let date;
  if (!includeTime) {
    date = new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      nowDate.getDate() + addDays
    );
    return new Date(date.toUTCString().substr(0, 25));
  } else {
    date = new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      nowDate.getDate() + addDays,
      nowDate.getHours(),
      nowDate.getMinutes(),
      nowDate.getSeconds()
    );
    return new Date(date.toUTCString().substr(0, 25));
  }
}

export function convertLocalToUtcDate(val) {
  const date = new Date(val);
  const localOffset = date.getTimezoneOffset() * 60000;
  const utcTime = date.getTime() + localOffset;

  date.setTime(utcTime);
  return date;
}

export function convertCronofyAvailabilityViewerLocalDateToUtcDate(val) {
  const date = new Date(val);
  const localOffset =
    (date.getTimezoneOffset() + date.getTimezoneOffset()) * 60000;
  const utcTime = date.getTime() + localOffset;

  date.setTime(utcTime);
  return this.formatDateTime(date);
}

export function convertUtcDateToLocal(val) {
  const date = new Date(val);
  return date;
}

export function convertLocalDate(date) {
  const convertedDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
  return convertedDate;
}

export function getMonthName(month) {
  return this.monthNames[month];
}

export function getMonthDifference(date1, date2) {
  let months = 0;
  months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return months <= 0 ? 0 : months;
}

export function getDatePartOnlyFromUtcDate(value) {
  const date = moment(value);
  return date.add(date.utcOffset() / 60, "hours").toDate();
}
