import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./Request.scss";
import Loading from "components/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useHistory } from "react-router-dom";
import { getRequest } from "store/action/requestAction";
import { DeleteByKey } from "store/action/httpAction";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TableAndChartLoading from "../../components/TableAndChartLoading/TableAndChartLoading";
import { screenType } from "Enums/screenType";
const Request = (props) => {
  const [isLoading,setLoading] = useState(true)
  const [RequestsData, setRequestsData] = useState([])
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    getRequest();
  }, []);

  const history = useHistory();
  const navigateToAddRequest = () => {
    history.push("/admin/AddRequest");
  };

  const cellEditButtonClick = (params) => {
    
    history.push({
      pathname: "/admin/AddRequest",
      key: `${params.key}`,
    });
  };

  const getRequest = () => {
    setLoading(true);
    props.getRequest().then(o => {
      
      setRequestsData(prev => ({ ...prev, Data: o.data.data }))
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setIsError(true);
    });
  }

  const cellDeleteButtonClick = (params) => {
    let workspaceKey = localStorage.getItem("workspaceKey");
    let url = `Requests/${params.key}/${workspaceKey}`;
    props.DeleteByKey(url).then(o => {
      
      getRequest()
    })

  };

  return (
    <div className="Request">
      <div className="action-area">
        <div>
          <label className="title"> Request </label>
        </div>
        <div className="action">
          <button
            onClick={() => navigateToAddRequest()}
            type="button"
            className="NQ-btn NQ-btn-blue"
          >
            Add Request
          </button>
        </div>
      </div>
      <div>
        {RequestsData?.Data && RequestsData?.Data.length == 0 || isLoading ? (
          <TableAndChartLoading isLoad={isLoading} isNoRecord={RequestsData?.Data && RequestsData?.Data.length == 0 ? true : false} isError={isError} screen={screenType.Request} />
        )
        : ( 
                  
        <GridContainer>
        <GridItem lg={12} md={12} xs={12} sm={12}>
          <div className="NQ-web">
            <div className="NQ-card">
              <div className="NQ-card-body">
                <div className="ag-theme-alpine">

                  <div id="no-more-tables">
                    <table id="myTable" className=" table-bordered table-striped table-condensed cf w-100" >
                      <thead className="cf">
                        <tr className="header">
                          <th></th>
                          <th>Type</th>
                          <th className="numeric">Portfolio</th>
                          <th className="numeric">Status</th>
                          <th className="numeric">Adviser Name</th>
                          <th className="numeric">Adviser Firm</th>
                        </tr>
                      </thead>
                      <tbody>


                        {RequestsData?.Data?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td data-title="Action" className="mobile-text action-Icon">
                                {/* { <EditIcon  onClick={() => cellEditButtonClick(item)} />} */}
                                <DeleteIcon onClick={() => cellDeleteButtonClick(item)} />
                              </td>
                              <td data-title="Type" className="mobile-text">{item.requestType}</td>
                              <td data-title="Portfolio" className="mobile-text">{item.clientName}</td>
                              <td data-title="Status" className="mobile-text">{item.status}</td>
                              <td data-title="Adviser Name" className="mobile-text">{item.adviserName}</td>
                              <td data-title="Adviser Firm" className="mobile-text">{item.adviserFirmName}</td>
                            </tr>
                          )
                        })}

                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </GridItem>
      </GridContainer>
          ) 
        }
     </div>
    </div>
  );

};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRequest: () => dispatch(getRequest()),
    DeleteByKey: (url) => dispatch(DeleteByKey(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Request);
