import axios from "axios";
import { API_ERROR, GetAdvice, SHOW_LOADER } from "../types";

export const getAdvice = (isSuperSelected) => async (dispatch) => {
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}FactFindAdvice?showArchived=false&isSuperSelected=${isSuperSelected}`;
  dispatch(toggleLoading(true));
  axios
    .get(url)
    .then((o) => {
      dispatch({
        type: GetAdvice,
        payload: o.data.data,
      });
      dispatch(toggleLoading(false));
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: false,
      });
      dispatch(isError(true))
      dispatch(toggleLoading(false));
    });
};

function toggleLoading(status) {
  return { type: SHOW_LOADER, payload: status };
}

function isError(status) {
  return { type: API_ERROR, payload: status };
}
