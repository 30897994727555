export const FactFindGoalInvestmentStyleEnum = Object.freeze({
  ShortTerm: "ShortTerm",
  Conservative: "Conservative",
  Balanced: "Balanced",
  Growth: "Growth",
  AggressiveGrowth: "AggressiveGrowth",
  MostAggressive: "MostAggressive",
});

export const FactFindGoalInvestmentStyles = [
  {
    tag: FactFindGoalInvestmentStyleEnum.ShortTerm,
    display: "Short-Term",
    value: 0.03,
  },
  {
    tag: FactFindGoalInvestmentStyleEnum.Conservative,
    display: "Conservative",
    value: 0.04,
  },
  {
    tag: FactFindGoalInvestmentStyleEnum.Balanced,
    display: "Balanced",
    value: 0.05,
  },
  {
    tag: FactFindGoalInvestmentStyleEnum.Growth,
    display: "Growth",
    value: 0.07,
  },
  {
    tag: FactFindGoalInvestmentStyleEnum.AggressiveGrowth,
    display: "Aggressive Growth",
    value: 0.09,
  },
  {
    tag: FactFindGoalInvestmentStyleEnum.MostAggressive,
    display: "Most Aggressive",
    value: 0.11,
  },
];
