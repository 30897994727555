export const PotTypes = Object.freeze({
  Unknown: "Unknown",
  Asset: "Asset",
  Liability: "Liability",
  Expense: "Expense",
  Income: "Income",
});

export const EnumTypes = Object.freeze({
  AssetTypes: "AssetTypes",
  LiabilityTypes: "LiabilityTypes",
  FactFindExpenseCategory: "FactFindExpenseCategory",
  FactFindIncomeCategory: "FactFindIncomeCategory",
});


export const IncomeExpenseFrequency = Object.freeze({
  Annually: "Annually",
  BiAnnually: "BiAnnually",
  Quaterly: "Quaterly",
  Monthly: "Monthly",
  Weekly: "Weekly",
  Fortnightly: 'Fortnightly',
  OnceOff: 'OnceOff'
});
