import GridItem from "components/Grid/GridItem";
import "./Documents.scss";
import React, { useEffect, useState } from "react";
import GridContainer from './../../../components/Grid/GridContainer';
import { connect } from 'react-redux';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from './../../../components/Loading';
import axios from "axios";
import FileSaver from "file-saver";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import './Documents.scss';
import DocumentsList from '../Reports/Documents-list';
const Documents = (props) => {


  const [prescribedDocuments, setprescribedDocuments] = useState([]);
  const getPrescribedDocuments = (isSuperSelected) => {
    let env = JSON.parse(localStorage.getItem("env"));
    let workSpaceKey = localStorage.getItem("workspaceKey");
    axios.get(`${env.apiUrl}ApprovedServices/PrescribedDocuments?workspaceKey=${workSpaceKey}&isSuperSelected=${isSuperSelected}`).then(o => {
      setprescribedDocuments(o.data.data);

    }).catch(err => {
    })
  }

  const downloadFile = (item) => {
    let env = JSON.parse(localStorage.getItem("env"));
    let workSpaceKey = localStorage.getItem("workspaceKey");
    axios.get(`${env.apiUrl}ApprovedServices/${item.documentKey}/DownloadFile?workspaceKey=${workSpaceKey}&isInstrument=${item.isInstrument}`, { responseType: 'blob' }).then(o => {

      FileSaver.saveAs(o.data, item.documentName)
    }).catch(err => {
    })
  }

  useEffect(() => {
    getPrescribedDocuments(props.nqSuperSelected)
  }, [])


  return (
    <div className="Document">
      <div className="document-View">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div
              className="css-naxvie "
              style={{
                textAlign: 'center'
              }}
            >
              <div className="css-10ym0te title" style={{ margin: "20px 0" }}>
                <label className="title">Important Documents</label>
              </div>
            </div>
          </GridItem>
          <GridItem lg={12} sm={12} xs={12} md={12}>
            <DocumentsList />
          </GridItem>
        </GridContainer>
      </div>
    </div>

  );

};

const mapStateToProps = (state) => {
  return {
    nqSuperSelected: state.sideBarReducer.nqSuperSelected
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withAuthenticationRequired(Documents, {
    onRedirecting: () => <Loading />,
  })
);
