import { combineReducers } from "redux";
import newsReducer from "./newsReducer";
import recentTransactionReducer from "./recentTransactionReducer";
import latestPerformanceReducer from "./latestPerformanceReducer";
import holdingUnitPriceReducer from "./holdingUnitPriceReducer";
import factFindReducer from "./factFindReducer";
import memberTypeReducer from "./memberTypeReducer";
import goalsReducer from "./goalsReducer";
import portfoliosReducer from "./portfoliosReducer";
import potsReducer from "./potsReducer";
import sideBarReducer from "./sideBarReducer";
import instrumentPerformanceReducer from "./instrumentPerformancesReducer";
import readOnlyReducer from "./readOnlyReducer";
import adviceReducer from "./adviceReducer";
import requestReducer from "./requestReducer";
import httpActionReducer from "./httpActionReducer";

export default combineReducers({
  newsReducer,
  recentTransactionReducer,
  latestPerformanceReducer,
  holdingUnitPriceReducer,
  factFindReducer,
  memberTypeReducer,
  goalsReducer,
  portfoliosReducer,
  potsReducer,
  sideBarReducer,
  instrumentPerformanceReducer,
  readOnlyReducer,
  adviceReducer,
  requestReducer,
  httpActionReducer,
});
