import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import GridItem from "components/Grid/GridItem";
import "./Reports.scss";
import React, { useEffect, useState } from "react";
import Portfoliolist from "../Portfolio/Portfoliolist/Portfoliolist";
import GridContainer from './../../components/Grid/GridContainer';
import { showError } from "utils/NQToaster";
import axios from "axios";
import { showSuccess } from 'utils/NQToaster';

const Reports = (props) => {

  const [reportList, setreportList] = useState([
   { name: "Portfolio Performance Report YTD", value: "PortfolioPerformanceYTD" ,hidden:false },
   { name: "Portfolio Performance Report MTD", value: "PortfolioPerformanceMTD",hidden:false },
   { name: "Portfolio Performance Report (Annualised)", value: "PortfolioPerformance",hidden:false },
   { name: "Portfolio Performance Report 3 Years", value: "PortfolioPerformance3Years",hidden:false },
   { name: "Portfolio Performance Report 5 Years", value: "PortfolioPerformance5Years",hidden:false },
   { name: "Portfolio Valuation Report", value: "PortfolioValuation",hidden:false },
   { name: "Investment Transaction Report", value: "InvestmentTransactions",hidden:false },
   { name: "Cash Transaction Report", value: "CashTransaction",hidden:false },
   { name: "Income Report", value: "Income",hidden:false},
   { name: "Expense Report", value: "Expense",hidden:false},
   { name: "Realised Gains and Losses Report", value: "RealisedGainAndLoss",hidden:false},
   { name: "Unrealised Gains and Losses Report", value: "UnrealisedGainAndLoss",hidden:false}
  ]);
  const [selectedPortfolio, setSelectedPortfolio] = useState('');
  const [reportEnumList, setreportEnumList] = useState([]);

  useEffect(() => {
    selectPortfolio(props.location.selectedPortfolio)
    GetReportList()
    GetReports();
  }, []);

  const GetReports =() => {
    const enviroment = JSON.parse(localStorage.getItem("enviroment"));
    
    if(enviroment  == "prod") {
      reportList.forEach((x)=>{
        if(x.value == "PortfolioValuation" || x.value == "CashTransaction" || x.value == "Income" || x.value == "Expense") {
          x.hidden = false;
        } else {
          x.hidden = true;
        }
      });
    }

    setreportList(reportList);
  }

  const GetReportList = () => {
    const env = JSON.parse(localStorage.getItem("env"));
    const url = `${env.apiUrl}Reports`;
    axios.get(url).then(o => {
      setreportEnumList(o.data);
    })
  }
  
  const downloadReport = (item) =>{
    if(!selectedPortfolio){
      showError("Please Select Portfolio");
    }
    else{
      showSuccess("Report will be generated and sent to your Email");
      let value =  reportEnumList.find((element) => {
        return element.value === item;
      })
      let env = JSON.parse(localStorage.getItem("env"));
      let workSpaceKey = localStorage.getItem("workspaceKey");
      let Reportobj = {
        PortfolioKey : selectedPortfolio,
        Report : value.value
      }
      axios.post(`${env.apiUrl}Reports/Generate/${workSpaceKey}`,Reportobj).then(o => {
      }).catch(err => {
        showError("Error while generating report")
      })
    }
  }

  const selectPortfolio = (value) => {
    setSelectedPortfolio(value);
  }

  return (
    <div className="Home">
      <div className="css-13bzwy9">
        <div className="css-k008qs" style={{ margin: "20px 0" }}>
          <Typography variant="body1">
            To download a Report, begin by selecting a Portfolio from the options below:
          </Typography>
        </div>
      </div>
      <Portfoliolist showPlugin={false} height={'150px'} selectFirstPortolio={!selectedPortfolio} selectedPortfolio={selectedPortfolio} handleReportselection={selectPortfolio}/>
      <div className="Document">
        <div className="document-View">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <div
                className="css-naxvie"
                style={{ display: "flex", justifyContent: "space-between", }}
              >
                <div className="css-10ym0te" style={{ margin: "20px 0" }}>
                  <label className="title">Reports</label>
                </div>
              </div>
            </GridItem>
            <GridItem lg={12} sm={12} xs={12} md={12}>
              <ul className="impDoc">
                {reportList.map((o, i) => {
                  return (
                    <li hidden={o.hidden} onClick={() => downloadReport(o.value)} key={i}>
                      <div className="eUedHw">
                        <button className="bcxYtT" >
                          <div className="fNPVNt">
                            <span className="cnKnPt">
                              {o.name}
                            </span>
                            <div className="eLMudl">
                              <div className="ItfWz">
                                <span className="icon">
                                  <ArrowForwardIcon />
                                </span>
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
};


export default Reports
