import React, { useState } from "react";
import "../TextField/NQ-input-textarea.scss";

export default function NQInputTextArea(props) {
  const [focus, setonFocus] = useState(false);
  const onFocus = () => {
    if (props.value) {
      setonFocus(false);
    } else {
      setonFocus(true);
    }
  };
  const onBlur = () => {
    if (props.value) {
      setonFocus(false);
    } else {
      setonFocus(true);
    }
  };

  return (
    <div
      className={
        focus && props?.required ? (!props?.value ? "isError" : "") : ""
      }
    >
      <div className="NQ-input-textarea">
        <div className="css-79elbk">
          <div className="css-npjedo">
            <textarea
              {...props}
              value={props.value}
              type={props.type ? props.type : "text"}
              onChange={props.onChange}
              name={props.name}
              onFocus={() => onFocus()}
              onBlur={onBlur}
            />
            <label>
              {props.caption}
              {props && props.required ? <span>*</span> : null}
            </label>
          </div>
        </div>

        {focus ? (
          props && props.required ? (
            !props.value ? (
              <span className="errormsg">
                {props.caption} {" is required"}
              </span>
            ) : null
          ) : null
        ) : null}
      </div>
    </div>
  );
}
