import React, { Component } from "react";
import "./modal.scss"
const Modal = ({ handleClose, show, children,heading}) => {
    const showHideClassName =show ? "modal display-block" : "modal display-none"
    return (
        <>
                      <div className={showHideClassName}>

                      <section className="modal-main">
                          <div className="mdactionshead">
                          <h3 className="mdhead">{heading}</h3>
                              <button onClick={handleClose} name="close">X</button>
                          </div>
                          
                          {children}
                         
                      </section>
                  </div>
                   
        </>
    )
}
export default Modal;