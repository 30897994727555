import {
  ClientRequestList,
  Crush_Debt_Type,
  GetAllFactFinds,
  SHOW_LOADER
} from "../types";

const initialState = {
  factFindDataObj: [],
  loading: false,
  ClientRequestList: [],
  Crush_Debt_Type: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GetAllFactFinds:
      return {
        ...state,
        factFindDataObj: action.payload,
      };
    case SHOW_LOADER:
      return { ...state, loading: action.payload };
    case ClientRequestList:
      return { ...state, ClientRequestList: action.payload };

    case Crush_Debt_Type:
      return { ...state, Crush_Debt_Type: action.payload };
    default:
      return state;
  }
}
