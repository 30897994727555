import axios from "axios";
import { AddGoalsAPIsCall, GetGoals } from "constant/APICalls";
import { showError, showSuccess } from "utils/NQToaster";
import {
  AddHomeGoals, AddOtherGoal, AddRainyDayGoals, API_ERROR, getGoalById,
  PutGoals, SHOW_LOADER,GetAllGoals
} from "../types";

export const getGoals = () => async (dispatch) => {
  const FactFindKey = localStorage.getItem("FactFindKey");
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const url = `${GetGoals.controller}/${FactFindKey}/${GetGoals.action}/${WorkspaceKey}`;
  const env = JSON.parse(await localStorage.getItem("env"));
  dispatch(toggleLoading(true));
  axios
    .get(`${env.apiUrl}${url}`)
    .then((o) => {
      dispatch({
        type: GetAllGoals,
        payload: o.data.data,
      });
      dispatch(toggleLoading(false));
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: false,
      });
      dispatch(isError(true))
      dispatch(toggleLoading(false));
    });
};

export const DeleteGoals = (goalKey) => async (dispatch) => {
  const FactFindKey = localStorage.getItem("FactFindKey");
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}/FactFind/${FactFindKey}/Goals/${goalKey}/${WorkspaceKey}`;
  return axios
    .delete(url)
};

export const AddHomeGoal = (goalbject) => async (dispatch) => {
  const FactFindKey = localStorage.getItem("FactFindKey");
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}${AddGoalsAPIsCall.controller}/${FactFindKey}/${AddGoalsAPIsCall.AddGoalHome}/${WorkspaceKey}`;
  dispatch(toggleLoading(true));
  axios
    .post(url, goalbject)
    .then((o) => {
      dispatch({
        type: AddHomeGoals,
        payload: o.data.data,
      });
      showSuccess();
      dispatch(toggleLoading(false));
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: false,
      });
      let msg = "";
      Object.keys(er.response.data).forEach((o) => {
        msg += er.response.data[o];
      });
      showError(msg);

      dispatch(toggleLoading(false));
    });
};

export const AddDebtGoal = (goalbject, history) => async (dispatch) => {
  const FactFindKey = localStorage.getItem("FactFindKey");
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}${AddGoalsAPIsCall.controller}/${FactFindKey}/${AddGoalsAPIsCall.AddGoalDebts}/${WorkspaceKey}`;
  return axios
    .post(url, goalbject)
};

export const AddRainyDayGoal = (rainyDaybject, history) => async (dispatch) => {
  const FactFindKey = localStorage.getItem("FactFindKey");
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}${AddGoalsAPIsCall.controller}/${FactFindKey}/${AddGoalsAPIsCall.AddGoalRainyDays}/${WorkspaceKey}`;
  axios
    .post(url, rainyDaybject)
    .then((o) => {
      dispatch({
        type: AddRainyDayGoals,
        payload: o.data.data,
      });
      showSuccess();
      dispatch(toggleLoading(false));
      history.push("/admin/Goal");
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: false,
      });
      let msg = "";
      Object.keys(er.response.data).forEach((o) => {
        msg += er.response.data[o];
      });
      showError(msg);
      dispatch(toggleLoading(false));
    });
};

export const PostAddOtherGoal = (rainyDaybject, history) => async (
  dispatch
) => {
  const FactFindKey = localStorage.getItem("FactFindKey");
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}${AddGoalsAPIsCall.controller}/${FactFindKey}/${AddGoalsAPIsCall.AddGoalOther}/${WorkspaceKey}`;
  dispatch(toggleLoading(true));
  axios
    .post(url, rainyDaybject)
    .then((o) => {
      dispatch({
        type: AddOtherGoal,
        payload: o.data,
      });
      showSuccess();
      dispatch(toggleLoading(false));
      history.push("/admin/Goal");
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: false,
      });
      let msg = "";

      Object.keys(er.response.data).forEach((o) => {
        msg += er.response.data[o];
      });
      showError(msg);
      dispatch(toggleLoading(false));
    });
};

export const getById = (goalId, goaltype) => async (dispatch) => {
  const FactFindKey = localStorage.getItem("FactFindKey");
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  // const url = `${GetGoals.controller}/${FactFindKey}/${GetGoals.action}/${goalId}/${WorkspaceKey}`;
  const url = `${GetGoals.controller}/${FactFindKey}/${goaltype}/${goalId}/${WorkspaceKey}`;
  const env = JSON.parse(await localStorage.getItem("env"));

  axios
    .get(`${env.apiUrl}${url}`)
    .then((o) => {
      dispatch({
        type: getGoalById,
        payload: o.data.data,
      });
      dispatch(toggleLoading(false));
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: false,
      });
      dispatch(toggleLoading(false));
    });
};

export const EditGoal = (
  goalId,
  homeId,
  goaltype,
  editGoalObject,
  history
) => async (dispatch) => {
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}${AddGoalsAPIsCall.controller}/${goalId}/${goaltype}/${homeId}/${WorkspaceKey}`;
  dispatch(toggleLoading(true));
  axios
    .post(url, editGoalObject)
    .then((o) => {
      dispatch({
        type: PutGoals,
        payload: o.data.data,
      });
      showSuccess();
      dispatch(toggleLoading(false));
      history.push("/admin/Goal");
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: false,
      });
      let msg = "";
      Object.keys(er.response?.data).forEach((o) => {
        msg += er.response.data[o];
      });
      showError(msg);

      dispatch(toggleLoading(false));
    });
};

export const EditCrushDebt = (key, debtId, editGoalObject, history) => async (
  dispatch
) => {
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}${AddGoalsAPIsCall.controller}/${key}/GoalDebts/${debtId}/${WorkspaceKey}`;
  dispatch(toggleLoading(true));
  axios
    .post(url, editGoalObject)
    .then((o) => {
      dispatch({
        type: PutGoals,
        payload: o.data.data,
      });
      showSuccess();
      dispatch(toggleLoading(false));
      history.push("/admin/Goal");
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: false,
      });
      let msg = "";
      Object.keys(er.response.data).forEach((o) => {
        msg += er.response.data[o];
      });
      showError(msg);

      dispatch(toggleLoading(false));
    });
};

function toggleLoading(status) {
  return { type: SHOW_LOADER, payload: status };
}

export const loading = (status) => async (dispatch) => 
{
    dispatch(toggleLoading(status));
};

function isError(status) {
  return { type: API_ERROR, payload: status };
}

