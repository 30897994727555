import React, { Fragment, useCallback, useEffect, useState } from "react";
import Select from "react-select";

export default function NQDropDown(props) {
  const [selectedOption, setSelectedOption] = useState("none");
  useEffect(() => {
    setSelectedOption(props.value);
  }, [props.value]);
  const [focus, setonFocus] = useState(false);

  const onFocus = () => {
    if (props.value) {
      setonFocus(false);
    } else {
      setonFocus(true);
    }
  };
  const onBlur = () => {
    if (props.value) {
      setonFocus(false);
    } else {
      setonFocus(true);
    }
  };

  const ChangeValue = useCallback(
    (value) => {
      setSelectedOption(value?.value);
      props.onChange(value);
    },
    [selectedOption]
  );

  return (
    <div className={focus && props?.required ? (!props?.defaultValue ? "isError" : "") : ""}>
      <Fragment>
        <Select {...props} className="basic-single" classNamePrefix="select" isClearable={props.isClearable ? props.isClearable : true} isSearchable={true} name={props.name}
          options={props.data} onChange={ChangeValue} defaultValue={props.value} value={!props.multiple ? props.data.find(o => o.value === selectedOption)
            : props.value} onFocus={onFocus} onBlur={onBlur} isMulti={props.multiple} placeholder={props.placeholder ? "Select " + props.placeholder : "Select"}
        />
        {focus ?
          (props && props.required ? (!props.value ? (
            <span className="errormsg"> {props.caption} {" is required"} </span>) : null) : null) : null}
      </Fragment>
    </div>
  );
}
