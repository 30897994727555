import axios from "axios";
import { HomeAPICalls } from "constant/APICalls";
import {
  API_ERROR, Latest_Performance, SHOW_LOADER
} from "../types";

export const getLatestPerformance = (PortFolioId) => async (dispatch) => {
  const workspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${HomeAPICalls.controller}/${PortFolioId}/${workspaceKey}/${HomeAPICalls.Performance}`;
  dispatch(toggleLoading(true));
  latestPerformances(env.apiUrl, url)
    .then((o) => {
      dispatch({
        type: Latest_Performance,
        payload: o.data.data,
      });
      dispatch(toggleLoading(false));
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: [],
      });
      dispatch(toggleLoading(false));
    });
};

function latestPerformances(baseURL, url) {
  return axios.get(`${baseURL}${url}`);
}

function toggleLoading(status) {
  return { type: SHOW_LOADER, payload: status };
}
