import axios from "axios";
import { PotsAPI } from "constant/APICalls";
import { PotTypes } from "Enums/PotTypes";
import {
  API_ERROR, Pots,
  PotsDetailList,
  SHOW_LOADER
} from "store/types";

export const getPotByType = (potType) => async (dispatch) => {
  let obj =
    potType === "Assets"
      ? PotTypes.Asset
      : potType === "Liabilities"
        ? PotTypes.Liability
        : potType === "Expense"
          ? PotTypes.Expense
          : potType === "Income"
            ? PotTypes.Income
            : PotTypes.Unknown;

  const FactFindKey = localStorage.getItem("FactFindKey");
  const workspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}FactFind/${FactFindKey}/Pot/${obj}/${workspaceKey}`;
  dispatch(toggleLoading(true));
  return axios.get(url)

};

export const getPotList = (factFindKey) => async (dispatch) => {
  const workspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}FactFind/${factFindKey}/${workspaceKey}/Pot  `;
  dispatch(toggleLoading(true));
  getpots(url)
    .then((o) => {
      dispatch({
        type: Pots,
        payload: o.data.data,
      });
      dispatch(toggleLoading(false));
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: [],
      });
      dispatch(toggleLoading(false));
    });
};

export const getPotDetailList = () => async (dispatch) => {
  const FactFindKey = localStorage.getItem("FactFindKey");
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const url = `${PotsAPI.controller}/${FactFindKey}/${PotsAPI.potlist}/${WorkspaceKey}`;
  const env = JSON.parse(await localStorage.getItem("env"));
  dispatch(toggleLoading(true));
  getPotdetaillist(env.apiUrl, url)
    .then((o) => {
      dispatch({
        type: PotsDetailList,
        payload: o.data.data,
      });
      dispatch(toggleLoading(false));
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: [],
      });
      dispatch(toggleLoading(false));
    });
};

export const AddNewPot = (NewPot) => async (dispatch) => {
  const FactFindKey = localStorage.getItem("FactFindKey");
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}FactFind/${FactFindKey}/Pot/${WorkspaceKey}`;

  dispatch(toggleLoading(true));
  return axios.post(url, NewPot)

};

export const ChangePot = (obj) => async (dispatch) => {
  const FactFindKey = localStorage.getItem("FactFindKey");
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}FactFind/${FactFindKey}/Pot/${obj.key}/${WorkspaceKey}`;

  dispatch(toggleLoading(true));
  return axios.post(url, obj)

};

export const DeletePotById = (PotKey, PotTypeKey) => async (dispatch) => {
  const FactFindKey = localStorage.getItem("FactFindKey");
  const WorkspaceKey = localStorage.getItem("workspaceKey");
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${env.apiUrl}FactFind/${FactFindKey}/Pot/${PotKey}/${PotTypeKey}/${WorkspaceKey}`;

  dispatch(toggleLoading(true));
  return axios.delete(url)
};

function getpots(url) {
  return axios.get(url);
}

function getPotdetaillist(baseURL, url) {
  return axios.get(`${baseURL}${url}`);
}

function toggleLoading(status) {
  return { type: SHOW_LOADER, payload: status };
}
