import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const SignIn = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({ redirectUri: window.location.origin, appState: "/" });
  }, []);

  return null;
};

export default SignIn;
