import GridItem from "components/Grid/GridItem";
import "./EntityDetail.scss";
import React, { useEffect, useState } from "react";
import GridContainer from './../../../components/Grid/GridContainer';
import { connect } from 'react-redux';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from './../../../components/Loading'; 
import Accordion from '../../../components/Accordion/Accordion'; 
import NotFound from "components/404/NotFound"; 
import { useHistory } from 'react-router-dom';
const EntityDetail = (props) => {


  const [etityDetails, setetityDetails] = useState([]); 
  
  const getetityDetails = () => {


    // let env = JSON.parse(localStorage.getItem("env"));
    // let workSpaceKey = localStorage.getItem("workspaceKey");
    // axios.get(`${env.apiUrl}ApprovedServices/etityDetails/${workSpaceKey}`).then(o => {
    //   setetityDetails(o.data.data);

    // }).catch(err => {
    // })
    setetityDetails([
      {
        code : '1',
        name : 'zeeshan',
        type : 'goal',
        fUM : 'FUM',
        portfolioKey : '38996609-c556-4962-93cb-84993ada3962'
      },
      {
        code : '1',
        name : 'zeeshan',
        type : 'goal',
        fUM : 'FUM',
        portfolioKey : '38996609-c556-4962-93cb-84993ada3962'
      },
      {
        code : '1',
        name : 'zeeshan',
        type : 'goal',
        fUM : 'FUM',
        portfolioKey : '38996609-c556-4962-93cb-84993ada3962'
      },
      {
        code : '1',
        name : 'zeeshan',
        type : 'goal',
        fUM : 'FUM',
        portfolioKey : '38996609-c556-4962-93cb-84993ada3962'
      },
      {
        code : '1',
        name : 'zeeshan',
        type : 'goal',
        fUM : 'FUM',
        portfolioKey : '38996609-c556-4962-93cb-84993ada3962'
      },
      {
        code : '1',
        name : 'zeeshan',
        type : 'goal',
        fUM : 'FUM',
        portfolioKey : '38996609-c556-4962-93cb-84993ada3962'
      },
    ]);
  }

 

  useEffect(() => {
    getetityDetails()
  }, [])


  const history = useHistory();
  const navigateToReports = () => {
    history.push("/admin/Portfolio");
  };

  return (
    <div className="EntityDetail">
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div
            className="css-naxvie"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom:'20px'

            }}
          >
            <div className="css-10ym0te">
              <label className="title">Entity Detail(s)</label>
            </div>
          </div>
        </GridItem>

        <GridItem lg={12} xs={12} sm={12} md={12}>
          <div className="css-2mqgjc">
            <div className="css-ea35n1">
              <div className="css-1kmimlc">
                <div className="ag-theme-alpine" style={{ minHeight: '200px' }}>
                  {etityDetails.length === 0 ? (
                    <NotFound />
                  ) : ( 
                    <div >
                      {etityDetails.map((item, index) => {
                        return (
                          <div className="NQ-accordion">                    
                            <Accordion  allowZeroExpanded={false}>
                              <div label="Question 1"    key={index}>

                               
                              
                                <div className="dpTvjJ">
                                  <div className="sc-fujyUd"> 
                                      <div className="eOsVek">
                                      <p > 
                                      {item.code}
                                    </p>
                                      </div>
                                      <div className="eelevm">
                                          <div className="hukjwq">
                                         <p className="hSaKVG">
                                         {item.name}
                                         </p>
                                          </div>
                                          <div className="eSQXjd">
                                          <p>
                                            {item.type}
                                          </p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="cnwwRC">
                                   <p className="hSaKVG">
                                    <u onClick={navigateToReports}>
                                    {item.fUM}
                                    </u>
                                   </p>
                                  </div>
                                </div> 
                                <div className="grVxVP">
                                     
                                </div>
                                <div>

                                </div>
                              </div>
                              <div label="Question 2"   >
                               <div></div>
                               <div></div>
                              </div>
                              
                            </Accordion>
                            </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );

};

// export default EntityDetail



const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withAuthenticationRequired(EntityDetail, {
    onRedirecting: () => <Loading />,
  })
);