import TextField from "@material-ui/core/TextField";
import React from "react";

export default function NQTextField(props) {
  return (
    <div style={{ width: "100%", paddingTop: "5px" }} className="input-border">
      <TextField
        style={{ width: "100%" }}
        {...props}
        type={props.type ? props.type : "text"}
        onChange={props.onChange}
        name={props.name}
        onBlur={props.onBlur}
        id={props.id}
      />
    </div>
  );
}
