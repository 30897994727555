import * as moment from "moment";

export function currencyFormat(num) {
  if (typeof num === "string") num = parseFloat(num);

  num = num >= 0 ? num : 0;
  return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function UnitFormat(val) {
  return ((val * 100) / 100).toFixed(6);
}
export function UnitPriceFormat(val) {
  return ((val * 100) / 100).toFixed(4);
}

export function dateFormatter(value) {
  let date = new Date(value);
  let dateFormat =
    date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
  return dateFormat;
}

export function MonthlyDateFormatter(value) {
  let date = new Date(value);
  let formated = moment(date).format("MMM-YYYY");
  return formated;
}

export function textDateFormatter(value) {
  let date = new Date(value);
  let formated = moment(date).format("YYYY-MMM");
  return formated;
}

export function FormatDate(value) {
  let date = new Date(value);
  let formated = moment(date).format("DD-MMM-YYYY");
  return formated;
}

export function FormatDateToText(value) {
  let date = new Date(value);
  let formated = moment(date).format("DD MMM YYYY");
  return formated;
}



export function NQDateInputMonthFormatter(value) {
  let NQDateInputMonth = moment(new Date(value).getFullYear() + "-" + (new Date(value).getMonth() + 1)).format("YYYY-MM");
  return NQDateInputMonth;
}

export function convertToInternationalCurrencySystem(labelValue) {

  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

      ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
      // Three Zeroes for Thousands
      : Math.abs(Number(labelValue)) >= 1.0e+3

        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

        : Math.abs(Number(labelValue));

}