import { any } from "prop-types";
import {
  GetAdvice,API_ERROR,SHOW_LOADER
} from "../types";

const initialState = {
  adviceData: [],
  response: any,
  loading: false,
  isValid: false,
  isError:false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GetAdvice:
      return {
        ...state,
        adviceData: action.payload,
      };
    case SHOW_LOADER:
      return { ...state, loading: action.payload };

    case API_ERROR:
      return { ...state, isError: action.payload };

    default:
      return state;
  }
}
