import axios from "axios";
import {
  API_ERROR,
  GetByKeyType,
  SHOW_LOADER
} from "../types";

export const GetByKey = (url) => async (dispatch) => {
  const env = JSON.parse(await localStorage.getItem("env"));
  const api = `${env.apiUrl}${url}`;
  dispatch(toggleLoading(true));
  axios
    .get(api)
    .then((o) => {
      dispatch({
        type: GetByKeyType,
        payload: o.data.data,
      });
      dispatch(toggleLoading(false));
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: false,
      });
      dispatch(toggleLoading(false));
    });
};

export const DeleteByKey = (url) => async (dispatch) => {
  const env = JSON.parse(await localStorage.getItem("env"));
  const api = `${env.apiUrl}${url}`;
  return axios
    .delete(api)

};

function toggleLoading(status) {
  return { type: SHOW_LOADER, payload: status };
}
