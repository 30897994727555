import { enableRipple } from '@syncfusion/ej2-base';
import { AccumulationChartComponent, AccumulationDataLabel, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationTooltip, Inject, PieSeries } from '@syncfusion/ej2-react-charts';
import * as React from 'react';
import { convertToInternationalCurrencySystem } from 'utils/formatter';
enableRipple(true);


export default function PotCardChart(props) {

    const PotTypes = () => {
        let data = [];
        if (props && props.Data && props.Data && props.Data.length > 0) {
            data = props.Data.reduce((r, { type }) => {
                let valu = 0;
                props.Data.filter((o) => o.type === type).forEach(
                    (element) => {
                        valu += element.amount;
                    }
                );

                if (!r.some((o) => o.type === type)) {
                    r.push({
                        label: type,
                        value: valu,
                        text: `${type} : ${valu}`,
                        total: valu
                    });
                }
                return r;
            }, []);
        }
        if (data && data.length > 0) {
            data.forEach(element => {
                element.label = `${element.label} ${convertToInternationalCurrencySystem(element.total)}`
            });
        }

        return data;
    };

    const load = (args) => {
        let selectedTheme = 'Material';
        args.accumulation.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).
            replace(/-dark/i, "Dark");
    }

    const chartId = () => {
        const min = 1;
        const max = 100;
        const rand = min + Math.random() * (max - min);
        return 'pie-chart' + rand
    }

    return (
        <div >
            <div >
                <AccumulationChartComponent height='400px' key={chartId} tooltip={{ enable: true, format: '${point.x}' }} load={load} legendSettings={{
                    visible: true,
                    position: 'Top'
                }}>
                    <Inject services={[AccumulationDataLabel, AccumulationTooltip, PieSeries]} />
                    <AccumulationSeriesCollectionDirective>
                        <AccumulationSeriesDirective key={chartId} name={props.Caption} dataSource={PotTypes()}
                            xName='label' yName='value'
                            innerRadius='40%' startAngle={0} endAngle={360} radius='70%' explode={true} explodeOffset='10%' explodeIndex={3}
                            dataLabel={{
                                visible: true, position: 'Inside',
                                name: 'value',
                                font: {
                                    fontWeight: '600',
                                    color: '#ffffff'
                                }
                            }}>
                        </AccumulationSeriesDirective>
                    </AccumulationSeriesCollectionDirective>
                </AccumulationChartComponent>
            </div>
        </div>
    )
}
