import { withAuthenticationRequired } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import Loading from "components/Loading";
import Menu from "components/Menu/Menu.js";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import commonMenus from "routes/common.routes";
import portfolioRoutes from './../routes/portfolio.routes';
import superPensionRoutes from './../routes/super-pension.routes';
import { useDispatch } from "react-redux";
import { checkClientType } from "store/action/portfoliosAction";

let ps;
const routes = [...commonMenus]
const portfolioroutes = [...portfolioRoutes]
const superpensionroutes = [...superPensionRoutes]

const switchRoutes = (
  <Switch>
    {routes.concat(portfolioroutes).concat(superpensionroutes).map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/Home" />
  </Switch>
);

const useStyles = makeStyles(styles);

const Admin = ({ ...rest }) => {
  const classes = useStyles();
  const mainPanel = React.createRef();
  let dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkClientType())

    },[]);

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <Menu />
      <div
        className={
          classes.mainPanel +
          " " +
          (window.location.href.includes("Portfolio")
            ? "Portfolio"
            : "container")
        }
        ref={mainPanel}
      >
        {
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        }
      </div>
    </div>
  );
};

export default withAuthenticationRequired(Admin, {
  onRedirecting: () => <Loading />,
});
