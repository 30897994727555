import React from "react";
import './unauthorized.scss';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
export default function Unauthorized() {
  const { logout } = useAuth0();
  const logoutWithRedirect = () => {
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };
  return (
    <div className="unauthorized">
 
      <div className="error-card text-center">
        <h1 className='errorcode'>401</h1>
        <h3 className="text-uppercase">Unauthorised !</h3>
        <p className="text-muted m-t-30 m-b-30">You are not authorised to access this application. Please contact administrator.</p>
          <button type="button" onClick={() => logoutWithRedirect()} className="NQ-btn NQ-btn-blue m-b-40 m-l-10 " style={{display:"inline",marginTop:'16px'}}>Logout </button>
      </div>

    </div>
  );
}