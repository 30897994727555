export function currencyFormat(num) {
  
  if (typeof num === "string") 
    num = parseFloat(num);
  let ret = num >= 0 ? num : -num;
  ret = "$" + ret.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  if (num <0)
    ret =  "-"+ret;
  return ret;
}
