import axios from "axios";
import { PortfoliosAPI } from "constant/APICalls";
import { SHOW_LOADER, Portfolios, API_ERROR, portfolioLoader, isSuperOnboardingCompleted, isSuperPortfolioExist } from "../types";
import { NQSuperSelected } from "store/types";

export const getPortfolios = (isSuperSelected) => async (dispatch) => {
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${PortfoliosAPI.controller}/${PortfoliosAPI.action}?isSuperSelected=${isSuperSelected}`;
  return portfoliosList(env.apiUrl, url)

};

export const getPortfoliosList = (isSuperSelected) => async (dispatch) => {
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${PortfoliosAPI.controller}/${PortfoliosAPI.action}?isSuperSelected=${isSuperSelected}`;
  dispatch(toggleLoading(true));
  dispatch(togglePortfolioLoading(true));
  portfoliosList(env.apiUrl, url).then((o) => {

    if (isSuperSelected && o.data.totalRecords > 0) {

      dispatch({ type: isSuperPortfolioExist, payload: true, });

      if (o.data.data.filter(x => x.onboardingStatus == 8).length > 0) {
        dispatch({ type: isSuperOnboardingCompleted, payload: true, });
      }
      else {
        dispatch({ type: isSuperOnboardingCompleted, payload: false, });
      }

    } else {
      dispatch({ type: isSuperPortfolioExist, payload: false, });
    }

    dispatch({
      type: Portfolios,
      payload: o.data.data,
    });
    dispatch(toggleLoading(false));
    dispatch(togglePortfolioLoading(false));
  })

  .catch((er) => {
    dispatch({
      type: API_ERROR,
      payload: [],
    });
    dispatch(toggleLoading(false));
    dispatch(togglePortfolioLoading(false));
  })

};

export const checkClientType = () => async (dispatch) => {
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${PortfoliosAPI.controller}/${PortfoliosAPI.checkClientType}`;
  dispatch(toggleLoading(true));
    portfoliosList(env.apiUrl, url).then((o) => {
      dispatch({
        type: NQSuperSelected,
        payload: o.data,
      });

      dispatch(toggleLoading(false));
      dispatch(getPortfoliosList(o.data))
    })
    .catch((er) => {
      dispatch({
        type: API_ERROR,
        payload: [],
      });
      dispatch(toggleLoading(false));
    })
  

};


export const getClientPortfolioByContactEmailAndServiceCode = () => async (dispatch) => {
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${PortfoliosAPI.controller}/${PortfoliosAPI.getClientPortfolioByContactEmailAndServiceCode}`;
  return axios.get(`${env.apiUrl}${url}`);
};


export const getClientPortfolioList = (isSuperSelected) => async (dispatch) => {
  const env = JSON.parse(await localStorage.getItem("env"));
  const url = `${PortfoliosAPI.controller}/${PortfoliosAPI.getClientPortfolios}?isSuperSelected=${isSuperSelected}`;
  portfoliosList(env.apiUrl, url).then((o) => {
    dispatch({
      type: Portfolios,
      payload: o.data.data,
    });
    dispatch(toggleLoading(false));
  })

  .catch((er) => {
    dispatch({
      type: API_ERROR,
      payload: [],
    });
    dispatch(toggleLoading(false));
  })

};


function portfoliosList(baseURL, url) {
  return axios.get(`${baseURL}${url}`);
}

function toggleLoading(status) {
  return { type: SHOW_LOADER, payload: status };
}

function togglePortfolioLoading(status) {
  return { type: portfolioLoader, payload: status };
}

export const loading = (status) => async (dispatch) => 
{

    dispatch(toggleLoading(status));
};
