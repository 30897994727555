import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect } from "react";
import EllipsisText from "react-ellipsis-text";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getGoals } from "store/action/goalsAction";
import debt from "../../../assets/icon/debt.svg";
import houes from "../../../assets/icon/houes.svg";
import other from "../../../assets/icon/other.svg";
import rainyday from "../../../assets/icon/rainyday.svg";
import "./Goaldetail.scss";

const Goaldetail = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

  const history = useHistory();

  const navigateToAddHome = () => {
    history.push("/admin/AddHome");
  };

  const navigateToAddDebt = () => {
    history.push("/admin/AddDebt");
  };

  const navigateToAddRainyDays = () => {
    history.push("/admin/AddRainyDay");
  };

  const navigateToAddSomethingElse = () => {
    history.push("/admin/AddSomethingElse");
  };

  useEffect(() => {}, []);

  return (
    <div className="GoalDetail">
      {/* Goal List */}
      <div className="mb-10">
        <label className="title"> Goals Type </label>
      </div>
      {/* Goal List */}
      {/* Goal Menu */}
      <GridContainer>
        <GridItem lg={6} md={6} xs={12} sm={12}>
          <div className="NQ-web">
            <div className="NQ-card goal-card">
              <div className="NQ-card-body" onClick={() => navigateToAddHome()}>
                <div className="NQ-left">
                  <div className="NQ-col-1">
                    <div>
                      <img
                        height="70"
                        src={houes}
                        width="60"
                        alt={"Buys a house"}
                      />
                    </div>
                    <div className="NQ-col-2">
                      <span>
                        <EllipsisText
                          title="Buys a house "
                          text="Buys a house"
                          length={80}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GridItem>
        <GridItem lg={6} md={6} xs={12} sm={12}>
          <div className="NQ-web">
            <div className="NQ-card goal-card">
              <div className="NQ-card-body" onClick={() => navigateToAddDebt()}>
                <div className="NQ-left">
                  <div className="NQ-col-1">
                    <div>
                      <img
                        height="70"
                        src={debt}
                        width="60"
                        alt={"Crush debt"}
                      />
                    </div>
                    <div className="NQ-col-2">
                      <span>
                        <EllipsisText
                          title="Crush debt "
                          text="Crush debt"
                          length={80}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GridItem>
        <GridItem lg={6} md={6} xs={12} sm={12}>
          <div className="NQ-web">
            <div className="NQ-card goal-card">
              <div
                className="NQ-card-body"
                onClick={() => navigateToAddRainyDays()}
              >
                <div className="NQ-left  ">
                  <div className="NQ-col-1">
                    <div>
                      <img
                        height="70"
                        src={rainyday}
                        width="60"
                        alt={"Save for a rainy day"}
                      />
                    </div>
                    <div className="NQ-col-2">
                      <span>
                        <EllipsisText
                          title="Save for a rainy day"
                          text="Save for a rainy day"
                          length={80}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GridItem>
        <GridItem lg={6} md={6} xs={12} sm={12}>
          <div className="NQ-web">
            <div className="NQ-card goal-card">
              <div
                className="NQ-card-body"
                onClick={() => navigateToAddSomethingElse()}
              >
                <div className="NQ-left">
                  <div className="NQ-col-1">
                    <div>
                      <img
                        height="70"
                        src={other}
                        width="60"
                        alt={"Something else"}
                      />
                    </div>
                    <div className="NQ-col-2">
                      <span>
                        <EllipsisText
                          title="Something else"
                          text="Something else"
                          length={80}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { goalsList: state.goalsReducer.goalsData };
};

const mapDispatchToProps = (dispatch) => {
  return { goalsList: () => dispatch(getGoals()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Goaldetail);
