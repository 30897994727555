import {
  DeletePot,
  EditPot,
  PostAddNewPot,
  Pots,
  PotsByType,
  PotTotal,
  SHOW_LOADER,
} from "../types";
import { PotsDetailList } from "../types";

const initialState = {
  potsList: [],
  PotsDetailList: [],
  loading: false,
  Total: [],
  response: {},
};

export default function (state = initialState, action) {
  
  switch (action.type) {
    case Pots:
      return {
        ...state,
        potsList: action.payload,
      };
    case PotsByType:
      return {
        ...state,
        PotsDetailList: action.payload,
      };
    case PotsDetailList:
      return {
        ...state,
        PotsDetailList: action.payload,
      };
    case PotTotal:
      return {
        ...state,
        Total: action.payload,
      };
    case PostAddNewPot:
      return {
        ...state,
        response: action.payload,
      };
    case EditPot:
      
      return {
        ...state,
        response: action.payload,
      };
    case DeletePot:
      return {
        ...state,
        response: action.payload,
      };
    case SHOW_LOADER:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
}
