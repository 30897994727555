import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import styles from "./menuStyle.js";
import { setSideBar, setNQSuperSelected } from "../../store/action/sideAction.js";
import { getPortfoliosList } from "../../store/action/portfoliosAction.js";
import commonRoutes from "../../routes/common.routes.js";
import portfolioRoutes from "../../routes/portfolio.routes.js";
import superPensionRoutes from "../../routes/super-pension.routes.js";
import BootstrapTooltip from "../toolTIp/NQTooltips";


const useStyles = makeStyles(styles);
const allRoutes = [...commonRoutes, ...portfolioRoutes, ...superPensionRoutes];

export default function Menu(props) {
  const theme = useTheme();
  const location = useLocation();
  const classes = useStyles();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  // Redux store
  const dispatch = useDispatch();
  const isSuperSelected = useSelector((state) => state.sideBarReducer.nqSuperSelected);
  const isSuperOnboardingCompleted = useSelector((state) => state.portfoliosReducer.isSuperOnboardingCompleted)
  const isSuperPortfolioExist = useSelector((state) => state.portfoliosReducer.isSuperPortfolioExist)

  // useState
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  // Toggle menu for mobile view
  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  // Update nqSuperSelected with opposite boolean value
  const toggleSuperSelected = () => {
    dispatch(getPortfoliosList(!isSuperSelected));
    dispatch(setNQSuperSelected(!isSuperSelected));
  }

  // Update store when a menu item is clicked
  const handleMenuItemClick = (menuItem) => {
    setMenuIsOpen(!menuIsOpen);
    dispatch(setSideBar(menuItem.menu));

    if (menuItem.name === "Switch") {
      toggleSuperSelected();
    }
  };

  // Update current location in store everytime it changes
  useEffect(() => {
    const currentPath = `/${location.pathname.split('/').pop()}`;
    const currentRoute = allRoutes.find(route => route.path === currentPath);
    dispatch(setSideBar(currentRoute && currentRoute.menu || 'Home'));
  }, [location]);

  // Set menu items based on portfolio/super view
  useEffect(() => {
    let items = isSuperSelected ? [...commonRoutes, ...superPensionRoutes] : [...commonRoutes, ...portfolioRoutes];

    let hideMenu = ["Goals", "Transaction", "Investments", "Request"];
    let isHideMenu = false;

    if (isSuperSelected) {

      if (!isSuperPortfolioExist || (isSuperPortfolioExist && !isSuperOnboardingCompleted)) {
        isHideMenu = true;
      }

      items = items.map(m => {
        if (hideMenu.includes(m.name)) {

          if(m.name === "Request" && isSuperPortfolioExist && !isSuperOnboardingCompleted){
            m.hide = false;
          }else{
            m.hide = isHideMenu;
          }

        }
        return m;
      })


    }

    setMenuItems(items.filter(item => !item.hide).sort((a, b) => a.index - b.index));
  }, [isSuperSelected, isSuperPortfolioExist, isSuperOnboardingCompleted]);

  // Nav list component
  function NavList(props) {
    return (
      <List className={classes.navList} disablePadding>
        {props.children}
      </List>
    )
  }

  // Nav item component
  function NavListItem(props) {
    return (
      <ButtonBase component="li" className={props.menuItem.name === "Switch" && !isMobile ?  classes.navListItem + " "+ classes.mtAuto : classes.navListItem}>
        <BootstrapTooltip
          title={`${props.menuItem.name}${props.menuItem.name === 'Switch' ? ` ${isSuperSelected ? 'to Investment Portfolio' : 'to Super & Pension'}` : ''}`}
          placement="right"
          disableTouchListener
        >
          <NavLink
            className={classes.navListItemLink}
            activeClassName={props.menuItem.name !== "Switch" ? classes.navListItemLinkActive : ''}
            to={`${props.menuItem.layout}${props.menuItem.path}`}
            onClick={() => handleMenuItemClick(props.menuItem)}
          >
            {props.menuItem.svgicon && props.menuItem.icon && typeof props.menuItem.icon === "string" && (
              <svg
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 400 400"
                enableBackground="new 0 0 100 100"
                className={classes.navListIcon}
              >
                <path
                  strokeWidth="3"
                  strokeMiterlimit="10"
                  d={props.menuItem.icon}
                />
              </svg>
            )}
            {isMobile && (
              <span className={classes.navListItemText}>
                {props.menuItem.name}
                {props.menuItem.name === "Switch" && ` ${isSuperSelected ? 'to Inv. Portfolio' : 'to Super & Pension'}`}
              </span>
            )}
            {props.children}
          </NavLink>
        </BootstrapTooltip>
      </ButtonBase>
    )
  };

  return (
    <>
      <AppBar className={classes.appBar} position="sticky">
        <Toolbar>
          <IconButton edge="start" aria-label="menu" onClick={toggleMenu}>
            <MenuIcon />
          </IconButton>
          {isMobile && (
            <Typography variant="body1" className={classes.appBarTitle}>
              {isSuperSelected ? "Super & Pension Portfolio" : "Investment Portfolio"}
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        classes={{ paper: classes.menuDrawerPaper }}
        anchor="left"
        open={menuIsOpen}
        variant={isMobile ? 'temporary' : 'permanent'}
        onClose={toggleMenu}
      >
        <nav className={classes.navBar} role="navigation">
          <NavList>
            {menuItems.map((menuItem, index) => {
              return (
                <NavListItem menuItem={menuItem} key={`menu-item-${index}`}>
                  {!isMobile && index === 0 && <span className={classes.title}>{isSuperSelected ? "Super" : "Portfolio"}</span>}
                </NavListItem>
              );
            })}
          </NavList>
        </nav>
      </Drawer>
    </>
  );
}
