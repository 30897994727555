import { withAuthenticationRequired } from "@auth0/auth0-react";
import { upperCase } from "assets/jss/material-dashboard-react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loading from "components/Loading";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { currencyFormat } from "utils/currencyFormat";
import { getPortfolios } from "../../../store/action/portfoliosAction";
import "./Portfoliolist.scss";
import PieChart, { Legend, Series, Tooltip, Format, Size, } from 'devextreme-react/pie-chart';
import { roundPercentage } from "utils/percentage";
import IconInfoSquare from "Icons/iconInfoSquare";

const Portfoliolist = (props) => {

  const [homeChart, setHomeChart] = useState([])
  const [selectedPortfolio, setSelectedPortfolio] = useState('')
  const history = useHistory();
  const [PortFolioList, setPortFolioList] = useState([])
  const portfolioData = useSelector((state)=>state.portfoliosReducer.portfoliosDataObj)
  const isSuperPortfolioExist = useSelector((state)=>state.portfoliosReducer.isSuperPortfolioExist)

  useEffect(() => {},[props.selectedPortfolio])

  const navigateTo = (i, portfolioKey) => {
    history.push({
      pathname: "/admin/Portfoliodetail",
      currentIndex: i + 1,
      PorfolioKey: portfolioKey,
    });
  };

  useEffect(() => {
    SetPortFolios()
  }, []);

  const SetPortFolios = () =>
  {
    let portfolios = [];
    portfolioData && portfolioData.forEach(element => {
      let showPortfolio = true;
      if(props.nqSuperSelected && element.onboardingStatus !== 8){
        showPortfolio = false;
      }

      if(element && element.holdings && showPortfolio){ 
        element.portfolioHoldings = JSON.parse(element.holdings);
        let dates = element.portfolioHoldings.map(o => new Date(o.holdingDate));
        element.holdingDate = dates && dates.length > 0 ? new Date(Math.max.apply(null, dates)) : undefined;
        portfolios.push(element)
      }
    });
    setPortFolioList(portfolios);
  }

  const getAssestData = (portFolios) => {
    portFolios = portFolios.portfolioHoldings;
    let data = [];
    if (portFolios.length > 0) {
      data = portFolios.reduce((r, { assetClass }) => {
        if (!r.some((o) => o.assetClass === assetClass)) {
          let amount = 0;
          let types = portFolios.filter((v) => v.assetClass === assetClass);
          if (types) {
            types.forEach((element) => {
              amount += Number(element.unitPrice) * Number(element.units);
            });
          }
          r.push({
            assetClass: assetClass,
            holdingValue: amount,
          });
        }
        return r;
      }, []);
    }
    return data;
  };

  const customizeTooltip = (arg) => {
    return {
      text: `<b> ${arg.argumentText}</b> : $${(arg.value).toFixed(2)}`,
    };
  }

  useEffect(() => {
    if(props.selectedPortfolio){
      setSelectedPortfolio(props.selectedPortfolio)
    }
    if(props.portfoliosDataObj.length >= 0 && (props.selectedPortfolio == undefined || props.selectedPortfolio == ""))
    {
      SetPortFolios(props.portfoliosDataObj)
    }
  },[props])

  useEffect(() => {
    if (PortFolioList.length && !selectedPortfolio && !!props.selectFirstPortolio) {
      setSelectedPortfolio(PortFolioList.at(0).portfolioKey);
    }
  }, [PortFolioList]);

  const MoreClick = (i, portfolioKey) => {
    if(props.handleSelectedPortfolio && props.handleSelectedPortfolio !== undefined){
      props.handleSelectedPortfolio(portfolioKey)
    }
    navigateTo(i, portfolioKey)
    
  }
  const ClickPortfolio = (portfolioKey) => {
    if(!props.handleSelectedPortfolio && props.handleSelectedPortfolio === undefined){
     setSelectedPortfolio(portfolioKey)
     if(props.handleReportselection && props.handleReportselection !== undefined){
      props.handleReportselection(portfolioKey)
    }
    } 
  }
  return (
    <div className="Portfoliolist">
      
      { props.loading ? (
        <div className="flex-container">
        <div className="flex-items loader">Retrieving your Portfolio data... <span>🔍</span></div>
      </div>
      ) : !props.loading && !isSuperPortfolioExist && props.nqSuperSelected ? (
      <div className="flex-container css-vy2pbh">
      <div className="flex-items text-danger css-1qm1lh">
        <div className="css-xqrdoy">
          <table>
            <tr>
              <td className="icon-cell"> 
                <IconInfoSquare/>
              </td>
              <td>
                <p><b>NQ Super</b> is a retail superannuation offering that allows you to create a personalised investment portfolio with Listed Securities, Managed Funds, Term Deposits, Cash and more. You can also manage your pension, insurance and control your <b>super</b> with complete flexibility and transparency.
                </p>
                <br/>
                <p> Talk to your trusted adviser to learn more about becoming a member of NQ Super or NQ Pension. </p>
              </td>
            </tr>
          </table>
       
        </div>
      </div>
      </div>) 
      :!props.loading && selectedPortfolio == "" && (!PortFolioList || PortFolioList.length === 0) && (isSuperPortfolioExist || !props.nqSuperSelected) ? (
        <div className="flex-container">
          <div className="flex-items text-danger">Your Portfolio is still being established. Please check back soon</div>
        </div>
      ) :
      <GridContainer>
        {PortFolioList.map((item, i) => {
          return (
            <Fragment key={`portfolio-${i}`}>
              <GridItem id="Portfoliogrid" xs={12} sm={12} md={4} lg={4} key={i}>
                <div  className={item.portfolioKey === selectedPortfolio ? "home-card SelectedPortfolio" : "home-card"} onClick={() => ClickPortfolio(item.portfolioKey)}>
                  <div className="row-1">
                    <div className="m-0 cardHeading pretag">
                      {upperCase(item.name)}
                      <span className="adviser-title">{item.adviserFirmName}</span>
                    </div>
                    <div>
                      <div onClick={() => MoreClick(i, item.portfolioKey)} className="viewAll" style={{ cursor: 'pointer', float: 'right', }}> More  </div>
                    </div>
                  </div>
                  <div className="row-2">
                    {/* <div className="m-0 cardHeading">
                      $1500.00
                    </div>
                    <div className="m-0 seprater cardHeading">
                      |
                    </div>
                    <div className="m-0 cardHeading">
                      6.23% (1M)
                    </div> */}
                  </div>
                  <div className="row-3">
                    <div>
                      <span className="service-title">{item.serviceType}</span>
                      <span className="amount"> {currencyFormat(item.portfolioHoldings?.reduce((a,v) =>  a = a + v.holdingValue , 0 ))} </span>
                    </div>
                  {getAssestData(item).length == 0 ? (
                    <div style={{marginTop:"37px",fontWeight:"bold"}}> No data to display</div>
                    ):<div>
                      <PieChart
                        id="pie"
                        type="doughnut"
                        innerRadius={0.85}
                        centerTemplate={"100"}
                        palette="Soft Pastel"
                        dataSource={getAssestData(item)}
                        height={20}
                        key={i}
                      >
                        <Size height={90} width={100} />
                        <Series argumentField="assetClass" valueField="holdingValue"  >
                        </Series>
                        <Legend
                          margin={30}
                          verticalAlignment="bottom"
                          horizontalAlignment="center"
                        />
                        <Tooltip enabled={true} customizeTooltip={customizeTooltip} >
                          <Format type="millions" />
                        </Tooltip>
                      </PieChart>
                    </div>}
                  </div>
                </div>
              </GridItem>
            </Fragment>
          );
        })}
      </GridContainer>}
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.portfoliosReducer.portfolioLoader,
    nqSuperSelected: state.sideBarReducer.nqSuperSelected,
    portfoliosDataObj:state.portfoliosReducer.portfoliosDataObj
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    portfoliosList: (isSuperSelected) => dispatch(getPortfolios(isSuperSelected)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withAuthenticationRequired(Portfoliolist, {
    onRedirecting: () => <Loading />,
  })
);
