import { NQSuperSelected } from "store/types";
import { SideBar } from "store/types";

const initialState = {
  menu: "",
  nqSuperSelected: true
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SideBar:
      return {
        ...state,
        menu: action.payload,
      };
    case NQSuperSelected:
      return {
        ...state,
        nqSuperSelected: action.payload,
      };
    default:
      return state;
  }
}
