import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
    boxSizing: 50,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return (
    <Tooltip
      interactive={true}
      enterDelay={300}
      arrow
      classes={classes}
      {...props}
    />
  );
}
